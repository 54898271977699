import React, { useEffect, useRef, useState } from "react";
import {
  InputAdornment,
  IconButton,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { Box, Grid, TextField, Typography } from "@mui/material";
import CustomDatePicker from "../CustomDatePicker";
import ErrorInputAdornment from "../error-input-adornment";
import CommodityListDialog from "../commodity-list-dialog/commodity-list-dialog";
import { UnDetailsItemDto } from "../../../models/long-form-item-dto";

interface RequestInfoProps {
  formik: any;
}

const RequestInfo = (props: RequestInfoProps) => {
  const { formik } = props;
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const onSelectCommodity = (commodity: UnDetailsItemDto) => {
    formik.setFieldValue("unNumber", commodity?.unNumber || "");
  };

  return (
    <>
      <Box className="mt-3 pb-40 Divider w-100 mb-24">
        <Grid container spacing={2}>
          <Grid item lg={7} md={6} sm={12} xs={12}>
            <Box className="rounded p16 border">
              <Typography variant="h6" className="Sub-header">
                Contact Information
              </Typography>
              <Grid container spacing={2}>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <TextField
                    id="txtAeadCompanyName"
                    label="Company Name"
                    name="companyName"
                    variant="outlined"
                    className="w-100"
                    size="medium"
                    onChange={formik.handleChange}
                    value={formik.values.companyName}
                    error={
                      formik.touched.companyName &&
                      Boolean(formik.errors.companyName)
                    }
                    InputProps={{
                      endAdornment: formik.touched.companyName &&
                        Boolean(formik.errors.companyName) && (
                          <ErrorInputAdornment />
                        ),
                    }}
                    inputProps={{ maxLength: 50 }}
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <TextField
                    id="hcName"
                    label="Name"
                    name="name"
                    variant="outlined"
                    className="w-100"
                    size="medium"
                    onChange={formik.handleChange}
                    value={formik.values.name}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    InputProps={{
                      endAdornment: formik.touched.name &&
                        Boolean(formik.errors.name) && <ErrorInputAdornment />,
                    }}
                    inputProps={{
                      maxLength: 50,
                    }}
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <TextField
                    id="txtAeadEmailAddress"
                    label="Email Address"
                    name="emailAddress"
                    variant="outlined"
                    className="w-100"
                    size="medium"
                    onChange={formik.handleChange}
                    value={formik.values.emailAddress}
                    error={
                      formik.touched.emailAddress &&
                      Boolean(formik.errors.emailAddress)
                    }
                    InputProps={{
                      endAdornment: formik.touched.emailAddress &&
                        Boolean(formik.errors.emailAddress) && (
                          <ErrorInputAdornment />
                        ),
                    }}
                    inputProps={{
                      maxLength: 4000,
                    }}
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <TextField
                    id="txtAeadPhoneNumber"
                    label="Phone"
                    name="phoneNumber"
                    variant="outlined"
                    className="w-100"
                    size="medium"
                    onChange={formik.handleChange}
                    value={formik.values.phoneNumber}
                    error={
                      formik.touched.phoneNumber &&
                      Boolean(formik.errors.phoneNumber)
                    }
                    InputProps={{
                      endAdornment: formik.touched.phoneNumber &&
                        Boolean(formik.errors.phoneNumber) && (
                          <ErrorInputAdornment />
                        ),
                    }}
                    inputProps={{
                      maxLength: 25,
                    }}
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <TextField
                    label="UN #"
                    variant="outlined"
                    name="unNumber"
                    id="txtUnNumber"
                    className="w-100"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton id="btnDgAdd" onClick={handleClickOpen}>
                            <AddIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    onChange={formik.handleChange}
                    value={formik.values.unNumber}
                    inputProps={{
                      maxLength: 25,
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item lg={5} md={6} sm={12} xs={12}>
            <Box className="border rounded p16 w-100">
              <Grid container spacing={2}>
                <Grid item lg={12} md={12}>
                  <Typography variant="h6" className="Sub-header">
                    Shipment Ready Time
                  </Typography>

                  <CustomDatePicker
                    id="txtReadyDate"
                    handleDateChange={formik.handleChange}
                    label="Ready Date"
                    name="readyTime"
                    value={
                      formik.values.readyNow ? null : formik.values.readyTime
                    }
                    isClearValue={formik.values.readyNow}
                    isDisable={formik.values.readyNow}
                    showTimeSelect={true}
                    showError={
                      formik.touched.readyTime &&
                      Boolean(formik.errors.readyTime)
                    }
                  />
                  <Box className="d-flex gap-2 mt-2 h-42 order_chkbx">
                    <FormControlLabel
                      className="chk_bx_50"
                      control={
                        <Checkbox
                          name="readyNow"
                          checked={formik.values.readyNow}
                          onChange={formik.handleChange}
                          id="cbReadyNow"
                        />
                      }
                      label="Ready Now"
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <CommodityListDialog
        open={open}
        handleClose={handleClose}
        onSelectCommodity={onSelectCommodity}
      />
    </>
  );
};

export default RequestInfo;
