import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Typography,
  styled,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { MAX_COMPANY_ID } from "../../../config/constants";
import useDocumentTypeLogic from "../../lookup/document-type/document-type.logic";
import { DocumentTypeItemDto } from "../../../models/track-order-item-dto";
import useMultipleFileUploadLogic from "../../document-upload/multiple-document-upload.logic";
import { useDecodeJWT } from "../../hook/use-decodeJWT";
import Loader from "../../util/loader";

interface FileItem {
  id: number;
  name: string;
  size: any;
  type: string;
  file: File | null;
  error: any;
}

interface MyDialogProps {
  open: boolean;
  handleClose: (isReload: boolean) => void;
  shipmentGUID: string;
  onUploadSuccess: (message: string) => void;
}

const AttachDocsDialog: React.FC<MyDialogProps> = ({
  open,
  handleClose,
  shipmentGUID,
  onUploadSuccess,
}) => {
  const [fileUploads, setFileUploads] = useState<FileItem[]>([
    { id: 1, name: "", size: null, type: "", file: null, error: null },
    { id: 2, name: "", size: null, type: "", file: null, error: null },
    { id: 3, name: "", size: null, type: "", file: null, error: null },
  ]);
  const [selectedDocumentType, setSelectedDocumentType] = useState<string>("");
  const [error, setError] = useState<string | null>(null);
  const { documentTypeError, documentType } = useDocumentTypeLogic();
  const [documentTypes, setDocumentTypes] = useState<DocumentTypeItemDto[]>([]);
  const { multipleFileUpload } = useMultipleFileUploadLogic();
  const token = useDecodeJWT();
  const [isLoading, setIsLoading] = useState(false);

  const getDocumentType = async () => {
    try {
      const param = {
        companyID: MAX_COMPANY_ID,
      };
      const response = await documentType(param);
      if (response.data && response.data.data) {
        setDocumentTypes(response.data.data);
        setError(null);
      }
    } catch (err) {
      setError(documentTypeError);
    }
  };

  useEffect(() => {
    if (open) {
      getDocumentType();
      setFileUploads(
        fileUploads.map((fileUpload) => ({
          ...fileUpload,
          error: null,
        }))
      );
    }
  }, [open]);

  const supportedTypes = [
    "image/jpe",
    "image/jpeg",
    "image/jpg",
    "image/gif",
    "image/png",
    "image/bmp",
    "application/pdf",
    "application/xml",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "text/csv",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "text/plain",
    "application/rtf",
  ];

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  const handleFileChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: any
  ) => {
    if (event.target.files) {
      const file = event.target.files[0];
      const Valid = validateFile(file);
      if (Valid === null) {
        const updatedFileUploads = fileUploads.map((fileUpload) =>
          fileUpload.id === id
            ? {
                ...fileUpload,
                name: file.name,
                size: file.size,
                type: file.type,
                file,
                error: null,
              }
            : fileUpload
        );
        setFileUploads(updatedFileUploads);
        setError(null);
      } else {
        const updatedFileUploads = fileUploads.map((fileUpload) =>
          fileUpload.id === id
            ? {
                ...fileUpload,
                name: "",
                size: null,
                type: "",
                file: null,
                error: Valid,
              }
            : fileUpload
        );
        setFileUploads(updatedFileUploads);
      }
    }
  };

  const handleRemoveFile = (idToRemove: any) => {
    const updatedFileUploads = fileUploads
      .filter((fileUpload) => fileUpload.id !== idToRemove)
      .map((fileUpload, index) => ({
        ...fileUpload,
        id: index + 1, // Update IDs sequentially starting from 1
      }));
    setFileUploads(updatedFileUploads);
    setError(null);
  };

  const validateFile = (file: File): any => {
    const maxSize = 10 * 1024 * 1024; // 10 MB
    if (!supportedTypes.includes(file.type)) {
      setError(
        "Documented upload failed. Please ensure format and size are supported. Supported file types: jpe, .jpeg, .jpg, .gif, .png, .bmp, .pdf, .xml, .xls, .xlsx, .csv, .doc, .docx, .txt, .rtf"
      );
      return "Documented upload failed. Please ensure format and size are supported. \nSupported file types: jpe, .jpeg, .jpg, .gif, .png, .bmp, .pdf, .xml, .xls, .xlsx, .csv, .doc, .docx, .txt, .rtf";
    }
    if (file.size > maxSize) {
      setError("File size is over 10MB. Please try again.");
      return "File size is over 10MB. Please try again.";
    }
    setError(null);
    return null;
  };

  const handleAddMore = () => {
    const newId = fileUploads.length + 1;
    const newFileUpload = {
      id: newId,
      name: "",
      size: null,
      type: "",
      file: null,
      error: null,
    };
    setFileUploads([...fileUploads, newFileUpload]);
  };

  // const handleFileUpload = async (data: any) => {
  //   try {
  //     setIsLoading(true);
  //     const response = await multipleFileUpload(data);
  //     if (response) {
  //       const res = response.data.data;
  //       const successMessage = "Documents uploaded successfully!";
  //       onUploadSuccess(successMessage);
  //     }
  //     setIsLoading(false);
  //   } catch (err) {
  //     setIsLoading(false);
  //     setError(null);
  //   }
  //   handleClose(true);
  // };

  const handleFileUpload = async (data: any) => {
    try {
      const response = await multipleFileUpload(data);
      if (response) {
        const res = response.data.data;
        return { success: true };
      }
    } catch (err) {
      return { success: false };
    }
  };

  const handleUpload = () => {
    const filesToSend = Object.values(fileUploads).map((item) => item.file);
    var selectedType = null;
    if (selectedDocumentType !== "") {
      selectedType = documentTypes.find((d) => d.name === selectedDocumentType);
    }
    // let param = {
    //   files: filesToSend,
    //   shipmentGUID: shipmentGUID,
    //   insertUserID: token.UserId,
    //   documentTypeID: selectedType ? selectedType.documentTypeID : null,
    // };

    if (fileUploads.length > 0) {
      //handleFileUpload(param);
      handleUploadAllFiles(filesToSend, selectedType, shipmentGUID);
    }

    setFileUploads([
      { id: 1, name: "", size: null, type: "", file: null, error: null },
      { id: 2, name: "", size: null, type: "", file: null, error: null },
      { id: 3, name: "", size: null, type: "", file: null, error: null },
    ]);
    setSelectedDocumentType("");
  };

  const handleUploadAllFiles = async (
    filesToSend: any,
    selectedType: any,
    shipmentGUID: any
  ) => {
    setIsLoading(true);
    const results = await Promise.all(
      filesToSend.map(async (file: any) => {
        if (file) {
          const fileParam = {
            shipmentGUID: shipmentGUID,
            insertUserID: token.UserId,
            documentTypeID: selectedType ? selectedType.documentTypeID : null,
            files: [file], // Send the current file
          };
          return handleFileUpload(fileParam); // Call upload for each file
        } else {
          return { success: false };
        }
      })
    );

    // Handle the results of all file uploads
    const successFiles = results.filter((result) => result.success);
    const failedFiles = results.filter((result) => !result.success);

    if (successFiles.length > 0) {
      //const successMessage = `${successFiles.length} Documents uploaded successfully!`;
      const successMessage = `Documents uploaded successfully!`;
      onUploadSuccess(successMessage);
    }

    if (failedFiles.length > 0) {
      const errorMessage = `${failedFiles.length} Documents failed to upload.`;
      setError(errorMessage);
    }

    setIsLoading(false);
    handleClose(true);
  };

  const handleDialogClose = (isReload: boolean) => {
    setFileUploads([
      { id: 1, name: "", size: null, type: "", file: null, error: null },
      { id: 2, name: "", size: null, type: "", file: null, error: null },
      { id: 3, name: "", size: null, type: "", file: null, error: null },
    ]);
    setSelectedDocumentType("");
    handleClose(isReload);
  };

  const isSaveAndUploadDisabled = fileUploads.every(
    (fileUpload) => fileUpload.file === null
  );

  const toPascalCase = (str:any) => {
    return str
      .replace(/\w\S*/g, (word:any) => word.charAt(0).toUpperCase() + word.substr(1).toLowerCase())
      .replace(/\s+/g, '');
  };

  return (
    <Dialog
      open={open}
      onClose={() => handleDialogClose(false)}
      maxWidth="lg"
      fullWidth
    >
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6" className="sub-track-header">
            Attach Documents
          </Typography>
          <IconButton onClick={() => handleDialogClose(false)} id="btnAddAttachDoc">
            <ClearIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        {isLoading ? (
          <>
            <Loader />
          </>
        ) : (
          <>
            <Typography variant="body2" className="addr_lbl1 pt-8">
              Press the BROWSE button to search for files, when finished
              selecting, press the SAVE & UPLOAD link. You can add and remove
              lines by pressing the add more and remove links.
            </Typography>
            <Typography variant="body2" className="addr_lbl1">
              (NOTE: File upload size is restricted to 10 MB.)
            </Typography>

            <FormControl
              size="small"
              fullWidth
              className="mt-16 mb-8 choose_doc_input"
            >
              <InputLabel id="document-type-label" className="d-flex">
                Choose Document Type (Optional)
              </InputLabel>
              <Select
                labelId="document-type-label"
                value={selectedDocumentType}
                label="Choose Document Type (Optional)"
                onChange={(event) =>
                  setSelectedDocumentType(event.target.value)
                }
              >
                {documentTypes.length > 0 &&
                  documentTypes.map((item: DocumentTypeItemDto) => (
                    <MenuItem key={item.documentTypeID} value={item.name} id={`libtnExport`}>
                      {item.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            {fileUploads.map((fileUpload) => (
              <Box
                key={fileUpload.id}
                className={`file-upload-container ${
                  window.innerWidth <= 600 ? "file-upload-container-column" : ""
                }`}
              >
                <TextField
                  variant="outlined"
                  size="small"
                  value={fileUpload.name}
                  id="txtFileUpload"
                  label="Select File to Attach"
                  InputProps={{
                    readOnly: true,
                  }}
                  // error={true}
                  error={!!fileUpload.error}
                  helperText={fileUpload.error || ""}
                  className="text-field-trackOrder"
                />
                <div className="Browse-btn">
                  <Box className="button-container">
                    <Button variant="outlined" component="label" id="btnBrowse">
                      Browse
                      <VisuallyHiddenInput
                        type="file"
                        onChange={(e) => handleFileChange(e, fileUpload.id)}
                      />
                    </Button>
                    <Button
                      color="primary"
                      startIcon={<ClearIcon />}
                      onClick={() => handleRemoveFile(fileUpload.id)}
                      id="btnRemove"
                    >
                      Remove
                    </Button>
                  </Box>
                </div>
              </Box>
            ))}
          </>
        )}
      </DialogContent>
      <DialogActions className="d-flex p-2 justify-content-end page-footer">
        <Button
          variant="outlined"
          color="primary"
          startIcon={<AttachFileIcon />}
          className="doc_action_btn"
          onClick={handleAddMore}
          disabled={fileUploads.length >= 5}
          id="btnAddMore"
        >
          Add More
        </Button>

        <Button
          variant="contained"
          color="primary"
          id="btnUpload"
          className="doc_action_btn"
          startIcon={<CloudUploadIcon />}
          onClick={handleUpload}
          disabled={isSaveAndUploadDisabled}
        >
          Save & Upload
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AttachDocsDialog;
