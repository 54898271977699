import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
  Grid,
  TextField,
} from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { visuallyHidden } from "@mui/utils";
import { ActiveOrderItemDto } from "../../models/active-order-item-dto";
import { formatDate } from "../../shared/util/date-utils";
import { formatPickupLocation } from "../../shared/util/format-pickup-location";
import { formatDeliveryLocation } from "../../shared/util/format-delivery-location";
import { formatDateTZ } from "../../shared/util/date-tz-utils";
import CommonPagination from "../../shared/components/CustomPagination";
import useAccounntsLogic from "../../shared/lookup/accounts/accounts.logic";
import { useDecodeJWT } from "../../shared/hook/use-decodeJWT";
import useActiveOrdersLogic from "./active-orders.logic";
import Loader from "../../shared/util/loader";
import { useMediaQuery } from "@mui/material";
import NoDataFound from "../../shared/components/NoDataFound";
import { deliverdOptions } from "../../shared/util/common";
import { Link } from "react-router-dom";
import { MAX_COMPANY_ID } from "../../config/constants";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import SearchIcon from "@mui/icons-material/Search";
import CachedIcon from "@mui/icons-material/Cached";
import ActiveOrderFilterDropdown from "../../shared/components/active-order/ActiveOderFilterOptions";
import Footer from "../../shared/components/Footer";

type Order = "desc" | "asc";

interface HeadCell {
  disablePadding: boolean;
  id: keyof ActiveOrderItemDto;
  label: string;
  numeric: boolean;
  minwidth: string;
}

interface ActiveOrdersProps {
  title: string;
  orderType: string;
  // other props
}

const headCellsItem: HeadCell[] = [
  {
    id: "shipmentNumber",
    numeric: true,
    disablePadding: false,
    label: "ORDER #",
    minwidth: "110",
  },
  {
    id: "orderStatusDisplay",
    numeric: true,
    disablePadding: false,
    label: "STATUS",
    minwidth: "140",
  },
  {
    id: "orderDate",
    numeric: true,
    disablePadding: false,
    label: "PLACED",
    minwidth: "110",
  },
  {
    id: "orderDateTime", //For recent delivery
    numeric: true,
    disablePadding: false,
    label: "PLACED",
    minwidth: "110",
  },
  {
    id: "reference",
    numeric: true,
    disablePadding: false,
    label: "REFER",
    minwidth: "110",
  },
  {
    id: "pickupCompanyName",
    numeric: true,
    disablePadding: false,
    label: "PICKUP AT",
    minwidth: "299",
  },
  {
    id: "deliveryCompanyName",
    numeric: true,
    disablePadding: false,
    label: "DELIVERY TO",
    minwidth: "299",
  },
  {
    id: "updatedDeliveryDateTime",
    numeric: true,
    disablePadding: false,
    label: "ESTIMATED DELIVERY",
    minwidth: "229",
  },
  {
    id: "podDateTime", //For recent delivery
    numeric: true,
    disablePadding: false,
    label: "DELIVERY DATE",
    minwidth: "200",
  },
  {
    id: "podSignature",
    numeric: true,
    disablePadding: false,
    label: "SIGNATURE",
    minwidth: "110",
  },
];

const filtersOptions = [
  "orderStatusDisplay",
  "pickupCompanyName",
  "deliveryCompanyName",
  "updatedDeliveryDateTime",
];
interface EnhancedTableProps {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof ActiveOrderItemDto
  ) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}
type DeliveryType = "2" | "7" | "10" | "30" | "60";

const ActiveOrders: React.FC<ActiveOrdersProps> = ({ title, orderType }) => {
  const [order, setOrder] = React.useState<Order>("desc");
  const [orderBy, setOrderBy] =
    React.useState<keyof ActiveOrderItemDto>("shipmentNumber");
  const [notData, setNotData] = React.useState<any>("");
  const { error, account } = useAccounntsLogic();
  const [isLoading, setIsLoading] = useState(false);
  const { activeOrderError, activeOrders, recentDeliveries, filterOptions } =
    useActiveOrdersLogic();
  const [accounts, setAccounts] = useState<any>([]);
  const [activeOrdesData, setActiveOrdesData] = useState<ActiveOrderItemDto[]>(
    []
  );
  const token = useDecodeJWT();
  const [selectAccount, setSelectAccount] = React.useState("All");
  const [delivery, setDelivery] = React.useState("2");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRecord, setTotalRecord] = useState(0);
  const isMobile = useMediaQuery("(max-width:599px)");
  const isTablet = useMediaQuery("(max-width:905px)");
  const [expanded, setExpanded] = useState(false);
  const [expandedMainSearch, setExpandedMainSearch] = useState(false);
  const [headCellFiltersOptions, setHeadCellFiltersOptions] = useState(null);
  const [headCellFilters, setHeadCellFilters] = useState({
    orderStatusDisplay: null,
    updatedDeliveryDateTime: null,
    pickupCompanyName: null,
    deliveryCompanyName: null,
    accountNumber: "All",
    searchByReference: null,
  });
  const [headCells, setHeadCells] = useState(headCellsItem);
  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof ActiveOrderItemDto
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    setPage(0);
  };
  interface MenuStyles {
    maxHeight: string;
  }

  const [menuStyles, setMenuStyles] = useState<MenuStyles>({
    maxHeight: "200px", // Default max height for desktop
  });
  useEffect(() => {
    getActiveOrders();
    if (orderType === "RecentDeliveries") {
      const newHeadCells = headCellsItem.filter(
        (cell) =>
          cell.id != "orderStatusDisplay" &&
          cell.id != "updatedDeliveryDateTime" &&
          cell.id != "orderDate"
      );
      setHeadCells(newHeadCells);
    } else {
      const newHeadCells = headCellsItem.filter(
        (cell) =>
          cell.id != "podSignature" &&
          cell.id != "podDateTime" &&
          cell.id != "orderDateTime"
      );
      setHeadCells(newHeadCells);
    }
  }, [order, orderBy, selectAccount, page, rowsPerPage, orderType, delivery]);

  useEffect(() => {
    setDelivery("2");
    if (orderType === "ActiveOrders") {
      getFilterOptions();
    }
  }, [orderType]);

  const handleTextFieldChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setHeadCellFilters((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const handleFilterChange = (name: string, _item: any) => {
    setHeadCellFilters((prev) => ({
      ...prev,
      [name]: _item,
    }));
  };
  const toggleExpand = () => {
    setExpanded(!expanded);
  };
  const toggleExpandMainSearch = () => {
    setExpandedMainSearch(!expandedMainSearch);
  };

  function EnhancedTableHead(props: EnhancedTableProps) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler =
      (property: keyof ActiveOrderItemDto) =>
      (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
      };

    return (
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => (
            <TableCell
              width={headCell.minwidth}
              key={headCell.id}
              align={"left"}
              sx={{
                minWidth: headCell.minwidth,
              }}
              style={{
                paddingTop:
                  !filtersOptions.includes(headCell.id) &&
                  orderType === "ActiveOrders"
                    ? "13px !important"
                    : "8px",
              }}
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.id ? order : false}
              className={`${
                !filtersOptions.includes(headCell.id) &&
                orderType === "ActiveOrders"
                  ? "paddingTop-active-filters"
                  : ""
              }`}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>

              {filtersOptions.includes(headCell.id) &&
                orderType === "ActiveOrders" && (
                  <TableSortLabel className="hide-sort-arrow">
                    <ActiveOrderFilterDropdown
                      name={headCell.id}
                      onSelectedItem={(name, filters) => {
                        handleFilterChange(name, filters);
                      }}
                      headCellFilters={headCellFilters}
                      headCellFiltersOptions={headCellFiltersOptions}
                    />
                  </TableSortLabel>
                )}
            </TableCell>
          ))}
          {/* {orderType === "WillCallOrders" &&  <TableCell>EDIT</TableCell>} */}
        </TableRow>
      </TableHead>
    );
  }

  const getAccounts = useCallback(async () => {
    try {
      const param = {
        userId: token.UserId,
        companyID: MAX_COMPANY_ID,
        useIncludeAllItemrId: false,
      };

      const response = await account(param, true); //true is passed to get always data from api to update the redux data.
      if (response.data && response.data.data) {
        setAccounts(response.data.data);
      } else {
        setAccounts([]);
        setNotData("Data not found");
      }
    } catch {
      setAccounts([]);
      setNotData("Data not found");
    }
  }, [token.UserId]);

  useEffect(() => {
    getAccounts();
    setNotData("Data not found");
  }, [getAccounts]);

  const {
    orderStatusDisplay,
    updatedDeliveryDateTime,
    pickupCompanyName,
    deliveryCompanyName,
    accountNumber,
  } = headCellFilters;
  //Call the search api as soon as filter option updates
  useEffect(() => {
    if (page != 0) {
      setPage(0);
    } else {
      getActiveOrders(1);
    }
  }, [
    orderStatusDisplay,
    updatedDeliveryDateTime,
    pickupCompanyName,
    deliveryCompanyName,
  ]);

  useEffect(() => {
    getFilterOptions();
    if (page != 0) {
      setPage(0);
    } else {
      getActiveOrders(1);
    }
    setHeadCellFilters((prev) => ({
      ...prev,
      orderStatusDisplay: null,
      updatedDeliveryDateTime: null,
      pickupCompanyName: null,
      deliveryCompanyName: null,
    }));
  }, [accountNumber]);

  const handleSearch = () => {
    setHeadCellFilters((prev) => ({
      ...prev,
      orderStatusDisplay: null,
      updatedDeliveryDateTime: null,
      pickupCompanyName: null,
      deliveryCompanyName: null,
    }));
    if (page != 0) {
      setPage(0);
    } else {
      getActiveOrders(1);
    }
  };
  const resetAllFilters = () => {
    setHeadCellFilters((prev) => ({
      ...prev,
      orderStatusDisplay: null,
      updatedDeliveryDateTime: null,
      pickupCompanyName: null,
      deliveryCompanyName: null,
      searchByReference: null,
    }));

    if (page != 0) {
      setPage(0);
    } else {
      getActiveOrders(1, true);
    }
  };
  const getActiveOrders = async (
    defaultPage: number = 0,
    resetFilters: boolean = false
  ) => {
    try {
      setIsLoading(true);
      let response;
      if (orderType == "RecentDeliveries") {
        if (orderBy === "orderDate") {
          setOrderBy("orderDateTime");
        }
        if (orderBy === "updatedDeliveryDateTime") {
          setOrderBy("podDateTime");
        }

        const param = {
          accountNumber: selectAccount === "All" ? "" : selectAccount,
          orderBy: orderBy,
          sorting: order,
          pagesize: rowsPerPage === -1 ? totalRecord : rowsPerPage,
          pageNo: page + 1,
          days: parseInt(delivery),
        };
        response = await recentDeliveries(param);
      } else {
        const param = {
          orderType: orderType,
          accountNumber:
            headCellFilters?.accountNumber === "All"
              ? ""
              : headCellFilters?.accountNumber,
          orderBy: orderBy,
          sorting: order,
          pagesize: rowsPerPage,
          pageNo: defaultPage ? defaultPage : page + 1,
          reference: resetFilters
            ? null
            : headCellFilters?.searchByReference || null,
          orderStatusDisplay: resetFilters
            ? null
            : headCellFilters?.orderStatusDisplay || null,
          deliveryCompanyName: resetFilters
            ? null
            : headCellFilters?.deliveryCompanyName || null,
          pickupCompanyName: resetFilters
            ? null
            : headCellFilters?.pickupCompanyName || null,
          updatedDeliveryDateTime: resetFilters
            ? null
            : headCellFilters?.updatedDeliveryDateTime || null,
        };
        response = await activeOrders(param);
      }

      if (response.data && response.data.data) {
        setActiveOrdesData(response.data.data);
        setTotalRecord(response.data.totalCount);
      }
      setIsLoading(false);
    } catch {
      setIsLoading(false);
      setActiveOrdesData([]);
      setTotalRecord(0);
    }
  };

  const getFilterOptions = async () => {
    try {
      const param = {
        orderType: orderType,
        accountNumber:
          headCellFilters?.accountNumber === "All"
            ? ""
            : headCellFilters?.accountNumber,
        orderBy: orderBy,
        sorting: order,
        pagesize: rowsPerPage === -1 ? totalRecord : rowsPerPage,
        pageNo: page + 1,
        orderStatusDisplay: null,
        deliveryCompanyName: null,
        pickupCompanyName: null,
        updatedDeliveryDateTime: null,
      };
      const response = await filterOptions(param);
      if (response.data && response.data.data) {
        setHeadCellFiltersOptions(response.data.data[0]);
      }
    } catch {
      setHeadCellFiltersOptions(null);
    }
  };

  const handleChange = (event: SelectChangeEvent) => {
    if (orderType === "RecentDeliveries") {
      setSelectAccount(event.target.value as string);
    } else {
      setHeadCellFilters((prev) => ({
        ...prev,
        accountNumber: event.target.value as any,
      }));
    }
  };

  const handleDeliveryChange = (event: SelectChangeEvent) => {
    setDelivery(event.target.value as string);
  };

  const handleChangePage = (
    _: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (pageSize: number) => {
    if (pageSize === -1) {
      setRowsPerPage(-1);
    } else {
      setRowsPerPage(pageSize);
    }
    setPage(0);
  };

  const dayStrings: Record<DeliveryType, string> = {
    "2": "two",
    "7": "seven",
    "10": "ten",
    "30": "thirty",
    "60": "sixty",
  };

  const daysTitle = useMemo(() => {
    return dayStrings[delivery as DeliveryType] || dayStrings["2"];
  }, [delivery]);

  return (
    <>
      <div
        className={`container-fluid active-order ${
          orderType === "ActiveOrders" ? "active-order-mobile" : ""
        }`}
      >
        <>
          {orderType === "RecentDeliveries" && (
            <>
              <Box
                className="Main_head_lbl pt-16"
                display="flex"
                flexDirection="column"
                alignItems="start"
                justifyContent="space-between"
              >
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  width="100%"
                >
                  <Box>
                    <Typography
                      className="Main-Header active_lbl"
                      variant="h4"
                      color={"primary"}
                    >
                      {title}
                    </Typography>
                  </Box>
                  <Box
                    className="dropdown-combo gap-16"
                    display="flex"
                    alignItems="center"
                  >
                    <FormControl
                      size="small"
                      sx={{ width: isMobile ? "100%" : isTablet ? 200 : 295 }}
                    >
                      <InputLabel id="demo-select-small-label-ac">
                        Select Account
                      </InputLabel>
                      <Select
                        labelId="demo-select-small-label-id-ac"
                        id="ddAoAccount"
                        value={selectAccount}
                        label="Select Account"
                        onChange={handleChange}
                        sx={{ width: isMobile ? "100%" : undefined }}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          PaperProps: {
                            style: menuStyles,
                          },
                        }}
                      >
                        <MenuItem value="All">All</MenuItem>
                        {accounts.length > 0 &&
                          accounts.map((account: any) => (
                            <MenuItem
                              key={account.userAccountRowGUID}
                              value={account.accountNumber}
                            >
                              {account.displayName}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>

                    {orderType === "RecentDeliveries" && (
                      <FormControl
                        size="small"
                        sx={{ width: isMobile ? "100%" : isTablet ? 200 : 295 }}
                      >
                        <InputLabel id="demo-select-small-label-dl">
                          Delivered
                        </InputLabel>
                        <Select
                          labelId="demo-select-small-label-lbl"
                          id="ddAoDelivery"
                          value={delivery}
                          label="Select Delivered"
                          onChange={handleDeliveryChange}
                          sx={{ width: isMobile ? "100%" : undefined }}
                        >
                          {deliverdOptions.length > 0 &&
                            deliverdOptions.map((_item: any) => (
                              <MenuItem key={_item.value} value={_item.value}>
                                {_item.displayName}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    )}
                  </Box>
                </Box>
                {orderType === "RecentDeliveries" && (
                  <Box className="Sub_info_section">
                    <Typography className="Sub_info_text">
                      {`Your most recent deliveries. This list includes shipments from the past ${daysTitle} days.`}
                    </Typography>
                  </Box>
                )}
              </Box>
            </>
          )}

          {orderType === "ActiveOrders" && (
            <>
              <Box
                className="Main_head_lbl pt-16"
                display="flex"
                flexDirection="column"
                alignItems="start"
                justifyContent="space-between"
              >
                <Box>
                  <Typography
                    className="Main-Header activeheader_lbl"
                    variant="h4"
                    color={"primary"}
                  >
                    {title}
                  </Typography>
                </Box>
              </Box>
            </>
          )}

          {orderType === "ActiveOrders" && (
            <>
              <Box className="d-flex flex-column w-100 border rounded active-order-file">
                <Typography
                  variant="h6"
                  className="header-sub-title"
                  sx={{ paddingLeft: "4px", paddingRight: "4px" }}
                >
                  Search Criteria
                  <>
                    {isMobile && (
                      <Button
                        onClick={toggleExpandMainSearch}
                        style={{ float: "right" }}
                      >
                        {expandedMainSearch ? <ExpandLess /> : <ExpandMore />}
                      </Button>
                    )}
                  </>
                </Typography>

                <Grid
                  container
                  spacing={2}
                  className={
                    !expandedMainSearch && isMobile
                      ? "hide-search-default mt-0"
                      : "mt-0"
                  }
                >
                  <Grid item xs={12} sm={6} md={3} lg={3}>
                    <FormControl size="small" className="w-100">
                      <InputLabel id="demo-select-small-label-sa">
                        Select Account
                      </InputLabel>
                      <Select
                        labelId="demo-select-small-label-id"
                        id="ddAoAccount"
                        value={headCellFilters?.accountNumber}
                        label="Select Account"
                        onChange={handleChange}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          PaperProps: {
                            style: menuStyles,
                          },
                        }}
                      >
                        <MenuItem value="All">All</MenuItem>
                        {accounts.length > 0 &&
                          accounts.map((account: any) => (
                            <MenuItem
                              key={account.userAccountRowGUID}
                              value={account.accountNumber}
                            >
                              {account.displayName}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={5}>
                    <FormControl size="small" className="w-100">
                      {/* <InputLabel id="searchByReference-label">
                        Search by Reference
                      </InputLabel> */}
                      <TextField
                        id="searchByReference"
                        size="small"
                        variant="outlined"
                        label="Search by Reference"
                        className="w-100"
                        value={headCellFilters?.searchByReference || ""}
                        name="searchByReference"
                        onChange={handleTextFieldChange}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} md={5} lg={4}>
                    <>
                      <FormControl
                        size="small"
                        className="w-100 Filter-section"
                      >
                        <Button
                          id="ddAoReset"
                          variant="outlined"
                          className={`d-flex reset_btn_filter`}
                          startIcon={<CachedIcon />}
                          onClick={() => {
                            resetAllFilters();
                          }}
                        >
                          Reset Filters
                        </Button>
                        <Button
                          id="ddAoSearch"
                          variant="contained"
                          color="primary"
                          className="Search_small_btn active_search_btn"
                          startIcon={<SearchIcon />}
                          onClick={() => {
                            handleSearch();
                          }}
                        >
                          Search
                        </Button>
                      </FormControl>
                    </>
                  </Grid>
                </Grid>
              </Box>
            </>
          )}

          {orderType === "ActiveOrders" && isMobile && (
            <>
              <Box className="d-flex flex-column w-100 border mt-2 rounded mb-16 active-order-file">
                <Typography
                  variant="h6"
                  sx={{ paddingLeft: "4px" }}
                  className="header-sub-title Search-lbl"
                >
                  Filters
                  <>
                    <Button onClick={toggleExpand} style={{ float: "right" }}>
                      {expanded ? <ExpandLess /> : <ExpandMore />}
                    </Button>
                  </>
                </Typography>
                <Grid
                  container
                  spacing={2}
                  className={!expanded ? "hide-search-default" : ""}
                  sx={{
                    marginTop: {
                      xs: 0, // 0px margin-top for mobile
                      sm: 0, // 0 margin-top for larger screens
                    },
                  }}
                >
                  {filtersOptions.map((_item: string) => (
                    <ActiveOrderFilterDropdown
                      key={_item}
                      name={_item}
                      onSelectedItem={(name, filters) => {
                        handleFilterChange(name, filters);
                      }}
                      headCellFilters={headCellFilters}
                      isMobile={true}
                      headCellFiltersOptions={headCellFiltersOptions}
                    />
                  ))}
                </Grid>
              </Box>
            </>
          )}

          <div className="d-flex align-items-center">
            <div className="content pb-15 active-order">
              <div className="subtitle"></div>
            </div>
          </div>
          <Box sx={{ width: "100%" }}>
            <Paper
              className="ActiveOrder"
              sx={{ width: "100%", mb: 2, marginTop: "16px" }}
            >
              <TableContainer
                className={` ${
                  orderType == "RecentDeliveries"
                    ? "Recent-delivery-grid"
                    : "table-container-1"
                }  `}
              >
                {" "}
                {/* Add class name for styling */}
                <Table aria-labelledby="tableTitle" className="Table-fixed">
                  <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    rowCount={activeOrdesData.length}
                  />
                  <TableBody>
                    {isLoading ? (
                      <TableRow>
                        <TableCell className="" colSpan={12} align="center">
                          <Loader />
                        </TableCell>
                      </TableRow>
                    ) : activeOrdesData.length === 0 ? (
                      <TableRow
                        className="Nodata_found"
                        id="trActiveOrderDataNotFound"
                      >
                        <TableCell
                          className="No-data-section"
                          colSpan={12}
                          align="center"
                          id="tdDataNotFound"
                        >
                          {orderType === "RecentDeliveries" ? (
                            <NoDataFound
                              heading="No Recent Deliveries Found"
                              title={
                                "The currently selected accounts do not have any orders that have been recently delivered. Try selecting a new account or time frame in the menu above or one of the following:"
                              }
                              leftBtnText={"VIEW ACTIVE ORDERS"}
                              rightBtnText={"NEW ORDER"}
                              leftBtnUrl={"/active-orders"}
                              rightBtnUrl={"/longform-order"}
                            />
                          ) : (
                            <NoDataFound />
                          )}
                        </TableCell>

                        {/* <TableCell className="" colSpan={12} align="center">
                        {notData}
                      </TableCell> */}
                      </TableRow>
                    ) : (
                      activeOrdesData.map(
                        (row: ActiveOrderItemDto, index: any) => {
                          return isMobile ? (
                            <MobileCardView
                              key={row.shipmentGUID}
                              row={row}
                              orderType={orderType}
                            />
                          ) : (
                            <TableRow
                              hover
                              key={row.shipmentGUID}
                              id="trActiveOrder"
                            >
                              <TableCell
                                style={{ minWidth: 100 }}
                                id="tdShipmentNumber"
                              >
                                <Link
                                  to={`/track-order?ShipmentGUID=${row?.shipmentGUID}`}
                                  className="primary"
                                  id={`lbtnOrderId`}
                                >
                                  {row?.shipmentNumber}
                                </Link>
                              </TableCell>
                              {orderType !== "RecentDeliveries" && (
                                <>
                                  <TableCell
                                    style={{ minWidth: 140 }}
                                    id="tdOrderStatusDisplay"
                                  >
                                    <Paper
                                      className="active-status"
                                      elevation={0}
                                    >
                                      <Typography className="status">
                                        {row.orderStatusDisplay}
                                      </Typography>
                                    </Paper>
                                  </TableCell>
                                </>
                              )}
                              {orderType === "RecentDeliveries" ? (
                                <TableCell
                                  style={{ minWidth: 110 }}
                                  id="tdOrderDateTime"
                                >
                                  {formatDate(row.orderDateTime)}
                                </TableCell>
                              ) : (
                                <TableCell
                                  style={{ minWidth: 110 }}
                                  id="tdOrderDate"
                                >
                                  {formatDate(row.orderDate)}
                                </TableCell>
                              )}
                              <TableCell
                                style={{ minWidth: 110 }}
                                id="tdReference"
                              >
                                {row.reference}
                              </TableCell>
                              <TableCell
                                style={{ minWidth: 110 }}
                                id="tdPickup"
                              >
                                <Box
                                  dangerouslySetInnerHTML={{
                                    __html: formatPickupLocation(row),
                                  }}
                                ></Box>
                              </TableCell>
                              <TableCell id="tdDelivery">
                                <Box
                                  dangerouslySetInnerHTML={{
                                    __html: formatDeliveryLocation(row),
                                  }}
                                ></Box>
                              </TableCell>
                              {orderType === "RecentDeliveries" ? (
                                <TableCell
                                  style={{ minWidth: 190 }}
                                  id="tdPodDateTime"
                                >
                                  {row.podDateTime ? (
                                    <>
                                      <Box className="weight-500">
                                        {formatDate(row.podDateTime)}
                                      </Box>
                                      <Box className="pod_datetime">
                                        {formatDateTZ(row.podDateTime)}
                                      </Box>
                                    </>
                                  ) : (
                                    <Box className="weight-500">
                                      {row.estimatedDeliveryDateTime ? (
                                        // formatDateTZ(
                                        //     row.estimatedDeliveryDateTime
                                        //   )
                                        <>
                                          <Box className="weight-500">
                                            {formatDate(
                                              row.estimatedDeliveryDateTime
                                            )}
                                          </Box>
                                          <Box className="pod_datetime">
                                            {formatDateTZ(
                                              row.estimatedDeliveryDateTime
                                            )}
                                          </Box>
                                        </>
                                      ) : (
                                        ""
                                      )}
                                    </Box>
                                  )}
                                </TableCell>
                              ) : (
                                <TableCell
                                  style={{ minWidth: 190 }}
                                  id="tdUpdatedDeliveryDateTime"
                                >
                                  {row.updatedDeliveryDateTime ? (
                                    <>
                                      <Box className="weight-500">
                                        {formatDate(
                                          row.updatedDeliveryDateTime
                                        )}
                                      </Box>
                                      <Box className="pod_datetime">
                                        {formatDateTZ(
                                          row.updatedDeliveryDateTime
                                        )}
                                      </Box>
                                    </>
                                  ) : (
                                    <Box className="weight-500">
                                      {row.estimatedDeliveryDateTime ? (
                                        // formatDateTZ(
                                        //     row.estimatedDeliveryDateTime
                                        //   )
                                        <>
                                          <Box className="weight-500">
                                            {formatDate(
                                              row.estimatedDeliveryDateTime
                                            )}
                                          </Box>
                                          <Box className="pod_datetime">
                                            {formatDateTZ(
                                              row.estimatedDeliveryDateTime
                                            )}
                                          </Box>
                                        </>
                                      ) : (
                                        ""
                                      )}
                                    </Box>
                                  )}
                                </TableCell>
                              )}
                              {orderType === "RecentDeliveries" && (
                                <>
                                  <TableCell
                                    style={{ minWidth: 110 }}
                                    id="tdPodSignature"
                                  >
                                    {row?.podSignature}
                                  </TableCell>
                                </>
                              )}
                              {/* <TableCell>
                              <Box className="weight-600">
                                {orderType === "WillCallOrders" && (
                                  <Box>
                                    <IconButton
                                      // onClick={() => handleEdit(row)}
                                      aria-label="edit"
                                    >
                                      <EditIcon />
                                    </IconButton>
                                    <IconButton
                                      // onClick={() => handleDelete(row)}
                                      aria-label="delete"
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  </Box>
                                )}
                              </Box>
                            </TableCell> */}
                            </TableRow>
                          );
                        }
                      )
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              {/* {!isLoading && activeOrdesData.length > 0 && ( */}

              <CommonPagination
                count={totalRecord}
                page={page}
                rowsPerPage={rowsPerPage}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
              {/* )} */}
            </Paper>
          </Box>
        </>
      </div>
      {orderType === "ActiveOrders" && <Footer />}
    </>
  );
};

// Mobile card view component
const MobileCardView: React.FC<{
  row: ActiveOrderItemDto;
  orderType: string;
}> = ({ row, orderType }) => {
  return (
    <Paper
      className="Card_view"
      elevation={3}
      sx={{ marginBottom: "16px", padding: "0" }}
    >
      <Typography variant="h6" className="Order-head Field_info">
        <span className="field_label">Order #</span>{" "}
        <span className="Order_id Field_value">
          <Link
            to={`/track-order?ShipmentGUID=${row?.shipmentGUID}`}
            className="primary"
            id={`lbtnOrderId`}
          >
            {row?.shipmentNumber}
          </Link>
        </span>
      </Typography>
      {orderType !== "RecentDeliveries" && (
        <>
          <Typography variant="body1" className="Field_info">
            <span className="field_label">Status</span>
            <span className="Field_value">
              {" "}
              <i className="status-info">{row.orderStatusDisplay}</i>
            </span>
          </Typography>
        </>
      )}

      <Typography variant="body1" className="Field_info">
        <span className="field_label">Placed</span>{" "}
        <span className="Field_value">
          {formatDate(
            orderType == "RecentDeliveries"
              ? (row?.orderDateTime as string)
              : (row.orderDate as string)
          )}
        </span>
      </Typography>
      {/* <Typography variant="body1"><span>Placed:</span> <span>{formatDate(row.orderDate)}</span></Typography> */}
      <Typography variant="body1" className="Field_info">
        <span className="field_label">Refer</span>{" "}
        <span className="Field_value">{row.reference}</span>
      </Typography>
      <Typography variant="body1" className="Field_info">
        <span className="field_label"> Pickup </span>
        <span className="Field_value">
          <Box
            dangerouslySetInnerHTML={{
              __html: formatPickupLocation(row),
            }}
          ></Box>
        </span>
        {/* <span> Pickup Location: </span>{formatPickupLocation(row)} */}
      </Typography>
      <Typography variant="body1" className="Field_info">
        <span className="field_label">Delivery </span>
        <span className="Field_value">
          <Box
            dangerouslySetInnerHTML={{
              __html: formatDeliveryLocation(row),
            }}
          ></Box>
        </span>
        {/* <span>Delivery Location:</span> <span>{formatDeliveryLocation(row)}</span> */}
      </Typography>
      <Typography variant="body1" className="Field_info">
        <span className="field_label">
          {" "}
          {orderType == "RecentDeliveries" ? "Delivery Date" : "EST. DELIVERY"}
        </span>
        <span className="Field_value">
          {" "}
          {orderType == "RecentDeliveries" && (
            <>
              <>
                <Box className="weight-400 update-date-mobile">
                  {formatDate(row?.podDateTime as any)}
                </Box>
                <Box className="update-date-time-mobile">
                  {formatDateTZ(row?.podDateTime as any)}
                </Box>
              </>
            </>
          )}
          {orderType != "RecentDeliveries" && (
            <>
              {row.updatedDeliveryDateTime ? (
                <>
                  <Box className="weight-400 update-date-mobile">
                    {formatDate(row.updatedDeliveryDateTime)}
                  </Box>
                  <Box className="update-date-time-mobile">
                    {formatDateTZ(row.updatedDeliveryDateTime)}
                  </Box>
                </>
              ) : (
                <Box className="weight-400 update-date-time-mobile">
                  {row.estimatedDeliveryDateTime
                    ? formatDateTZ(row.estimatedDeliveryDateTime)
                    : "N/A"}
                </Box>
              )}
            </>
          )}
          {/* {row.updatedDeliveryDateTime
            ? formatDateTZ(row.updatedDeliveryDateTime)
            : row.estimatedDeliveryDateTime
            ? formatDateTZ(row.estimatedDeliveryDateTime)
            : "N/A"} */}
        </span>
      </Typography>

      {orderType === "RecentDeliveries" && (
        <Typography variant="body1" className="Field_info">
          <span className="field_label">SIGNATURE</span>
          <span className="Field_value">
            {" "}
            <i className="status-info">{row?.podSignature}</i>
          </span>
        </Typography>
      )}
    </Paper>
  );
};

export default ActiveOrders;
