import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../types/global";
import { LongFormRequestDTO } from "../../models/long-form-request-item-dto";
import { LongFormReOrder } from "./long-form-re-order-create.reducer";



const useLongFormReOrderLogic = () => {
  const dispatch = useDispatch();
  const { LongFormReOrderLoading, LongFormReOrderError } = useSelector((state: RootState) => state.longFormReOrder);

  useEffect(() => {
    if (LongFormReOrderError) {
      // dispatch(showNotification('Active Order failed: ' + error, 'error'));
    }
  }, [LongFormReOrderError, dispatch]);

  const handleLongFormReOrder = async (param:LongFormRequestDTO ) => {
    try {
      const response = await dispatch(LongFormReOrder(param) as any);
      return response;
    } catch (err) {
      throw err;
    }
  };

  return { LongFormReOrderLoading, LongFormReOrderError, longFormReOrder: handleLongFormReOrder };
};

export default useLongFormReOrderLogic;
