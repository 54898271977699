import { Dispatch } from "redux"; // Import Dispatch type
import axiosInstance from "../../config/axios-interceptor";
import { API_BASE_URL } from "../../config/constants";
import { HealthCareDonorRequestDto } from "../../models/healthcare-donor-item-dto";


// Action Types for Default Data
const HEALTH_CARE_DONOR_REQUEST = "HEALTH_CARE_DONOR_REQUEST";
const HEALTH_CARE_DONOR_SUCCESS = "HEALTH_CARE_DONOR_SUCCESS";
const HEALTH_CARE_DONOR_FAILURE = "HEALTH_CARE_DONOR_FAILURE";


// Action Creators for Default Data
export const healthCareDonorRequest = () => ({
    type: HEALTH_CARE_DONOR_REQUEST,
  });
  
  export const healthCareDonorSuccess = () => ({
    type: HEALTH_CARE_DONOR_SUCCESS,
  });
  
  export const healthCareDonorFailure = (error: string) => ({
    type: HEALTH_CARE_DONOR_FAILURE,
    payload: error,
  });



// Initial State for  Active Order
export interface HealthCareDonorState {
    isAuthenticated: boolean;
    HealthCareDonorLoading: boolean;
    HealthCareDonorError: string | null;
  }


  const initialAuthState: HealthCareDonorState = {
    isAuthenticated: false,
    HealthCareDonorLoading: false,
    HealthCareDonorError: null,
  };
  

  // Define the interface for the API response
interface HealthCareDonorResponse {
    success: boolean;
    message: string;
    data: any;
    statusCode: string;
  }
  
  // Reducer for Authentication
  const healthCareDonorReducer = (state = initialAuthState, action: any): HealthCareDonorState => {
    switch (action.type) {
      case HEALTH_CARE_DONOR_REQUEST:
        return { ...state, HealthCareDonorLoading: true, HealthCareDonorError: null };
      case HEALTH_CARE_DONOR_SUCCESS:
        return { ...state, HealthCareDonorLoading: false, isAuthenticated: true };
      case HEALTH_CARE_DONOR_FAILURE:
        return { ...state, HealthCareDonorLoading: false, HealthCareDonorError: action.payload };
      default:
        return state;
    }
  };
  
  // Thunk for Active Order
  export const HealthCareDonor = (param: HealthCareDonorRequestDto) => async (dispatch: Dispatch) => {
      dispatch(healthCareDonorRequest());
      try {
        const response = await axiosInstance.post(
          `${API_BASE_URL}/api/Orders/GetMultipleHealthcareOrders`,
          param
      );
        // Assuming  Active Order was successful if no error occurred
        dispatch(healthCareDonorSuccess());
        const data: HealthCareDonorResponse = response.data;
        // Store token in localStorage
         if (response.status === 200 && data.success) {
          // Store token in localStorage
          return { data };
        } else {
          // Handle different status codes if necessary
          dispatch(healthCareDonorFailure(data.message));
        }
        return response.data;
      } catch (error: any) {
        if(error.response.data.message){
          dispatch(healthCareDonorFailure(error.response.data.message));
          return error.response.data;
        }else{
          dispatch(healthCareDonorFailure(error.message));
        }

      }
    };


    export default healthCareDonorReducer;  
  