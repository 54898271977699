import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { MySettingItemDto } from "../../../models/long-form-item-dto";
import { useEffect, useState } from "react";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import { red } from "@mui/material/colors";
interface NotificationProps {
  DefaultData: MySettingItemDto;
  onNotificationDataChange: (updatedData: any) => void;
  formErrors: any;
  setFormErrors: (errors: any) => void;
  isLimitedRole:boolean;
}

const Notifications: React.FC<NotificationProps> = ({
  DefaultData,
  onNotificationDataChange,
  formErrors,
  setFormErrors,
  isLimitedRole
}) => {
  const [notificationData, setNotificationData] = useState({
    notifyShipperOrderAck: false,
    notifyShipperOrigQdt: false,
    notifyShipperChangeQdt: false,
    notifyShipperDispatched: false,
    notifyShipperPickedUp: false,
    notifyShipperDropped: false,
    notifyShipperDeparted: false,
    notifyShipperArrived: false,
    notifyShipperRecovered: false,
    notifyShipperOutForDelivery: false,
    notifyShipperDelivered: false,
    notifyConseeOrderAck: false,
    notifyConseeOrigQdt: false,
    notifyConseeChangeQdt: false,
    notifyConseeDispatched: false,
    notifyConseePickedUp: false,
    notifyConseeDropped: false,
    notifyConseeDeparted: false,
    notifyConseeArrived: false,
    notifyConseeRecovered: false,
    notifyConseeOutForDelivery: false,
    notifyConseeDelivered: false,
    phoneNumber: "",
    textNotificationOnPickup: false,
    textNotificationOnDelivery: false,
  });

  useEffect(() => {
    if (DefaultData) {
      setNotificationData({
        notifyShipperOrderAck: DefaultData.notifyShipperOrderAck,
        notifyShipperOrigQdt: DefaultData.notifyShipperOrigQdt,
        notifyShipperChangeQdt: DefaultData.notifyShipperChangeQdt,
        notifyShipperDispatched: DefaultData.notifyShipperDispatched,
        notifyShipperPickedUp: DefaultData.notifyShipperPickedUp,
        notifyShipperDropped: DefaultData.notifyShipperDropped,
        notifyShipperDeparted: DefaultData.notifyShipperDeparted,
        notifyShipperArrived: DefaultData.notifyShipperArrived,
        notifyShipperRecovered: DefaultData.notifyShipperRecovered,
        notifyShipperOutForDelivery: DefaultData.notifyShipperOutForDelivery,
        notifyShipperDelivered: DefaultData.notifyShipperDelivered,
        notifyConseeOrderAck: DefaultData.notifyConseeOrderAck,
        notifyConseeOrigQdt: DefaultData.notifyConseeOrigQdt,
        notifyConseeChangeQdt: DefaultData.notifyConseeChangeQdt,
        notifyConseeDispatched: DefaultData.notifyConseeDispatched,
        notifyConseePickedUp: DefaultData.notifyConseePickedUp,
        notifyConseeDropped: DefaultData.notifyConseeDropped,
        notifyConseeDeparted: DefaultData.notifyConseeDeparted,
        notifyConseeArrived: DefaultData.notifyConseeArrived,
        notifyConseeRecovered: DefaultData.notifyConseeRecovered,
        notifyConseeOutForDelivery: DefaultData.notifyConseeOutForDelivery,
        notifyConseeDelivered: DefaultData.notifyConseeDelivered,
        phoneNumber: "",
        textNotificationOnPickup: false,
        textNotificationOnDelivery: false,
      });
    }
  }, [DefaultData]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    // Update the notification data
    setNotificationData((prev) => ({ ...prev, [name]: value }));
    onNotificationDataChange({
      ...notificationData,
      [name]: value,
    });

    // Validation logic for formErrors
    const phoneNumberRequired =
      notificationData.textNotificationOnPickup === true ||
      notificationData.textNotificationOnDelivery === true;

    if (name === "phoneNumber" && phoneNumberRequired && !value) {
      setFormErrors((prevErrors: any) => ({
        ...prevErrors,
        TextPhoneNumber:
          "Phone number is required if both Notify on Pickup and Notify on Delivery are checked.",
      }));
    } else {
      setFormErrors((prevErrors: any) => ({
        ...prevErrors,
        TextPhoneNumber: "",
      }));
    }
  };

  useEffect(() => {
    onNotificationDataChange({
      ...notificationData,
    });
  }, [notificationData]);

  useEffect(() => {
    if (
      notificationData.textNotificationOnPickup === false &&
      notificationData.textNotificationOnDelivery === false
    ) {
      setFormErrors((prevErrors: any) => ({
        ...prevErrors,
        TextPhoneNumber: "",
      }));
    }
  }, [
    notificationData.textNotificationOnPickup,
    notificationData.textNotificationOnDelivery,
  ]);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;

    // Update the notification data
    setNotificationData((prevState) => ({
      ...prevState,
      [name]: checked,
    }));

    // Update validation logic for phone number
    const phoneNumberRequired =
      notificationData.textNotificationOnPickup === true &&
      notificationData.textNotificationOnDelivery === true;

    // if (name === 'textNotificationOnPickup' || name === 'textNotificationOnDelivery') {
    //   if (phoneNumberRequired && !notificationData.phoneNumber) {

    //     setFormErrors((prevErrors: any) => ({
    //       ...prevErrors,
    //       phoneNumber: 'Phone number is required if both Notify on Pickup and Notify on Delivery are checked.',
    //     }));
    //   } else {
    //     setFormErrors((prevErrors: any) => ({
    //       ...prevErrors,
    //       phoneNumber: '',
    //     }));
    //   }
    // }
    onNotificationDataChange({
      ...notificationData,
      [name]: checked,
    });
  };

  return (
    <>
      <Box className="d-flex gap-4 mt-4 w-100">
        <Grid container spacing={2}>
          <Grid item lg={6} md={6} sm={6} xs={12}>
            <Box className="d-flex flex-column w-100 border p16 rounded">
              <Typography variant="h6" className="Sub-header">
                Shipment Email Notifications
              </Typography>
              <Typography
                variant="body1"
                className="weight-500 font14 textcolor"
              >
                Shipment Milestones
              </Typography>
              <Box className="d-flex flex-column w-100 p-2">
                <FormControlLabel
                  control={
                    <Checkbox
                      id="cbShipmentAcknowledged"
                      onChange={handleCheckboxChange}
                      checked={notificationData.notifyShipperOrderAck}
                      name="notifyShipperOrderAck"
                      disabled={isLimitedRole}
                    />
                  }
                  label="Acknowledged"
                />
                <Grid container alignItems="center">
                  <Grid item lg={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          id="cbShipmentEdtSet"
                          onChange={handleCheckboxChange}
                          checked={notificationData.notifyShipperOrigQdt}
                          name="notifyShipperOrigQdt"
                          disabled={isLimitedRole}
                        />
                      }
                      label="EDT Set"
                    />
                  </Grid>
                  <Grid item>
                    <p
                      className="addr_lbl1"
                      style={{
                        margin: 0,
                        paddingLeft: "32px",
                        marginTop: "-15px",
                      }}
                    >
                      Routing has been determined & estimated delivery time
                      (EDT) has been set.
                    </p>
                  </Grid>
                </Grid>

                <Grid container alignItems="center">
                  <Grid item lg={12} xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={handleCheckboxChange}
                          checked={notificationData.notifyShipperChangeQdt}
                          name="notifyShipperChangeQdt"
                          id="cbShipmentEdtChanged"
                          disabled={isLimitedRole}
                        />
                      }
                      label="EDT Changed"
                    />
                  </Grid>
                  <Grid item lg={12} xs={12}>
                    <p
                      className="addr_lbl1"
                      style={{
                        margin: 0,
                        paddingLeft: "32px",
                        marginTop: "-15px",
                      }}
                    >
                      The estimated delivery time (EDT) has been changed.
                    </p>
                  </Grid>
                </Grid>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleCheckboxChange}
                      checked={notificationData.notifyShipperDispatched}
                      name="notifyShipperDispatched"
                      id="cbShipmentDispatched"
                      disabled={isLimitedRole}
                    />
                  }
                  label="Dispatched"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleCheckboxChange}
                      checked={notificationData.notifyShipperPickedUp}
                      name="notifyShipperPickedUp"
                      id="cbShipmentPickedUp"
                      disabled={isLimitedRole}
                    />
                  }
                  label="Picked-Up"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleCheckboxChange}
                      checked={notificationData.notifyShipperDropped}
                      name="notifyShipperDropped"
                      id="cbShipmentDropped"
                      disabled={isLimitedRole}
                    />
                  }
                  label="Dropped-Off"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleCheckboxChange}
                      checked={notificationData.notifyShipperDeparted}
                      name="notifyShipperDeparted"
                      id="cbShipmentDeparted"
                      disabled={isLimitedRole}
                    />
                  }
                  label="Departed"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleCheckboxChange}
                      checked={notificationData.notifyShipperArrived}
                      name="notifyShipperArrived"
                      id="cbShipmentArrived"
                      disabled={isLimitedRole}
                    />
                  }
                  label="Arrived"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleCheckboxChange}
                      checked={notificationData.notifyShipperRecovered}
                      name="notifyShipperRecovered"
                      id="cbShipmentRecovered"
                      disabled={isLimitedRole}
                    />
                  }
                  label="Recovered"
                />
                <Grid container alignItems="center">
                  <Grid item lg={12} xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={handleCheckboxChange}
                          checked={notificationData.notifyShipperOutForDelivery}
                          name="notifyShipperOutForDelivery"
                          id="cbShipmentOfd"
                          disabled={isLimitedRole}
                        />
                      }
                      label="OFD"
                    />
                  </Grid>

                  <Grid item lg={12} xs={12}>
                    <p
                      className="addr_lbl1"
                      style={{
                        margin: 0,
                        paddingLeft: "32px",
                        marginTop: "-15px",
                      }}
                    >
                      Shipment was marked out for delivery
                    </p>
                  </Grid>
                </Grid>

                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleCheckboxChange}
                      checked={notificationData.notifyShipperDelivered}
                      name="notifyShipperDelivered"
                      id="cbShipmentDelivered"
                      disabled={isLimitedRole}
                    />
                  }
                  label="Delivered"
                />
              </Box>
            </Box>
          </Grid>

          <Grid item lg={6} md={6} sm={6} xs={12}>
            <Box className="d-flex flex-column w-100 border p16 rounded">
              <Typography variant="h6" className="Sub-header">
                Consignee Email Notifications
              </Typography>
              <Typography
                variant="body1"
                className="weight-500 font14 textcolor"
              >
                Shipment Milestones
              </Typography>
              <Box className="d-flex flex-column w-100 p-2">
                <FormControlLabel
                  control={
                    <Checkbox
                      id="cbConsigneeAcknowledged"
                      onChange={handleCheckboxChange}
                      checked={notificationData.notifyConseeOrderAck}
                      name="notifyConseeOrderAck"
                      disabled={isLimitedRole}
                    />
                  }
                  label="Acknowledged"
                />
                <Grid container alignItems="center">
                  <Grid item lg={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          id="cbConsigneeEdtSet"
                          onChange={handleCheckboxChange}
                          checked={notificationData.notifyConseeOrigQdt}
                          name="notifyConseeOrigQdt"
                          disabled={isLimitedRole}
                        />
                      }
                      label="EDT Set"
                    />
                  </Grid>
                  <Grid item lg={12}>
                    <p
                      className="addr_lbl1"
                      style={{
                        margin: 0,
                        paddingLeft: "32px",
                        marginTop: "-15px",
                      }}
                    >
                      Routing has been determined & estimated delivery time
                      (EDT) has been set.
                    </p>
                  </Grid>
                </Grid>

                <Grid container alignItems="center">
                  <Grid item lg={12} xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          id="cbConsigneeEdtChanged"
                          onChange={handleCheckboxChange}
                          checked={notificationData.notifyConseeChangeQdt}
                          name="notifyConseeChangeQdt"
                          disabled={isLimitedRole}
                        />
                      }
                      label="EDT Changed"
                    />
                  </Grid>

                  <Grid item lg={12} xs={12}>
                    <p
                      className="addr_lbl1"
                      style={{
                        margin: 0,
                        paddingLeft: "32px",
                        marginTop: "-15px",
                      }}
                    >
                      The estimated delivery time (EDT) has been changed.
                    </p>
                  </Grid>
                </Grid>

                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleCheckboxChange}
                      id="cbConsigneeDispatched"
                      checked={notificationData.notifyConseeDispatched}
                      name="notifyConseeDispatched"
                      disabled={isLimitedRole}
                    />
                  }
                  label="Dispatched"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleCheckboxChange}
                      checked={notificationData.notifyConseePickedUp}
                      name="notifyConseePickedUp"
                      id="cbConsigneePickedUp"
                      disabled={isLimitedRole}
                    />
                  }
                  label="Picked-Up"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleCheckboxChange}
                      checked={notificationData.notifyConseeDropped}
                      name="notifyConseeDropped"
                      id="cbConsigneeDropped"
                      disabled={isLimitedRole}
                    />
                  }
                  label="Dropped-Off"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleCheckboxChange}
                      checked={notificationData.notifyConseeDeparted}
                      name="notifyConseeDeparted"
                      id="cbConsigneeDeparted"
                      disabled={isLimitedRole}
                    />
                  }
                  label="Departed"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleCheckboxChange}
                      checked={notificationData.notifyConseeArrived}
                      name="notifyConseeArrived"
                      id="cbConsigneeArrived"
                      disabled={isLimitedRole}
                    />
                  }
                  label="Arrived"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleCheckboxChange}
                      checked={notificationData.notifyConseeRecovered}
                      name="notifyConseeRecovered"
                      id="cbConsigneeRecovered"
                      disabled={isLimitedRole}
                    />
                  }
                  label="Recovered"
                />

                <Grid container>
                  <Grid item lg={12} xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={handleCheckboxChange}
                          checked={notificationData.notifyConseeOutForDelivery}
                          name="notifyConseeOutForDelivery"
                          id="cbConsigneeOfd"
                          disabled={isLimitedRole}
                        />
                      }
                      label="OFD"
                    />
                  </Grid>
                  <Grid item lg={12} xs={12}>
                    <p
                      className="addr_lbl1"
                      style={{
                        margin: 0,
                        paddingLeft: "32px",
                        marginTop: "-15px",
                      }}
                    >
                      Shipment was marked out for delivery
                    </p>
                  </Grid>
                </Grid>

                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleCheckboxChange}
                      checked={notificationData.notifyConseeDelivered}
                      name="notifyConseeDelivered"
                      id="cbConsigneeDelivered"
                      disabled={isLimitedRole}
                    />
                  }
                  label="Delivered"
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box className="d-flex gap-4 mt-4 w-100">
        <Box className="d-flex flex-column w-100 border p16 rounded">
          <Typography variant="h6" className="Sub-header">
            Text Notifications
          </Typography>

          <Grid container spacing={2} className="pt-0">
            <Grid item lg={4} md={4} sm={6} xs={12} className="pt-0">
              <TextField
                name="phoneNumber"
                label="Phone Number"
                id="txtPhoneNumber"
                variant="outlined"
                fullWidth
                margin="normal"
                value={notificationData.phoneNumber}
                disabled={isLimitedRole}
                onChange={handleInputChange}
                // onKeyDown={handleNumericValue}
                error={!!formErrors.TextPhoneNumber}
                inputProps={{
                  maxLength: 25,
                }}
                // error={!!formErrors.textPhoneNumber}
                InputProps={{
                  endAdornment: !!formErrors.phoneNumber && (
                    <InputAdornment position="end" disablePointerEvents={true}>
                      <InfoRoundedIcon
                        style={{ color: red[500] }}
                        fontSize="small"
                      ></InfoRoundedIcon>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid
              className="pt-8"
              item
              lg={5}
              md={5}
              sm={8}
              xs={12}
              sx={{ Height: "56px", alignItems: "center", display: "flex" }}
            >
              <Grid container>
                <Grid item lg={12} md={12} sm={12} xs={12} className="gap-8">
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="textNotificationOnPickup"
                        id="cbNotifyPickup"
                        checked={notificationData.textNotificationOnPickup}
                        onChange={handleCheckboxChange}
                        disabled={isLimitedRole}
                      />
                    }
                    label="Notify on Pickup"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="textNotificationOnDelivery"
                        id="cbNotifyDelivery"
                        checked={notificationData.textNotificationOnDelivery}
                        onChange={handleCheckboxChange}
                        disabled={isLimitedRole}
                      />
                    }
                    label="Notify on Delivery"
                  />
                </Grid>
                <Grid item lg={6} xs={12}></Grid>
              </Grid>
            </Grid>
            <Grid
              item
              lg={3}
              xs={12}
              sx={{ Height: "56px", alignItems: "center", display: "flex" }}
            ></Grid>
          </Grid>
          <Box className="d-flex gap-4 w-100"></Box>
        </Box>
      </Box>
    </>
  );
};
export default Notifications;
