import { Box, TextField, Typography, Button, Alert, Grid } from "@mui/material";
import "bootstrap/dist/css/bootstrap.min.css";
import ErrorIcon from "@mui/icons-material/Error";
import React, { useEffect, useRef, useState } from "react";
import { useDecodeJWT } from "../../shared/hook/use-decodeJWT";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import useEditorderLogic from "./edit-active-order.logic";
import {
  EditOrderDto,
  Reference,
  UpdateEditOrderDto,
} from "../../models/edit-active-order-dto";
import EditPickupAddress from "../../shared/components/edit-active-order/EditPickupAddress";
import EditDeliveryAddress from "../../shared/components/edit-active-order/EditDeliveryAddress";
import EditReference from "../../shared/components/edit-active-order/EditReference";
import EditPackageInfo from "../../shared/components/edit-active-order/EditPackageInfo";
import { LoadingButton } from "@mui/lab";
import arrowicon from "../../assets/images/logo/img_arrowright_white_a700.svg";
import useUpdateEditorderLogic from "./update-edit-active-order.logic";
import { ShipmentPieceDto } from "../../models/shipment-piece-dto";
import useUpdateShipmentPieceLogic from "../../shared/lookup/shipment-piece/update-shipment-piece/update-shipment-piece.logic";
import useInsertShipmentPieceLogic from "../../shared/lookup/shipment-piece/insert-shipment-piece/insert-shipment-piece.logic";
import useGetShipmentPieceLogic from "../../shared/lookup/shipment-piece/get-shipment-piece/get-shipment-piece.logic";

const EditActiveOrder: React.FC = () => {
  const [searchOrder, setSearchOrder] = useState<string>("");
  const [searchOrderFormErrors, setSearchOrderFormErrors] = useState<
    string | null
  >(null);
  const { editOrderError, editOrder } = useEditorderLogic();
  const { updateEditOrderError, updateEditOrder } = useUpdateEditorderLogic();
  const [orderDetails, setOrderDetails] = useState<EditOrderDto | null>(null);
  const user = useDecodeJWT();
  const [isAlert, setIsAlert] = useState(false);
  const [saveChangeMessage, setSaveChangeMessage] = useState("");
  const [orderTitle, setOrderTitle] = useState("Order #");
  const [formErrors, setFormErrors] = useState<any>({});
  const [updateEditOrderData, setUpdateEditOrderData] =
    useState<UpdateEditOrderDto>();
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const errorRef = useRef<HTMLDivElement | null>(null); // Use a more specific type
  const [editablePiecesData, setEditablePiecesData] = useState<
    ShipmentPieceDto[]
  >([]);
  const [allPiecesData, setAllPiecesData] = useState<ShipmentPieceDto[]>([]);
  const { updateShipmentPiece } = useUpdateShipmentPieceLogic();
  const { insertShipmentPiece } = useInsertShipmentPieceLogic();
  const { getShipmentPiece } = useGetShipmentPieceLogic();
  const getEditOrderDetails = async (TrackingNumber: string) => {
    try {
      setIsAlert(false);
      setErrorMessage("");
      setSuccessMessage("");
      const param = {
        trackingValue: TrackingNumber,
        userGUID: user.UserGUID,
      };

      const response = await editOrder(param);

      if (response.data && response.data.data) {
        if (
          response.data.data ===
          "Invalid Airbill #/Order #. Please ensure the correct Airbill #/Order # is entered."
        ) {
          setSearchOrderFormErrors(response.data.data);
          setSaveChangeMessage("");
          setSuccessMessage("");
        } else {
          if (response.data.data.orderStatus >= 210) {
            setIsAlert(true);
          }
          setOrderTitle(`Edit Order # ${response.data.data.shipmentNumber}`);
          setOrderDetails(response.data.data);
          setUpdateEditOrderData({
            accountNumber: response.data.data.accountNumber,
            reference: response.data.data.reference,
            contents: response.data.data.contents,
            pieces: response.data.data.pieces,
            weight: response.data.data.weight,
            weightUOM: response.data.data.weightUOM,
            length: response.data.data.length,
            width: response.data.data.width,
            height: response.data.data.height,
            sizeUOM: response.data.data.sizeUOM,
            references: transformToReferences(response.data.data),
            declaredValue: response.data.data.declaredValue,
            declaredValueCurrencyID: response.data.data.declaredValueCurrencyID,
            userId: user.UserId ? parseInt(user.UserId) : 0,
            shipmentGUID: response.data.data.shipmentGUID,
            delCountryID: response.data.data.delCountryID,
            puCountryID: response.data.data.puCountryID,
            serviceID: response.data.data.serviceID,
          });

          setSaveChangeMessage(
            "Enter shipment information and press “Save Changes” to update the order."
          );
        }
      } else {
        setSearchOrderFormErrors(
          "Invalid Airbill #/Order #. Please ensure the correct Airbill #/Order # number is entered."
        );
        setSuccessMessage("");
        setOrderDetails(null);
      }
    } catch (err) {
      setSearchOrderFormErrors(editOrderError);
      setOrderDetails(null);
    }
  };

  const handleChangeSearchOrder = (e: any) => {
    setSearchOrderFormErrors(null);
    setSearchOrder(e.target.value);
  };

  const validateTrack = () => {
    let errors = "";
    let isValid = true;

    if (!searchOrder) {
      errors =
        "Invalid Airbill #/Order #. Please ensure the correct Airbill #/Order # number is entered.";
      isValid = false;
    }

    setSearchOrderFormErrors(errors);
    setOrderDetails(null);
    return isValid;
  };

  const handleSearchEditOrder = () => {
    setFormErrors([]);
    if (!validateTrack()) {
      setOrderDetails(null);
      setSuccessMessage("");
      return;
    } else {
      setSearchOrderFormErrors(null);
      getEditOrderDetails(searchOrder);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    const urlParams = new URLSearchParams(window.location.search);
    const shipmentGUID = urlParams.get("ShipmentGUID");
    if (shipmentGUID) {
      getEditOrderDetails("");
    }
  }, []);

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleSearchEditOrder();
    }
  };

  useEffect(() => {
    if (orderDetails) {
      const urlParams = new URLSearchParams(window.location.search);
      const shipmentGUID = urlParams.get("ShipmentGUID");
      if (shipmentGUID) {
        const url = new URL(window.location.href);
        url.searchParams.set("ShipmentGUID", orderDetails.shipmentGUID);
        // Update the browser's address bar
        window.history.pushState({}, "", url);
      }
    }
  }, [orderDetails]);

  const handleReferenceDataChange = (updatedData: any) => {
    const newData = {
      ...updatedData,
    };

    setUpdateEditOrderData((prevLongFormData: any) => ({
      ...prevLongFormData,
      reference: updatedData.reference,
      references: transformToReferences(newData),
    }));
  };

  const validateForm = (data: any) => {
    let errors: any = {};
    if (data && Array.isArray(data)) {
      data.forEach((piece: any, index: number) => {
        if (!piece.weight) {
          errors[
            `allPiecesData[${index}].weight`
          ] = `Please enter Weight for Piece ${index + 1}.`;
        }
      });
    }
    return errors;
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | { name?: string; value: unknown }>
  ) => {
    const target = e.target as HTMLInputElement;
    const { name, type, value, checked } = target;
    if (
      name === "weight" ||
      name === "length" ||
      name === "width" ||
      name === "height" ||
      name === "declaredValue" ||
      name === "pieces"
    ) {
      if (value) {
        setUpdateEditOrderData((prevData: any) => ({
          ...prevData,
          [name]: parseInt(value),
        }));
      }
    } else {
      setUpdateEditOrderData((prevData: any) => ({
        ...prevData,
        [name]: type === "checkbox" ? checked : value,
      }));
    }
    if (name === "declaredValue") {
      if (value === "") {
        setUpdateEditOrderData((prevData: any) => ({
          ...prevData,
          [name]: null,
        }));
      }
    }
  };

  const updateShipmentPieceData = async (pieceData: ShipmentPieceDto) => {
    try {
      const urlParams = new URLSearchParams(window.location.search);
      const shipmentGUID = urlParams.get("ShipmentGUID");
      let param = pieceData;
      if (pieceData?.shipmentPieceID) {
        const response = await updateShipmentPiece(param);
      } else {
        param = {
          ...param,
          shipmentGUID: orderDetails ? orderDetails.shipmentGUID : shipmentGUID,
        };
        await insertShipmentPiece(param);
      }

      // Handle response if needed
    } catch (err) {}
  };

  const handleSubmit = async () => {
    try {
      setSuccessMessage("");
      const validationErrors = validateForm(allPiecesData);

      if (Object.keys(validationErrors).length > 0) {
        setFormErrors(validationErrors);
        errorRef?.current?.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      } else {
        if (updateEditOrderData) {
          let piecesList = allPiecesData;
          if (
            allPiecesData &&
            allPiecesData?.length > 1 &&
            editablePiecesData &&
            editablePiecesData.length > 0
          ) {
            // Update each piece of data first
            await Promise.all(
              editablePiecesData.map(async (pieceData) => {
                try {
                  await updateShipmentPieceData(pieceData);
                } catch (pieceErr) {}
              })
            );

            const isInsertPieceData = allPiecesData.find(
              (_item: any) => !_item?.shipmentPieceID
            );
            if (isInsertPieceData) {
              //Get all the pieces data after updating/insert (Needed for insert case)
              const param = {
                shipmentGUID: updateEditOrderData?.shipmentGUID,
              };
              const piecesResponse = await getShipmentPiece(param as any);
              if (
                piecesResponse.data &&
                piecesResponse.data.data &&
                piecesResponse.data.data.length > 0
              ) {
                const updatedPiecesData = piecesResponse.data.data.map(
                  (piece: any) => ({
                    ...piece,
                    height: piece.height === 0 ? null : piece.height, // Update height to null if it is 0
                    weight: piece.weight === 0 ? null : piece.weight, // Update weight to null if it is 0
                    width: piece.weight === 0 ? null : piece.width, // Update weight to null if it is 0
                    length: piece.weight === 0 ? null : piece.length, // Update weight to null if it is 0
                    declaredValue:
                      piece.weight === 0 ? null : piece.declaredValue, // Update weight to null if it is 0
                  })
                );
                setAllPiecesData(updatedPiecesData);
                piecesList = updatedPiecesData;
              }
            }
          }

          const totalQuantity = allPiecesData.reduce(
            (sum: number, piece: any) => {
              const quantity =
                parseFloat(piece.quantity as unknown as string) || 0;
              return sum + quantity;
            },
            0
          );
          const totalWeight = allPiecesData.reduce(
            (sum: number, piece: any) => {
              const quantity =
                parseFloat(piece.weight as unknown as string) || 0;
              return sum + quantity;
            },
            0
          );
          const updatedPiecesList = allPiecesData.map((piece: any) => {
            // Ensure contents are trimmed to 50 characters
            // Convert length, width, and height to numbers if they're strings
            if (typeof piece.length === "string") {
              piece.length = Number(piece.length);
            }
            if (typeof piece.width === "string") {
              piece.width = Number(piece.width);
            }
            if (typeof piece.height === "string") {
              piece.height = Number(piece.height);
            }
            if (typeof piece.quantity === "string") {
              piece.quantity = Number(piece.quantity);
            }
            return piece;
          });
          updateEditOrderData.quantity = totalQuantity;
          updateEditOrderData.weight = totalWeight;
          updateEditOrderData.length = updatedPiecesList[0].length;
          updateEditOrderData.height = updatedPiecesList[0].height;
          updateEditOrderData.width = updatedPiecesList[0].width;
          updateEditOrderData.weightUOM = updatedPiecesList[0].weightUOM;
          updateEditOrderData.dimWeight = updatedPiecesList[0].dimWeight;
          updateEditOrderData.contents = updatedPiecesList[0].contents;
          updateEditOrderData.pieces = allPiecesData.length;
          if (
            updateEditOrderData.pieces === null ||
            updateEditOrderData.pieces === undefined ||
            !updateEditOrderData.pieces
          ) {
            updateEditOrderData.pieces = 1;
          }

          updateEditOrderData.puReadyDateTime = orderDetails?.readyDateTime;
          updateEditOrderData.serviceID = orderDetails?.serviceID;
          updateEditOrderData.puCountryID = orderDetails?.puCountryID;
          updateEditOrderData.delCountryID = orderDetails?.delCountryID;
          // updateEditOrderData.shipperCustomerID =
          //   orderDetails?.shipperCustomerID;
          updateEditOrderData.piecesList =
            piecesList && piecesList.length > 1 ? piecesList : [];
            (updateEditOrderData.piecesList as Array<{ declaredValue: number }>).forEach((piece, index) => {
              if (updatedPiecesList[index]) {
                  piece.declaredValue = updatedPiecesList[index].declaredValue ?? 0;
              } else {
                  piece.declaredValue = 0;
              }
          });
          
          
          const response = await updateEditOrder(updateEditOrderData);
          const res = response.data;
          if (res) {
            setEditablePiecesData([]);
            window.scrollTo(0, 0);
            if (res.statusCode == "200") {
              setSuccessMessage("Order changes saved successfully.");
              errorRef?.current?.scrollIntoView({
                behavior: "smooth",
                block: "start",
              });
            } else {
              setErrorMessage(res.message);
              setSuccessMessage("");
              errorRef?.current?.scrollIntoView({
                behavior: "smooth",
                block: "start",
              });
            }
          } else {
            setErrorMessage(response.message);
            setSuccessMessage("");
            errorRef?.current?.scrollIntoView({
              behavior: "smooth",
              block: "start",
            });
          }
        }
      }
    } catch {
      setSuccessMessage("");
      setErrorMessage(updateEditOrderError || "");
      errorRef?.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    } finally {
    }
  };

  const transformToReferences = (data: any): Reference[] => {
    const references: Reference[] = [];

    for (let i = 1; i <= 8; i++) {
      const shipmentReferenceIDKey = `shipmentReferenceID${i}`;
      const referenceValueKey = `referenceValue${i}`;

      if (
        data[shipmentReferenceIDKey] !== undefined &&
        data[referenceValueKey] !== undefined
      ) {
        references.push({
          shipmentReferenceID: data[shipmentReferenceIDKey],
          referenceValue: data[referenceValueKey],
        });
      }
    }
    return references;
  };

  return (
    <>
      <div className="container-fluid track-order Track-screen edit-activeorder">
        <Box className="align-items-center d-flex Track-section" ref={errorRef}>
          <Box className="pr-64">
            <Typography
              variant="h4"
              color="primary"
              className="d-flex Main-Header margintop16 orderhead"
              style={{ whiteSpace: "nowrap" }}
            >
              {orderTitle}
            </Typography>
          </Box>
          <Box className="d-flex w-100">
            <TextField
              id="txtTrackingnumber"
              name="Tracking Field"
              label="Airbill #/Order #"
              variant="outlined"
              size="small"
              className="d-flex w-100 pe-2"
              value={searchOrder}
              onChange={handleChangeSearchOrder}
              onKeyPress={handleKeyPress}
              error={!!searchOrderFormErrors}
              InputProps={{
                style: {
                  color: !!searchOrderFormErrors ? "#d32f2f" : "inherit",
                },
                endAdornment: searchOrderFormErrors ? (
                  <ErrorIcon color="error" />
                ) : null,
              }}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleSearchEditOrder}
              startIcon={<SearchOutlinedIcon className="Search-icon mr-0" />}
              className="Order_btn Track_lbl_btn"
              sx={{ minWidth: "auto" }}
              id="btnSearch"
            >
              Search
            </Button>{" "}
          </Box>
        </Box>

        <Box className="">
          <Typography
            variant="h6"
            className="will-call-order"
            sx={{ color: "rgba(0, 0, 0, 0.60)" }}
          >
            Enter Airbill #/Order # and press “Search”. {saveChangeMessage}
          </Typography>
        </Box>

        {searchOrderFormErrors && (
          <Box className="w-100">
            <Alert severity="error" variant="filled" id="txtEaoSearchError">
              {searchOrderFormErrors}
            </Alert>
          </Box>
        )}

        {isAlert && (
          <Box className="w-100">
            <Alert severity="error" variant="filled" id="txtEaoAlert">
              Order has been alerted and cannot be modified. Please contact
              customer service.
            </Alert>
          </Box>
        )}

        {Object.keys(formErrors).length > 0 && (
          <Box className="w-100">
            <Alert variant="filled" severity="error" id="txtEaoFormError">
              {Object.keys(formErrors).map((key) => (
                <div key={key}>{formErrors[key]}</div>
              ))}
            </Alert>
          </Box>
        )}

        {successMessage && (
          <Box className="w-100">
            <Alert variant="filled" severity="success" id="txtSuccess">
              <div>{successMessage}</div>
            </Alert>
          </Box>
        )}

        {errorMessage && (
          <Box className="w-100">
            <Alert variant="filled" severity="error" id="txtErrorMessage">
              <div>{errorMessage}</div>
            </Alert>
          </Box>
        )}

        {orderDetails && (
          <Box className="d-flex flex-column align-items-start">
            <Typography
              variant="h5"
              color="primary"
              className="pickup_section mb-24"
            >
              1. Pickup & Delivery
            </Typography>

            <Grid container spacing={2}>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <EditPickupAddress orderDetails={orderDetails} />
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <EditDeliveryAddress orderDetails={orderDetails} />
              </Grid>
            </Grid>

            <Box className="w-100 Divider mt-40 mb-40"></Box>

            <Typography variant="h5" color="primary" className="ml-8 mt-40">
              2. Package & References
            </Typography>

            <Grid container spacing={2}>
              <Grid item lg={6} md={6} sm={12} xs={12} className="mt-16">
                {updateEditOrderData && (
                  <EditPackageInfo
                    shipmentGUID={orderDetails.shipmentGUID}
                    formErrors={formErrors}
                    setFormErrors={setFormErrors}
                    updateEditOrderData={updateEditOrderData}
                    isAlert={isAlert}
                    handleInputChange={handleInputChange}
                    setEditablePiecesData={setEditablePiecesData}
                    setAllPiecesData={setAllPiecesData}
                  />
                )}
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12} className="mt-16">
                <EditReference
                  orderDetails={orderDetails}
                  onReferenceDataChange={handleReferenceDataChange}
                  isAlert={isAlert}
                />
              </Grid>
            </Grid>
          </Box>
        )}
      </div>
      {orderDetails && !isAlert && (
        <div className="container-fluid">
          <Box className="d-flex justify-content-end gap-2 w-100 Divider-top pt-16 pb-16">
            <LoadingButton
              variant="contained"
              color="primary"
              loadingPosition="end"
              className="d-flex rounded me-2"
              endIcon={<img src={arrowicon} alt="icon right" />}
              onClick={() => handleSubmit()}
              id="btnSaveChanges"
            >
              SAVE CHANGES
            </LoadingButton>
          </Box>
        </div>
      )}
    </>
  );
};

export default EditActiveOrder;
