interface DimWeightParam {
  pickupCountry: string;
  deliveryCountry: string;
  pieces: number;
  length: number | null;
  width: number | null;
  height: number | null;
  sizeUOM: string;
  weightUOM: string;
}

const getDimWt = async (paramsData: any, calculateDimWtAPI: any) => {
  try {
    const response = await calculateDimWtAPI(paramsData);
    if (response.data && response.data.data) {
      return response.data.data;
    }
  } catch (err) {
  } finally {
  }
};

// export const calculateDimWeight = (
//   pickupCountry: string,
//   deliveryCountry: string,
//   pieces: number,
//   length: number,
//   width: number,
//   height: number,
//   sizeUOM: string,
//   weightUOM: string | any,
//   accountNumber?: string | any,
//   puReadyDateTime?: string | any,
//   calculateDimWtAPI?: any
// ) => {
//   const params = {
//     puReadyDateTime: "2024-11-29T06:02:15.503Z",
//     serviceID: 0,
//     origCountryID: pickupCountry,
//     destCountryID: deliveryCountry,
//     length: length || 0,
//     width: width || 0,
//     height: height || 0,
//     sizeUOM: sizeUOM || "IN",
//     weightUOM: weightUOM,
//     qunatity: pieces || 1,
//     customerID: accountNumber || 0,
//   };

//   const dimWtAPi = getDimWt(params, calculateDimWtAPI);
//   return dimWtAPi;
// };

export const calculateDimWeight = (
  pickupCountry: string,
  deliveryCountry: string,
  pieces: number,
  length: number,
  width: number,
  height: number,
  sizeUOM: string,
  weightUOM: string | any
) => {
  let dimwt = 0.0;
  let dims = 0;
  let len = length;
  let wid = width;
  let hgt = height;

  if (len && wid && hgt && sizeUOM && weightUOM) {
    if (pickupCountry === "US" && deliveryCountry === "US") {
      if (sizeUOM === "CM") {
        len = Math.ceil(len * 0.3937007874);
        wid = Math.ceil(wid * 0.3937007874);
        hgt = Math.ceil(hgt * 0.3937007874);
      }
      dims = len * wid * hgt;
      dimwt = dims / 166;
      if (weightUOM === "KG") {
        dimwt = dimwt * 0.45359237;
        dimwt = parseFloat(dimwt.toFixed(1));
      } else {
        dimwt = Math.ceil(dimwt);
      }
    } else {
      if (pickupCountry === "US" && deliveryCountry !== "US") {
        dims = len * wid * hgt;
        if (sizeUOM === "IN") {
          if (weightUOM === "LB") {
            dimwt = dims / 139;
            dimwt = parseFloat(dimwt.toFixed(0));
          } else {
            dimwt = dims / 306;
            dimwt = parseFloat(dimwt.toFixed(1));
          }
        } else {
          dimwt = dims / 5000;
          if (weightUOM === "LB") {
            dimwt = dimwt * 2.20462262;
            dimwt = parseFloat(dimwt.toFixed(0));
          } else {
            dimwt = parseFloat(dimwt.toFixed(1));
          }
        }
      } else {
        let factor = 5000;
        if (pickupCountry === "AU" && deliveryCountry === "AU") factor = 4000;
        if (pickupCountry === "AU" && deliveryCountry !== "AU") factor = 5000;
        if (pickupCountry === "HK" || pickupCountry === "CN") factor = 5000;
        if (sizeUOM === "IN") {
          len = Math.ceil(len * 2.54);
          wid = Math.ceil(wid * 2.54);
          hgt = Math.ceil(hgt * 2.54);
        }
        dims = len * wid * hgt;
        dimwt = dims / factor;
        if (weightUOM === "LB") {
          dimwt = Math.ceil(dimwt * 2.20462262);
        } else {
          dimwt = parseFloat(dimwt.toFixed(1));
        }
      }
    }
  }

  return dimwt * pieces;
};
