import {
  Alert,
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Typography,
  styled,
} from "@mui/material";
import attachicon from "../../../assets/images/trackorder/img_attach_file.svg";
import attachdisabledicon from "../../../assets/images/trackorder/img_attach_disabled.svg";

import { useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import useDefaultDataLogic from "../../../modules/edit-will-call-order/edit-will-call-order-logic";

interface FileItem {
  name: string;
  size: number;
  type: string;
  file: File; // Include the File object
  shipmentDocumentID?: any;
}

interface DocumentProps {
  onDocumentDataChange: (updatedData: any) => void;
  editWillCallOrderData: any;
  setAllFilesToRemove: any;
}

const Documents: React.FC<DocumentProps> = ({
  onDocumentDataChange,
  editWillCallOrderData,
  setAllFilesToRemove,
}) => {
  const { deleteDocument } = useDefaultDataLogic();
  const [files, setFiles] = useState<FileItem[]>([]);
  const [error, setError] = useState<string | null>(null);
  const isDisabled = files.length >= 5;
  const icon = isDisabled ? attachdisabledicon : attachicon;

  const supportedTypes = [
    "image/jpe",
    "image/jpeg",
    "image/jpg",
    "image/gif",
    "image/png",
    "image/bmp",
    "application/pdf",
    "application/xml",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "text/csv",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "text/plain",
    "application/rtf",
  ];

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  useEffect(() => {
    onDocumentDataChange(files);
  }, [files]);

  const handleFileChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    index?: number
  ) => {
    const newFiles = event.target.files;

    if (newFiles && newFiles[0]) {
      const file = newFiles[0];
      const isValid = validateFile(file);
      if (isValid) {
        setError(null);
        const fileItem: FileItem = {
          name: file.name,
          size: file.size,
          type: file.type,
          file,
        };

        if (files.length < 6) {
          // Check if less than 3 files are already added
          if (index !== undefined) {
            const updatedFiles = [...files];
            updatedFiles[index] = fileItem;
            setFiles(updatedFiles);
          } else {
            setFiles([...files, fileItem]);
          }
        } else {
          setError("You can only upload up to 3 files.");
        }
      }
    }
  };

  const handleFileReplace = (index: number, shipmentDocumentID = null) => {
    if (shipmentDocumentID) {
      setAllFilesToRemove((prevData: any) => [...prevData, shipmentDocumentID]);
    }

    document.getElementById(`file-input-${index}`)?.click();
  };

  const handleFileRemove = async (index: number, shipmentDocumentID = null) => {
    const updatedFiles: any = files.filter((_, i) => i !== index);
    if (shipmentDocumentID) {
      const confirm = window.confirm("Are you sure want to delete?");
      if (confirm) {
        try {
          const param = {
            shipmentDocumentId: shipmentDocumentID,
          };

          const response = await deleteDocument(param);
          if (response.data && response.data.data) {
            //const res = response.data.data;
            setFiles(updatedFiles);
          }
        } catch {}
      }
    } else {
      setFiles(updatedFiles);
    }
  };

  const validateFile = (file: File): boolean => {
    const maxSize = 10 * 1024 * 1024; // 10 MB
    if (file.size > maxSize) {
      setError("File size exceeds the limit of 10MB.");
      return false;
    }
    if (!supportedTypes.includes(file.type)) {
      setError("Unsupported file type.");
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (
      editWillCallOrderData?.documents &&
      editWillCallOrderData?.documents?.length > 0
    ) {
      const uploadedFiles = editWillCallOrderData?.documents.map(
        (_item: any) => {
          return {
            ..._item,
            name: _item.originalFileName,
            shipmentDocumentID: _item?.shipmentDocumentID,
          };
        }
      );

      setFiles(uploadedFiles);
    }
  }, [editWillCallOrderData]);

  return (
    <Box className="d-flex gap-4 mt-4 w-100 Divider pb-32 align-flex-start">
      <Grid container spacing={2}>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <Box className="d-flex flex-column w-100 border p16 rounded">
            <Typography variant="h6" className="Sub-header">
              Document Upload
            </Typography>
            <Button
              className={`d-flex rounded ${
                isDisabled ? "disable_doc" : "Attach_doc"
              }`}
              component="label"
              id="btnLfAttachDoc"
              startIcon={<img src={icon} alt="icon left" />}
              sx={{ backgroundColor: "white", color: "black" }}
              disabled={isDisabled}
            >
              Attach Document
              <VisuallyHiddenInput
                type="file"
                onChange={handleFileChange}
                disabled={files.length >= 5}
                id="txtWcAttachDoc"
              />
            </Button>
          </Box>
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <Box className="d-flex flex-column w-100 p16 rounded border">
            <Typography
              variant="body1"
              className="Sub-header"
              sx={{ paddingBottom: "8px!important" }}
            >
              Document List
            </Typography>
            {error && (
              <Box>
                <Alert
                  icon={<InfoOutlinedIcon />}
                  variant="outlined"
                  color="error"
                  id="txtDocsError"
                >
                  <p className="text-danger">
                    Documented upload failed. Please ensure <br />
                    format and size are supported.{" "}
                  </p>
                  <p className="text-danger">
                    Supported file types: jpe, .jpeg, .jpg, .gif, .png,
                    <br /> .bmp, .pdf, .xml, .xls, .xlsx, .csv, .doc, .docx,
                    .txt, .rtf
                  </p>
                </Alert>
              </Box>
            )}
            <Typography
              variant="body1"
              className="sub-header-lbl Divider file-name"
            >
              File Name
            </Typography>

            {files.length > 0 ? (
              files.map((file, index) => (
                <Box className="Divider" key={index}>
                  <Box className="d-flex align-items-center justify-content-between">
                    <Typography variant="body1" className="txt_info_lbl p8">
                      {file.name}
                    </Typography>
                    <Box>
                      <IconButton
                        onClick={() =>
                          handleFileReplace(index, file?.shipmentDocumentID)
                        }
                        id="btnWcEdit"
                      >
                        <EditIcon />
                      </IconButton>
                      <input
                        id={`txtWcFileInput${index}`}
                        type="file"
                        hidden
                        onChange={(e) => handleFileChange(e, index)}
                        
                      />
                      <IconButton
                        onClick={() =>
                          handleFileRemove(index, file?.shipmentDocumentID)
                        }
                         id="btnWcDelete"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  </Box>
                </Box>
              ))
            ) : (
              <>
                <Typography
                  variant="body1"
                  className="p-2 Divider font14 textcolor"
                >
                  No documents uploaded
                </Typography>
              </>
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Documents;
