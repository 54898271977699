import React, { useEffect, useRef, useState } from "react";
import { Box, Button, Grid, MenuItem, Select, Typography } from "@mui/material";
import arrowicon from "../../assets/images/logo/img_arrowright_white_a700.svg";
import Shipper from "../../shared/components/long-form-order/Shipper";
import Documents from "../../shared/components/long-form-order/Documents";
import Notifications from "../../shared/components/long-form-order/Notifications";
import ReadyTime from "../../shared/components/long-form-order/ReadyTime";
import DeliveryTime from "../../shared/components/long-form-order/DeliveryTime";
import BillingAndRefer from "../../shared/components/long-form-order/BillingAndRefer";
import PackageInfo from "../../shared/components/long-form-order/PackageInfo";
import DangerousGoods from "../../shared/components/long-form-order/DangerousGoods";
import {
  ContentItemDto,
  MySettingItemDto,
} from "../../models/long-form-item-dto";
import useDefaultDataLogic from "./long-form-order.logic";
import { useDecodeJWT } from "../../shared/hook/use-decodeJWT";
import useServiceLogic from "../../shared/lookup/service/service.logic";
import {
  LongFormRequestDTO,
  Piece,
} from "../../models/long-form-request-item-dto";

import useLongFormOrderLogic from "./long-form-order-create.logic";
import Alert from "@mui/material/Alert";
import useMultipleFileUploadLogic from "../../shared/document-upload/multiple-document-upload.logic";
import { useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import { MAX_COMPANY_ID } from "../../config/constants";
import LongFormPickupAddress from "../../shared/components/long-form-order/LongFormPickupAddress";
import LongFormDeliveryAddress from "../../shared/components/long-form-order/LongFormDeliveryAddress";
import { CountryItemDto } from "../../models/country-item-dto";
import useCountryLogic from "../../shared/lookup/country/country.logic";
import moment from "moment";

import useMultipleFileUploadWithoutLoaderLogic from "../../shared/document-upload/multiple-document-without-loader-upload.logic";
//import Attributes from "../../shared/components/long-form-order/Attributes";
import { AddressItemDto } from "../../models/address-item-dto";
import useAddressLogic from "../../shared/lookup/address/address.logic";
import RoutePreview from "../route-preview/route-preview";
import { getCurrentDateTimeByAbbr } from "../../shared/util/timezones";
import { useLocation } from "react-router-dom";
import { updateDefaultDataByRateEstimate } from "../../shared/util/long-form ";
import usePackageTypeLogic from "../../shared/lookup/package-type/package-type.logic";

import useRole from "../../shared/hook/use-role";
import useLongFormReOrderLogic from "./long-form-re-order-create.logic";

const LongForm: React.FC = () => {
  const { defaultData } = useDefaultDataLogic();
  const { calculateDimWtAPI } = usePackageTypeLogic();
  const location = useLocation();
  const [defaultDatas, setDefaultDatas] = useState<MySettingItemDto>();
  const { service } = useServiceLogic();
  const [selectedServiceID, setSelectedServiceID] = useState<string>("");
  const [formErrors, setFormErrors] = useState<any>({});
  const [longFormSubmitError, setLongFormSubmitError] = useState<any>();
  const [errorCode, setErrorCode] = useState<any>();
  const [errorCodePickup, setErrorCodePickup] = useState<any>();
  const [errorCodeReadyTime, setErrorCodeReadyTime] = useState<any>();
  const [errorCodeDeliveryTime, setErrorCodeDeliveryTime] = useState<any>();
  const [fileUploadData, setFileUploadData] = useState<any[]>([]);
  const [isValid, setIsValid] = useState(true);
  const childRef = useRef<any>(null);
  const [pickupZipCodeError, SetPickUpZipCodeError] = useState<boolean>(false);
  const [deliveryZipCodeError, SetDeliveryZipCodeError] =
    useState<boolean>(false);
  const [unError, setUNError] = useState<boolean>(false);
  const serviceAvailType = localStorage.getItem("serviceAvailType");
  const errorRef: any = useRef(null);
  const [countryData, setCountryData] = useState<CountryItemDto[]>([]);
  const { country } = useCountryLogic();
  const [accountData, setAccountData] = useState<any>({});
  const [isDisplayAttributes, setIsDisplayAttributes] =
    useState<boolean>(false);
  const userGroupGUID: any = localStorage.getItem("userGroupGUID");
  const [addressData, setAddressData] = useState<AddressItemDto[]>([]);
  const { address } = useAddressLogic();
  const [isRouteRecommendationPage, setIsRouteRecommendationPage] =
    useState(false);
  const [packageTypeDependancyCustID, setPackageTypeDependancyCustID] =
    useState(0);
  const [pickupData, setPickupData] = useState({
    pickupAddress1: "",
    pickupAddress2: "",
    pickupAddress3: "",
    pickupCityName: "",
    pickupPostalCode: "",
    pickupCountryID: "",
    pickupStateProvID: "",
    pickupCompanyName: "",
    pickupInstructions: "",
    pickupAirportID: "",
    pickupEmailAddress: "",
    addressCode: "",
    pickupPhoneNumber: "",
    puKeep: false,
    pickupPersonToSee: "",
    pickupTimezoneID: "",
    puAddressGUID: "",
    pickupReadyTime: "",
    pickupReadyDay: 0,
    pickupReadyNow: false,
    standardAbbr: "",
  });
  const [deliveryData, setDeliveryData] = useState({
    deliveryAddress1: "",
    deliveryAddress2: "",
    deliveryAddress3: "",
    deliveryCityName: defaultDatas?.deliveryCityName,
    deliveryPostalCode: "",
    deliveryCountryID: "",
    deliveryStateProvID: "",
    deliveryCompanyName: "",
    deliveryInstructions: "",
    deliveryAirportID: "",
    deliveryTimezoneID: "",
    deliveryEmailAddress: "",
    addressCode: "",
    deliveryPhoneNumber: "",
    pickupReadyTime: "",
    dropOff: false,
    willCall: false,
    deliveryRequestTime: "",
    holdForPickup: false,
    deliveryRequestAsap: true,
    puReadyNow: false,
    deliveryWhen: "",
    shipperName: "",
    shipperPhone: "",
    shipperEmail: "",
    notifyShipper: false,
    billToType: "",
    accountNumber: "",
    reference1: "",
    reference2: "",
    reference3: "",
    reference4: "",
    reference5: "",
    reference6: "",
    reference7: "",
    reference8: "",
    reference9: "",
    delKeep: false,
    delAddressGUID: "",
    deliveryAttention: "",
    standardAbbr: "",
  });
  const [longFormData, setLongFormData] = useState<LongFormRequestDTO>({});
  const [serviceData, setServiceData] = useState<any>([]);
  const [selectedContent, setSelectedContent] = useState<ContentItemDto>();
  const token = useDecodeJWT();
  const [packageData, setPackageData] = useState<any>({});
  const [piecesData, setPiecesData] = useState<Piece[]>([]);
  const [dangerousGoodData, setDangerousGoodData] = useState<any>({});
  const [shipperData, setShipperData] = useState<any>({});
  const [notificationdData, setNotificationData] = useState<any>({});
  const { LongFormOrderError, longFormOrder } = useLongFormOrderLogic();
  const { LongFormReOrderError, longFormReOrder } = useLongFormReOrderLogic();
  const officeID: any = localStorage.getItem("officeID");
  const { multipleFileUpload } = useMultipleFileUploadLogic();
  const { multipleFileUploadWithoutLoader } =
    useMultipleFileUploadWithoutLoaderLogic();
  const navigate = useNavigate();
  const [pickupReadyTimeData, setPickupReadyTimeData] = useState<any>();
  const [readyTimeData, setReadyTimeData] = useState<any>();
  const [pickupTime, setPickupTime] = useState("");
  const [deliveryTime, setDeliveryTime] = useState("");
  const [pickupZipCodeWarn, setPickupZipCodeWarn] = useState(false);
  const [deliveryZipCodeWarn, setDeliveryZipCodeWarn] = useState(false);
  const [pickupSubmitCount, setPickupSubmitCount] = useState(0);
  const [deliverySubmitCount, setDeliverySubmitCount] = useState(0);
  const operationsAlertEmail: any = localStorage.getItem(
    "operationsAlertEmail"
  );
  const sendNewOrderEmail: any = localStorage.getItem("sendNewOrderEmail");
  const shpUseOrderNumAsTrackNum: any = localStorage.getItem(
    "UseOrderNumAsTrackNum"
  );
  const [servicesDependancyFinished, setServicesDependancyFinished] = useState({
    settingsAPI: false,
    pickUpPostalCode: true,
    deliveryPostalCode: true,
  });
  const isLimitedRole = useRole("limited");
  const [isRateEstimateOrder, setisRateEstimateOrder] = useState(false);
  const onContentChange = (content: any) => {
    const newData = {
      ...content,
    };
    setSelectedContent((prevData: any) => ({
      ...prevData,
      ...newData,
    }));

    setDangerousGoodData((prevData: any) => ({
      ...prevData,
      dgProperName: newData.properName,
      dgUNNumber: newData.unNumber,
      dgIsRadioactive: newData.isRadioactive,
      isDangerousGoods: newData.isDangerousGoods,
      dgCommodityClass: newData.commodityClass,
    }));
  };

  useEffect(() => {
    getDefaultData();
    getCountry();
    getAddress();
    sessionStorage.removeItem("previousLongformData");
    sessionStorage.removeItem("longFormData");
    sessionStorage.removeItem("tempDocumentGUID");
  }, []);

  const getAddress = async () => {
    try {
      const param = {
        addressCode: "",
        companyName: "",
        cityName: "",
        stateProvID: "",
        countryID: "",
        postalCode: "",
        userGroupGUID: userGroupGUID,
      };
      const response = await address(param);
      if (response.data && response.data.data) {
        const res = response.data.data;
        setAddressData(response);
      } else {
        setAddressData([]);
        // return [];
      }
    } catch (err) {
      setAddressData([]);
      return [];
    } finally {
    }
  };

  useEffect(() => {
    //areAllServicesDepFinished:: Needed for stop the multiple api call onLoad
    const areAllServicesDepFinished = Object.values(
      servicesDependancyFinished
    ).every((value) => value === true);

    if (areAllServicesDepFinished) {
      getService();
    }
  }, [
    servicesDependancyFinished,
    pickupData.pickupCountryID,
    pickupData.pickupCityName,
    pickupData.pickupStateProvID,
    pickupData.pickupPostalCode,
    pickupData.pickupAirportID,
    deliveryData.deliveryCountryID,
    deliveryData.deliveryCityName,
    deliveryData.deliveryStateProvID,
    deliveryData.deliveryPostalCode,
    deliveryData.deliveryAirportID,
  ]);
  useEffect(() => {
    if (
      pickupData ||
      deliveryData ||
      defaultDatas ||
      piecesData ||
      shipperData ||
      dangerousGoodData ||
      notificationdData
    ) {
      // const totalQuantity = calculateDimWeight(
      //   "US",
      //   "US",
      //   packageData.pieceCount ?? defaultDatas?.pieces,
      //   packageData.length ?? defaultDatas?.length,
      //   packageData.width ?? defaultDatas?.width,
      //   packageData.height ?? defaultDatas?.height,
      //   "IN",
      //   packageData.weightUOM ?? defaultDatas?.weightUOM
      // );

      setLongFormData((prevLongFormData) => ({
        ...prevLongFormData,
        puAddressGUID: pickupData.puAddressGUID || null,
        delAddressGUID: deliveryData.delAddressGUID || null,
        puAddress1: pickupData.pickupAddress1,
        puAddress2: pickupData.pickupAddress2,
        puAddress3: pickupData.pickupAddress3,
        puAddressCode: pickupData.addressCode || "",
        delAddressCode: deliveryData.addressCode || "",
        puAirportID: pickupData.pickupAirportID,
        puCityName: pickupData.pickupCityName,
        puCountryID: pickupData.pickupCountryID,
        puEmailAddress: pickupData.pickupEmailAddress,
        puPhoneNumber: pickupData.pickupPhoneNumber,
        puInstructions: pickupData.pickupInstructions,
        puPostalCode: pickupData.pickupPostalCode,
        puCompanyName: pickupData.pickupCompanyName,
        puStateProvID: pickupData.pickupStateProvID,
        puKeep: pickupData.puKeep,
        pickupPersonToSee: pickupData.pickupPersonToSee,
        puReadyTime: deliveryData.pickupReadyTime
          ? deliveryData.pickupReadyTime
          : null,
        puDropOff: deliveryData.dropOff,
        isWillCall: deliveryData.willCall,
        delAddress1: deliveryData.deliveryAddress1,
        delAddress2: deliveryData.deliveryAddress2,
        delAddress3: deliveryData.deliveryAddress3,
        delAirportID: deliveryData.deliveryAirportID,
        delCityName: deliveryData.deliveryCityName,
        delCountryID: deliveryData.deliveryCountryID,
        delEmailAddress: deliveryData.deliveryEmailAddress,
        delPhoneNumber: deliveryData.deliveryPhoneNumber,
        delInstructions: deliveryData.deliveryInstructions,
        delPostalCode: deliveryData.deliveryPostalCode,
        delStateProvID: deliveryData.deliveryStateProvID,
        delCompanyName: deliveryData.deliveryCompanyName,
        delRequestAsap: deliveryData.deliveryRequestAsap,
        holdForPickup: deliveryData.holdForPickup,
        deliverWhen: deliveryData.deliveryWhen,
        puTimezoneID: pickupData?.pickupTimezoneID,
        puPersonToSee: pickupData.pickupPersonToSee
          ? pickupData.pickupPersonToSee
          : defaultDatas?.pickupPersonToSee,
        puFaxNumber: defaultDatas?.pickupFaxNumber,
        puAfterHoursNumber: defaultDatas?.pickupAfterHoursNumber,
        puReadyDate: deliveryData.pickupReadyTime
          ? deliveryData.pickupReadyTime
          : null,
        puReadyNow: deliveryData.puReadyNow,
        dropAirportID: defaultDatas?.deliveryAirportID || "",
        puDistance: defaultDatas?.pickupDistance,
        delTimezoneID: deliveryData?.deliveryTimezoneID || "",
        delAttention: deliveryData?.deliveryAttention || "",
        delFaxNumber: defaultDatas?.deliveryFaxNumber || "",
        delAfterHoursNumber: defaultDatas?.deliveryAfterHoursNumber || "",
        delRequestTime: deliveryData?.deliveryRequestTime,
        delDistance: defaultDatas?.deliveryDistance || 0,
        shipperName: shipperData?.shipperName,
        shipperPhone: shipperData?.shipperPhone,
        shipperEmail: shipperData?.shipperEmail,
        billToType: deliveryData.billToType
          ? deliveryData.billToType
          : defaultDatas?.billToType,
        accountNumber: deliveryData.accountNumber
          ? deliveryData.accountNumber
          : defaultDatas?.accountNumber,
        operationsAlertEmail:
          operationsAlertEmail !== "" ? operationsAlertEmail : null,
        sendNewOrderEmail: sendNewOrderEmail ? true : false,
        companyID: MAX_COMPANY_ID,
        insertUserID: parseInt(token?.UserId),
        officeID: parseInt(officeID),
        reference: deliveryData.reference1,
        reference2: deliveryData.reference2,
        reference3: deliveryData.reference3,
        reference4: deliveryData.reference4,
        reference5: deliveryData.reference5,
        reference6: deliveryData.reference6,
        reference7: deliveryData.reference7,
        reference8: deliveryData.reference8,
        reference9: deliveryData.reference9,
        delKeep: deliveryData.delKeep,
        delRequestDate: deliveryData?.deliveryRequestTime || null,
        notifyShipper: shipperData.notifyShipper,
        pieces: packageData.pieceCount ?? 1,
        commodity: packageData.commodity
          ? packageData.commodity
          : defaultDatas?.commodity,
        weight: parseFloat(parseFloat(packageData?.weight).toFixed(2)),
        weightUOM: packageData.weightUOM
          ? packageData.weightUOM
          : defaultDatas?.weightUOM,
        length: packageData.length
          ? parseInt(packageData.length, 10)
          : defaultDatas?.length || 0,
        width: packageData.width
          ? parseInt(packageData.width, 10)
          : defaultDatas?.width || 0,
        height: packageData.height
          ? parseInt(packageData.height, 10)
          : defaultDatas?.height || 0,
        //dimWeight: totalQuantity,
        packagingTypeGUID_Outer: packageData.packagingTypeGUID,
        packagingTypeGUID_Inner: packageData?.packagingTypeGUID,
        contents: selectedContent?.properName,
        declaredValueCurrencyID: packageData.currencyID,
        declaredValue:
          parseFloat(parseFloat(packageData?.value).toFixed(2)) || 0.0,
        isDangerousGoods: dangerousGoodData.isDangerousGoods,
        dgIsRadioactive: dangerousGoodData.dgIsRadioactive,
        dgCommodityClass: dangerousGoodData?.dgCommodityClass,
        dgUNNumber: dangerousGoodData?.dgUNNumber,
        dgProperName: dangerousGoodData?.dgProperName || "",
        dgPackingGroup: dangerousGoodData?.dgPackingGroup || "",
        dgQuantityAndPackType: dangerousGoodData?.dgQuantityAndPackType || "",
        dgPackingInst: dangerousGoodData?.dgPackingInst || "",
        dgAuthorization: dangerousGoodData?.dgAuthorization || "",
        dgEmergencyPhone: dangerousGoodData?.dgEmergencyPhone || "",
        dgSignatory: dangerousGoodData?.dgSignatory || "",
        dgDate: dangerousGoodData?.dgDate || null,
        dgDryIceWeight: dangerousGoodData?.dgDryIceWeight
          ? dangerousGoodData?.dgDryIceWeight
          : null,
        carrierToProvideDryIce: dangerousGoodData?.dgIsDryIceProvide,
        dgGelPackCount: dangerousGoodData?.dgGelPackCount,
        dgTransportationLimit: dangerousGoodData?.dgTransportationLimit || "",
        dgTransportationIndex: dangerousGoodData?.dgTransportationIndex || "",
        carrierToProvideGelPack: dangerousGoodData?.dgIsGelPackProvide,
        notifyShipperOrderAck: notificationdData.notifyShipperOrderAck
          ? notificationdData.notifyShipperOrderAck
          : defaultDatas?.notifyShipperOrderAck,
        notifyShipperOrigQdt: notificationdData.notifyShipperOrigQdt
          ? notificationdData.notifyShipperOrigQdt
          : defaultDatas?.notifyShipperOrigQdt,
        notifyShipperChangeQdt: notificationdData.notifyShipperChangeQdt
          ? notificationdData.notifyShipperChangeQdt
          : defaultDatas?.notifyShipperChangeQdt,
        notifyShipperDispatched: notificationdData.notifyShipperDispatched
          ? notificationdData.notifyShipperDispatched
          : defaultDatas?.notifyShipperDispatched,
        notifyShipperPickedUp: notificationdData.notifyShipperPickedUp
          ? notificationdData.notifyShipperPickedUp
          : defaultDatas?.notifyShipperPickedUp,
        notifyShipperDropped: notificationdData.notifyShipperDropped
          ? notificationdData.notifyShipperDropped
          : defaultDatas?.notifyShipperDropped,
        notifyShipperDeparted: notificationdData.notifyShipperDeparted
          ? notificationdData.notifyShipperDeparted
          : defaultDatas?.notifyShipperDeparted,
        notifyShipperArrived: notificationdData.notifyShipperArrived
          ? notificationdData.notifyShipperArrived
          : defaultDatas?.notifyShipperArrived,
        notifyShipperRecovered: notificationdData.notifyShipperRecovered
          ? notificationdData.notifyShipperRecovered
          : defaultDatas?.notifyShipperRecovered,
        notifyShipperOutForDelivery:
          notificationdData.notifyShipperOutForDelivery
            ? notificationdData.notifyShipperOutForDelivery
            : defaultDatas?.notifyShipperOutForDelivery,
        notifyShipperDelivered: notificationdData.notifyShipperDelivered
          ? notificationdData.notifyShipperDelivered
          : defaultDatas?.notifyShipperDelivered,
        notifyConseeOrderAck: notificationdData.notifyConseeOrderAck
          ? notificationdData.notifyConseeOrderAck
          : defaultDatas?.notifyConseeOrderAck,
        notifyConseeOrigQdt: notificationdData.notifyConseeOrigQdt
          ? notificationdData.notifyConseeOrigQdt
          : defaultDatas?.notifyConseeOrigQdt,
        notifyConseeChangeQdt: notificationdData.notifyConseeChangeQdt
          ? notificationdData.notifyConseeChangeQdt
          : defaultDatas?.notifyConseeChangeQdt,
        notifyConseeDispatched: notificationdData.notifyConseeDispatched
          ? notificationdData.notifyConseeDispatched
          : defaultDatas?.notifyConseeDispatched,
        notifyConseePickedUp: notificationdData.notifyConseePickedUp
          ? notificationdData.notifyConseePickedUp
          : defaultDatas?.notifyConseePickedUp,
        notifyConseeDropped: notificationdData.notifyConseeDropped
          ? notificationdData.notifyConseeDropped
          : defaultDatas?.notifyConseeDropped,
        notifyConseeDeparted: notificationdData.notifyConseeDeparted
          ? notificationdData.notifyConseeDeparted
          : defaultDatas?.notifyConseeDeparted,
        notifyConseeArrived: notificationdData.notifyConseeArrived
          ? notificationdData.notifyConseeArrived
          : defaultDatas?.notifyConseeArrived,
        notifyConseeRecovered: notificationdData.notifyConseeRecovered
          ? notificationdData.notifyConseeRecovered
          : defaultDatas?.notifyConseeRecovered,
        notifyConseeOutForDelivery: notificationdData.notifyConseeOutForDelivery
          ? notificationdData.notifyConseeOutForDelivery
          : defaultDatas?.notifyConseeOutForDelivery,
        notifyConseeDelivered: notificationdData.notifyConseeDelivered
          ? notificationdData.notifyConseeDelivered
          : defaultDatas?.notifyConseeDelivered,
        TextPhoneNumber: notificationdData.phoneNumber || "",
        TextNotifyPickup: notificationdData.textNotificationOnPickup,
        TextNotifyDelivery: notificationdData.textNotificationOnDelivery,
        sizeUOM: defaultDatas?.sizeUOM,
        distanceUOM: defaultDatas?.distanceUOM,
        referenceTypeID: defaultDatas?.referenceTypeID,
        referenceTypeID2: defaultDatas?.referenceTypeID2 || 0,
        referenceTypeID3: defaultDatas?.referenceTypeID3 || 0,
        referenceTypeID4: defaultDatas?.referenceTypeID4 || 0,
        referenceTypeID5: defaultDatas?.referenceTypeID5 || 0,
        referenceTypeID6: defaultDatas?.referenceTypeID6 || 0,
        referenceTypeID7: defaultDatas?.referenceTypeID7 || 0,
        referenceTypeID8: defaultDatas?.referenceTypeID8 || 0,
        referenceTypeID9: defaultDatas?.referenceTypeID9 || 0,
        serviceGUID: selectedServiceID,
        insertUserGUID: token?.UserGUID,
        tmsType: "PLTMS",
        dgHandlingInfo: dangerousGoodData?.dgHandlingInfo || "",
        trackingNumber: "",
        piecesList: piecesData,
        quantity: packageData.pieces ?? 1,
        puStandardAbbr: pickupData?.standardAbbr,
        delStandardAbbr: deliveryData?.standardAbbr,
      }));
    }
  }, [
    deliveryData,
    pickupData,
    defaultDatas,
    packageData,
    selectedContent,
    dangerousGoodData,
    notificationdData,
    piecesData,
    shipperData,
  ]);

  useEffect(() => {
    const areAllServicesDepFinished = Object.values(
      servicesDependancyFinished
    ).every((value) => value === true);
    if (areAllServicesDepFinished) {
      getDimWt();
    }
  }, [
    servicesDependancyFinished,
    longFormData.puReadyTime,
    longFormData.puReadyNow,
    longFormData.puCountryID,
    longFormData.delCountryID,
    longFormData.serviceID,
    longFormData.accountNumber,
    packageTypeDependancyCustID,
  ]);

  const getDimWt = async () => {
    const updatedItems = [...piecesData];
    for (let i = 0; i < updatedItems.length; i++) {
      const item = updatedItems[i];
      const puReadtDate = longFormData.puReadyNow
        ? moment().format("YYYY-MM-DDTHH:mm:ss.SSS")
        : longFormData.puReadyTime
        ? moment(longFormData.puReadyTime).format("YYYY-MM-DDTHH:mm:ss.SSS")
        : moment().format("YYYY-MM-DDTHH:mm:ss.SSS");
      const params = {
        puReadyDateTime: puReadtDate,
        serviceID: longFormData?.serviceID || 0,
        origCountryID: longFormData?.puCountryID || "",
        destCountryID: longFormData?.delCountryID || "",
        length: item?.length || 0,
        width: item?.width || 0,
        height: item?.height || 0,
        sizeUOM: item?.sizeUOM || "IN",
        weightUOM: item?.weightUOM || "",
        qunatity: item?.quantity || 1,
        customerID: packageTypeDependancyCustID || 0,
      };

      try {
        // Call the API
        const response = await calculateDimWtAPI(params);

        if (response.data && response.data.data) {
          updatedItems[i].dimWeight = response.data.data;
        }
      } catch (error) {}

      setPiecesData(updatedItems);
      setLongFormData((prevLongFormData) => ({
        ...prevLongFormData,
        dimWeight: updatedItems[0].dimWeight,
      }));
    }
  };

  useEffect(() => {
    if (LongFormOrderError) {
      scrollToTop();
      setLongFormSubmitError(LongFormOrderError);
    }
  }, [LongFormOrderError]);

  const handlePickUpDataChange = (updatedData: any) => {
    const newData = {
      ...updatedData,
    };
    setPickupData((prevData) => ({
      ...prevData,
      ...newData,
    }));
  };

  const handleDeliveryDataChange1 = (updatedData: any) => {
    const newData = {
      ...updatedData,
    };
    setDeliveryData((prevData) => ({
      ...prevData,
      ...newData,
    }));
  };

  const handleAttChange = (attList: any) => {
    const attributeList = attList?.attributeList;
    const attributeTypeID = attList?.attributeTypeID;
    const attributeCode = attList?.attributeCode;
    setLongFormData((prevData) => ({
      ...prevData,
      attributeTypeID:
        attributeTypeID && attributeTypeID.length > 0
          ? attributeTypeID.join(",")
          : "",
      attributeList:
        attributeList && attributeList.length > 0 ? attributeList : [],
      attributeCode:
        attributeCode && attributeCode.length > 0 ? attributeCode : [],
    }));
  };

  const handleDeliveryDataChange = (updatedData: any) => {
    const newData = { ...updatedData };
    if (newData.hasOwnProperty("deliveryRequestTime")) {
      setDeliveryTime(newData.deliveryRequestTime);
    }
    setDeliveryData((prevData) => ({
      ...prevData,
      ...newData,
    }));
  };

  const handleReadyDataChange = (updatedData: any) => {
    const newData = { ...updatedData };

    if (newData.hasOwnProperty("pickupReadyTime")) {
      setPickupTime(newData.pickupReadyTime);
    }
    setDeliveryData((prevData) => ({
      ...prevData,
      ...newData,
    }));
  };

  const handleDocumentDataChange = (updatedData: any) => {
    const newData = {
      ...updatedData,
    };
    setFileUploadData({
      ...newData,
    });
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handlePakageInfoDataChange = (updatedData: any) => {
    const newData = {
      ...updatedData,
    };
    setPackageData((prevData: any) => ({
      ...prevData,
      ...newData,
    }));
  };

  const handlePiecesDataChange = (updatedData: any) => {
    let piecesListArray: any = Object.values(updatedData);
    setPackageData((prevData: any) => ({
      ...prevData,
    }));
    setPiecesData(piecesListArray);
  };

  const handleDangerousGoodDataChange = (updatedData: any) => {
    const newData = {
      ...updatedData,
    };
    setDangerousGoodData((prevData: any) => ({
      ...prevData,
      ...newData,
    }));
  };

  const handleShipperDataChange = (updatedData: any) => {
    const newData = {
      ...updatedData,
    };

    setShipperData((prevData: any) => ({
      ...prevData,
      ...newData,
    }));
  };

  const handleNotificatonDataChange = (updatedData: any) => {
    const newData = {
      ...updatedData,
    };
    setNotificationData((prevData: any) => ({
      ...prevData,
      ...newData,
    }));
  };

  const handleServiceChange = (e: any) => {
    const serviceID = e.target.value;
    setSelectedServiceID(serviceID);
    const findServiceCode = serviceData.find(
      (x: any) => x.serviceGUID === serviceID
    );
    setFormErrors((prevErrors: any) => {
      const updatedErrors = { ...prevErrors };
      delete updatedErrors["serviceGUID"];
      return updatedErrors;
    });
    setLongFormData((prevLongFormData) => ({
      ...prevLongFormData,
      serviceGUID: serviceID,
      serviceName: findServiceCode.name,
      serviceID: findServiceCode.serviceID,
    }));
  };

  useEffect(() => {
    if (serviceData && serviceData.length > 0 && defaultDatas?.serviceGUID) {
      const findServiceCode = serviceData?.find(
        (x: any) => x.serviceGUID === defaultDatas?.serviceGUID
      );
      setLongFormData((prevLongFormData) => ({
        ...prevLongFormData,
        serviceID: findServiceCode?.serviceID,
      }));
    }
  }, [defaultDatas, serviceData]);

  const getService = async () => {
    try {
      const param: any = {
        companyID: MAX_COMPANY_ID,
        userGUID: token.UserGUID,
        serviceAvailType: serviceAvailType,
        baseDateTime: new Date(),
        origCountryID: pickupData.pickupCountryID,
        origCityName: pickupData.pickupCityName,
        origStateProvID: pickupData.pickupStateProvID,
        origPostalCode: pickupData.pickupPostalCode,
        origAirportID: pickupData.pickupAirportID,
        destCountryID: deliveryData.deliveryCountryID,
        destCityName: deliveryData.deliveryCityName,
        destStateProvID: deliveryData.deliveryStateProvID,
        destPostalCode: deliveryData.deliveryPostalCode,
        destAirportID: deliveryData.deliveryAirportID,
      };
      const response = await service(param);
      if (response.data && response.data.data) {
        const res = response.data.data;
        setServiceData(res);
      } else {
        setServiceData([]);
      }
    } catch (err) {
      setServiceData([]);
    }
  };

  //detect the manually page refresh and remove the rate estimate data from state.
  useEffect(() => {
    const onBeforeUnload = () => {
      navigate(location.pathname, {
        state: {
          ...location.state,
          isManualReload: true,
          rateEstimateData: null,
        },
        replace: true,
      });
    };

    window.addEventListener("beforeunload", onBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", onBeforeUnload);
    };
  }, []);

  const getDefaultData = async () => {
    try {
      const rateEstimateData = location?.state?.rateEstimateData;
      if (rateEstimateData) {
        setisRateEstimateOrder(rateEstimateData.isRateEstimate);
      } else {
        setisRateEstimateOrder(false);
      }
      const param = {
        companyID: MAX_COMPANY_ID,
      };
      const response = await defaultData(param);
      if (response.data && response.data.data) {
        const apiResponse = response.data.data;
        let res = apiResponse;
        if (rateEstimateData) {
          res = updateDefaultDataByRateEstimate(apiResponse, rateEstimateData);
        }

        const currentTime = new Date();
        let pickupReadyTime: any = res.pickupReadyTime
          ? new Date(res.pickupReadyTime)
          : null;
        let deliveryTime: any = res.deliveryRequestTime
          ? new Date(res.deliveryRequestTime)
          : null;
        if (pickupReadyTime) {
          const pickupHours = pickupReadyTime.getHours();
          const pickupMinutes = pickupReadyTime.getMinutes();
          const currentHours = currentTime.getHours();
          const currentMinutes = currentTime.getMinutes();
          if (
            pickupHours < currentHours ||
            (pickupHours === currentHours && pickupMinutes < currentMinutes)
          ) {
            pickupReadyTime = null;
          } else {
            const updatedPickupReadyTime = new Date();
            updatedPickupReadyTime.setFullYear(currentTime.getFullYear());
            updatedPickupReadyTime.setMonth(currentTime.getMonth());
            updatedPickupReadyTime.setDate(currentTime.getDate());
            updatedPickupReadyTime.setHours(pickupHours, pickupMinutes, 0, 0);
            pickupReadyTime = updatedPickupReadyTime;
          }
        }
        if (deliveryTime) {
          const deliveryHours = deliveryTime.getHours();
          const deliveryMinutes = deliveryTime.getMinutes();
          const currentHours = currentTime.getHours();
          const currentMinutes = currentTime.getMinutes();
          if (
            deliveryHours < currentHours ||
            (deliveryHours === currentHours && deliveryMinutes < currentMinutes)
          ) {
            deliveryTime = null;
          } else {
            const updatedDeliveryTime = new Date();
            updatedDeliveryTime.setFullYear(currentTime.getFullYear());
            updatedDeliveryTime.setMonth(currentTime.getMonth());
            updatedDeliveryTime.setDate(currentTime.getDate());
            updatedDeliveryTime.setHours(deliveryHours, deliveryMinutes, 0, 0);
            deliveryTime = updatedDeliveryTime;
          }
        }
        res.pickupReadyTime = pickupReadyTime;
        res.deliveryRequestTime = deliveryTime;

        setDefaultDatas(res);
        setSelectedServiceID(res.serviceGUID);
        setDeliveryData((prevLongFormData: any) => ({
          ...prevLongFormData,
          pickupReadyTime: res.pickupReadyTime,
          puReadyNow: res.pickupReadyNow,
          deliveryRequestAsap: res.deliveryRequestAsap,
          holdForPickup: res.holdForPickup,
          readyNow: res.pickupReadyNow,
        }));
        if (res.contentsReferenceGroupGUID) {
          localStorage.setItem(
            "contentsReferenceGroupGUID",
            res.contentsReferenceGroupGUID
          );
        }
        setServicesDependancyFinished((_prev) => ({
          ..._prev,
          settingsAPI: true,
        }));
      }
    } catch (err) {
      setDefaultDatas(undefined);
    }
  };

  const handleUNNumberDataChange = (data: any) => {
    if (data.length > 0) {
      setUNError(false);
    } else {
      setUNError(true);
    }
  };

  const handlePickupZipCodeDataChange = (data: any, countryID: string) => {
    if (data.length == 0 && countryID === "US") {
      SetPickUpZipCodeError(true);
    } else if (data.length == 0 && countryID !== "US" && countryID !== "AE") {
      setPickupZipCodeWarn(true);
    } else {
      SetPickUpZipCodeError(false);
      setPickupZipCodeWarn(false);
    }
  };

  const handleDeliveryZipCodeDataChange = (data: any, countryID: string) => {
    if (data.length == 0 && countryID === "US") {
      SetDeliveryZipCodeError(true);
    } else if (data.length == 0 && countryID !== "US" && countryID !== "AE") {
      setDeliveryZipCodeWarn(true);
    } else {
      SetDeliveryZipCodeError(false);
      setDeliveryZipCodeWarn(false);
    }
  };

  const handleFileUpload = async (data: any, type: string) => {
    try {
      let response: any;
      if (type == "route") {
        response = await multipleFileUploadWithoutLoader(data);
      } else {
        response = await multipleFileUpload(data);
      }
      if (response) {
        const res = response.data.data;
        return { success: true };
      }
    } catch (err) {
      return { success: false };
    }
  };

  const handleUploadAllFiles = async (
    filesToSend: any,
    type: any,
    shipmentGUID: any
  ) => {
    const url = `/track-order?ShipmentGUID=${shipmentGUID}`;
    const results = await Promise.all(
      filesToSend.map(async (file: any) => {
        if (file) {
          const fileParam = {
            shipmentGUID: shipmentGUID,
            insertUserID: token.UserId,
            files: [file], // Send the current file
          };
          return handleFileUpload(fileParam, type); // Call upload for each file
        } else {
          return { success: false };
        }
      })
    );

    // Handle the results of all file uploads
    const successFiles = results.filter((result) => result.success);
    const failedFiles = results.filter((result) => !result.success);

    if (successFiles.length > 0 || failedFiles.length > 0) {
      //const successMessage = `${successFiles.length} Documents uploaded successfully!`;
      if (type === "newOrder") {
        window.location.reload();
      } else if (type === "route") {
      } else {
        navigate(url);
      }
    }

    // if (failedFiles.length > 0) {
    //   const errorMessage = `${failedFiles.length} Documents failed to upload.`;
    // }
  };

  const normalizeDate = (date: Date | null) => {
    if (!date) return null;
    const normalized = new Date(date);
    normalized.setMilliseconds(0); // Remove fractional milliseconds
    return normalized;
  };

  useEffect(() => {
    // const puReadyTime = longFormData.puReadyTime
    //   ? new Date(longFormData.puReadyTime)
    //   : null;
    // const deliveryRequestTime = longFormData?.delRequestDate
    //   ? new Date(longFormData?.delRequestDate)
    //   : null;
    const puReadyTime = longFormData.puReadyTime
      ? normalizeDate(new Date(longFormData.puReadyTime))
      : null;
    const deliveryRequestTime = longFormData?.delRequestDate
      ? normalizeDate(new Date(longFormData?.delRequestDate))
      : null;

    if (
      puReadyTime &&
      deliveryRequestTime &&
      longFormData.delRequestAsap === false &&
      longFormData.puReadyNow === false
    ) {
      if (puReadyTime >= deliveryRequestTime) {
        setFormErrors((_prv: any) => ({
          ..._prv,
          puReadyTime:
            "Shipment Ready Time cannot be set after the Requested Delivery Time.",
        }));
      } else {
        const { puReadyTime } = formErrors;
        if (
          puReadyTime &&
          puReadyTime ==
            "Shipment Ready Time cannot be set after the Requested Delivery Time."
        ) {
          setFormErrors((_prv: any) => ({
            ..._prv,
            puReadyTime: "",
          }));
        }
      }
    } else {
      setFormErrors((_prv: any) => ({
        ..._prv,
        puReadyTime: "",
      }));
    }
  }, [
    longFormData.puReadyTime,
    longFormData?.delRequestDate,
    longFormData.delRequestAsap,
    longFormData.puReadyNow,
  ]);
  const validateForm = (data: any) => {
    let errors: any = {};
    const puReadyTime = new Date(data.puReadyTime);
    const deliveryRequestTime = data?.delRequestDate
      ? new Date(data?.delRequestDate)
      : null;

    if (data.puReadyNow === false && data.isWillCall === false) {
      if (!data.puReadyTime || data.puReadyTime == "Invalid date") {
        errors.puReadyTime = "Please specify a Shipment Ready Time.";
      } else {
        const selectedDateTime = moment(data.puReadyTime).format(
          "YYYY-MM-DD HH:mm:ss"
        );
        const currentDateTime = getCurrentDateTimeByAbbr(data?.puStandardAbbr);

        if (selectedDateTime < currentDateTime) {
          errors.puReadyTime = "The ready time cannot be in the past.";
        }
      }
    }
    if (
      !errors.puReadyTime &&
      puReadyTime &&
      deliveryRequestTime &&
      data.delRequestAsap === false &&
      data.puReadyNow === false
    ) {
      if (puReadyTime > deliveryRequestTime) {
        errors.puReadyTime =
          "Shipment Ready Time cannot be set after the Requested Delivery Time.";
      }
    }
    if (data.delRequestAsap === false && data.isWillCall === false) {
      if (!data.delRequestDate) {
        errors.delRequestDate = "Please specify a Requested Delivery Time.";
      }
    }

    if (!data.delCompanyName) {
      errors.delCompanyName = "Please enter a Delivery Company Name.";
    }
    if (!data.puCompanyName) {
      errors.puCompanyName = "Please enter a Pickup Company Name.";
    }
    if (!data.puAirportID) {
      errors.puAirportID = "Please enter a Pickup Airport.";
    }
    if (!data.delAirportID) {
      errors.delAirportID = "Please enter a Delivery Airport.";
    }
    if (!data.puCityName) {
      errors.puCityName = "Please select a Pickup City.";
    }
    if (!data.delCityName) {
      errors.delCityName = "Please select a Delivery City.";
    }
    if (!data.puPostalCode && data.puCountryID !== "AE") {
      errors.puPostalCode = "Please enter a Pickup ZIP Code.";
      SetPickUpZipCodeError(false);
    }
    if (!data.delPostalCode && data.delCountryID !== "AE") {
      errors.delPostalCode = "Please enter a Delivery ZIP Code.";
      SetDeliveryZipCodeError(false);
    }
    if (!data.serviceGUID) {
      errors.serviceGUID = "Please specify a Shipment Service Level.";
    }
    if (!data.commodity) {
      errors.commodity = "Please select a Commodity.";
    }
    if (!data.shipperName) {
      errors.shipperName = "Please enter a Shipper Name.";
    }
    if (!data.shipperEmail) {
      errors.shipperEmail = "Please enter a Shipper Email Address.";
    }
    if (!data.accountNumber) {
      errors.accountNumber = "Please select a Account.";
    }
    if (data.dgIsRadioactive === true) {
      if (!data.dgUNNumber) {
        errors.dgUNNumber = "Please enter a UN# for your Dangerous Goods item.";
      }
      if (!data.dgProperName) {
        errors.dgProperName =
          "Please enter a Name for your Dangerous Goods item.";
      }
      if (!data.dgCommodityClass) {
        errors.dgCommodityClass =
          "Please enter a Class for your Dangerous Goods item.";
      }
      if (!data.dgTransportationLimit) {
        errors.dgTransportationLimit =
          "Please select a Limit for your Dangerous Goods item.";
      }
      if (!data.dgEmergencyPhone) {
        errors.dgEmergencyPhone =
          "Please enter an Emergency Phone Number for your Dangerous Goods item.";
      }
      if (!data.dgSignatory) {
        errors.dgSignatory =
          "Please enter a Title Signatory for your Dangerous Goods item.";
      }
      if (!data.dgDate) {
        errors.dgDate = "Please enter a Date for your Dangerous Goods item.";
      }
    }
    // Validate piecesList
    if (data.piecesList && Array.isArray(data.piecesList)) {
      data.piecesList.forEach((piece: any, index: number) => {
        if (!piece.weight) {
          errors[
            `piecesList[${index}].weight`
          ] = `Please enter Weight for Piece ${index + 1}.`;
        }
      });
    }
    if (childRef.current) {
      const isValid = childRef.current.validate();
      setIsValid(isValid);
    }
    if (
      (data.TextNotifyDelivery || data.TextNotifyPickup) &&
      !data.TextPhoneNumber
    ) {
      errors.TextPhoneNumber =
        "Please enter a Phone Number for text notifications.";
    }

    return errors;
  };

  const handleSubmit = async (type: string) => {
    sessionStorage.removeItem("longFormData");
    sessionStorage.removeItem("longFormFiles");
    if (errorCode) {
      // Optional: Scroll to errorRef if necessary
      errorRef?.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
        top: 50,
      });
      return; // Exit early if there is an errorCode
    }
    const isInvalidPickupZip =
      pickupZipCodeWarn &&
      pickupSubmitCount === 0 &&
      longFormData.puCountryID != "AE";
    const isInvalidDeliveryZip =
      deliveryZipCodeWarn &&
      deliverySubmitCount === 0 &&
      longFormData.delCountryID != "AE";
    if (isInvalidPickupZip || isInvalidDeliveryZip) {
      errorRef?.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
        top: 50,
      });
      if (pickupZipCodeWarn) {
        setPickupSubmitCount(1);
      }
      if (deliveryZipCodeWarn) {
        setDeliverySubmitCount(1);
      }
      return;
    } else {
      setPickupZipCodeWarn(false);
      setDeliveryZipCodeWarn(false);
      setPickupSubmitCount(0);
      setDeliverySubmitCount(0);
    }

    try {
      longFormData.weight = piecesData[0].weight;
      longFormData.puReadyTime = pickupTime
        ? moment(pickupTime).format("YYYY-MM-DDTHH:mm:ss")
        : null;

      longFormData.puReadyDate = pickupTime
        ? moment(pickupTime).format("YYYY-MM-DDTHH:mm:ss")
        : null;

      if (errorCodeReadyTime || errorCodeDeliveryTime) {
        return;
      }
      longFormData.weight = piecesData[0].weight;
      const isPastDate = deliveryTime
        ? new Date(deliveryTime) < new Date()
        : true;

      longFormData.delRequestTime = isPastDate
        ? null
        : moment(deliveryTime).format("YYYY-MM-DDTHH:mm:ss");

      longFormData.delRequestDate = isPastDate
        ? null
        : moment(deliveryTime).format("YYYY-MM-DDTHH:mm:ss");

      longFormData.dgDate = dangerousGoodData.dgDate
        ? moment(dangerousGoodData.dgDate).format("YYYY-MM-DDTHH:mm:ss")
        : null;
      const findServiceCode = serviceData.find(
        (x: any) => x.serviceGUID === longFormData?.serviceGUID
      );
      longFormData.serviceGUID = findServiceCode?.serviceGUID || "";
      longFormData.serviceName = findServiceCode?.name || "";
      const validationErrors = validateForm(longFormData);

      if (Object.keys(validationErrors).length > 0) {
        setFormErrors(validationErrors);
        errorRef?.current?.scrollIntoView({
          behavior: "smooth",
          block: "start",
          top: 50,
        });
      } else {
        if (errorCodeReadyTime || errorCodeDeliveryTime) {
          return;
        }

        SetPickUpZipCodeError(false);
        SetDeliveryZipCodeError(false);

        const totalQuantity = piecesData.reduce((sum: number, piece: any) => {
          const quantity = parseFloat(piece.quantity as unknown as string) || 0;
          return sum + quantity;
        }, 0);
        const totalWeight = piecesData.reduce((sum: number, piece: any) => {
          const quantity = parseFloat(piece.weight as unknown as string) || 0;
          return sum + quantity;
        }, 0);
        const updatedPiecesList = piecesData.map((piece: any) => {
          // Ensure contents are trimmed to 50 characters
          // Convert length, width, and height to numbers if they're strings
          if (typeof piece.length === "string") {
            piece.length = Number(piece.length);
          }
          if (typeof piece.width === "string") {
            piece.width = Number(piece.width);
          }
          if (typeof piece.height === "string") {
            piece.height = Number(piece.height);
          }
          if (typeof piece.quantity === "string") {
            piece.quantity = Number(piece.quantity);
          }
          return piece;
        });
        longFormData.quantity = totalQuantity;
        longFormData.weight = totalWeight;
        longFormData.length = updatedPiecesList[0].length;
        longFormData.height = updatedPiecesList[0].height;
        longFormData.width = updatedPiecesList[0].width;
        longFormData.weightUOM = updatedPiecesList[0].weightUOM;
        longFormData.dimWeight = updatedPiecesList[0].dimWeight;
        longFormData.contents = updatedPiecesList[0].contents;
        longFormData.mxwebEstCharges = accountData?.mxwebEstCharges;
        longFormData.useOrderNumAsTrackNum =
          shpUseOrderNumAsTrackNum == "true" ? true : false;
        if (
          longFormData.pieces === null ||
          longFormData.pieces === undefined ||
          !longFormData.pieces
        ) {
          longFormData.pieces = 1;
        }
        longFormData.piecesList = updatedPiecesList;
        if (longFormData.puReadyNow || longFormData.isWillCall) {
          longFormData.puReadyTime = null;
          longFormData.puReadyDate = null;
        }

        longFormData.qdtSweep = accountData.qdtSweep;
        longFormData.timeOutWindow = accountData.timeOutWindow;
        longFormData.orderTimeOut = accountData.orderTimeOut;
        longFormData.dgDryIceWeight =
          longFormData.dgDryIceWeight > 0 ? longFormData.dgDryIceWeight : null;
        for (let i = 0; i < piecesData.length; i++) {
          piecesData[i].dgDryIceWeight =
            piecesData[i].dgDryIceWeight > 0
              ? piecesData[i].dgDryIceWeight
              : null;
        }

        if (!isDisplayAttributes) {
          longFormData.attributeCode = [];
          longFormData.attributeList = [];
          longFormData.attributeTypeID = "";
        }
        longFormData.reference = longFormData?.reference
          ? longFormData?.reference?.trim()
          : "";
        longFormData.reference2 = longFormData?.reference2
          ? longFormData?.reference2?.trim()
          : "";
        longFormData.reference3 = longFormData?.reference3
          ? longFormData?.reference3?.trim()
          : "";
        longFormData.reference4 = longFormData?.reference4
          ? longFormData?.reference4?.trim()
          : "";
        longFormData.reference5 = longFormData?.reference5
          ? longFormData?.reference5?.trim()
          : "";
        longFormData.reference6 = longFormData?.reference6
          ? longFormData?.reference6?.trim()
          : "";
        longFormData.reference7 = longFormData?.reference7
          ? longFormData?.reference7?.trim()
          : "";
        longFormData.reference8 = longFormData?.reference8
          ? longFormData?.reference8?.trim()
          : "";
        longFormData.reference9 = longFormData?.reference9
          ? longFormData?.reference9?.trim()
          : "";

        if (
          type != "newOrder" &&
          !longFormData.isWillCall &&
          accountData.mxwebRouteRecommendations &&
          (findServiceCode?.serviceCode === "FF" ||
            findServiceCode?.serviceCode === "CTX")
        ) {
          const findServiceCodeEGD = serviceData.find(
            (x: any) => x.serviceCode === "EGD"
          );
          longFormData.serviceEGDGUID = findServiceCodeEGD?.serviceGUID || "";
          longFormData.israteEstimate = isRateEstimateOrder;
          sessionStorage.setItem("longFormData", JSON.stringify(longFormData));
          setIsRouteRecommendationPage(true);
        } else {
          setPickupSubmitCount(0);
          setDeliverySubmitCount(0);
          const response = !isRateEstimateOrder
            ? await longFormOrder(longFormData)
            : await longFormReOrder(longFormData);

          if (response.data) {
            const res = response.data;
            const filesToSend = Object.values(fileUploadData).map(
              (item) => item.file
            );
            const url = `/track-order?ShipmentGUID=${res.data.shipmentGUID}`;
            if (filesToSend.length > 0) {
              handleUploadAllFiles(filesToSend, type, res.data.shipmentGUID);
            } else {
              if (type === "newOrder") {
                window.location.reload();
              } else if (type === "route") {
              } else {
                navigate(url);
              }
            }
            errorRef?.current?.scrollIntoView({
              behavior: "smooth",
              block: "start",
              top: 50,
            });
          }
          if (!response.success) {
            setLongFormSubmitError(response.message);
          }
        }
      }
    } catch (err) {
    } finally {
    }
  };

  interface MenuStyles {
    maxHeight: string;
  }

  const [menuStyles, setMenuStyles] = useState<MenuStyles>({
    maxHeight: "200px", // Default max height for desktop
  });

  const getCountry = async () => {
    try {
      const param = {};
      const response = await country(param);
      if (response.data && response.data.data) {
        setCountryData(response.data.data);
      }
    } catch (err) {
      setCountryData([]);
    }
  };

  const handleRequestDeliveryTimeChange = (data: any) => {
    setReadyTimeData(data);
  };
  const handlePickupDeliveryTimeChange = (data: any) => {
    setPickupReadyTimeData(data);
  };

  return (
    <>
      {/* main content section */}

      <div className={!isRouteRecommendationPage ? "d-block" : "d-none"}>
        <div className="order-form">
          <Box className="d-flex flex-column w-100 create-Order-form">
            <Typography
              variant="h4"
              color="primary"
              className="d-flex font48 main-header"
              ref={errorRef}
            >
              New Order
            </Typography>
            {(longFormSubmitError ||
              pickupZipCodeError ||
              deliveryZipCodeError ||
              unError ||
              errorCode ||
              errorCodePickup ||
              (pickupZipCodeWarn && pickupSubmitCount === 1) ||
              (deliveryZipCodeWarn && deliverySubmitCount === 1) ||
              Object.keys(formErrors).filter((key) => formErrors[key]).length >
                0) && (
              <Box className="w-100">
                <Alert variant="filled" severity="error" id="txtLfValidation">
                  {errorCode && <div>{errorCode}</div>}
                  {errorCodePickup && <div>{errorCodePickup}</div>}
                  {longFormSubmitError && <div>{longFormSubmitError}</div>}
                  {pickupZipCodeError && (
                    <div>
                      You have entered an invalid Pickup Zip Code. Please ensure
                      you have entered the proper Zip Code. If you continue to
                      experience issues, please contact MNX.
                    </div>
                  )}
                  {deliveryZipCodeError && (
                    <div>
                      You have entered an invalid Delivery Zip Code. Please
                      ensure you have entered the proper Zip Code. If you
                      continue to experience issues, please contact MNX.
                    </div>
                  )}
                  {pickupZipCodeWarn && pickupSubmitCount === 1 && (
                    <div>
                      Please ensure you have entered the proper Pickup Zip Code.
                      After you have confirmed the Pickup Zip Code's accuracy,
                      please try again.
                    </div>
                  )}
                  {deliveryZipCodeWarn && deliverySubmitCount === 1 && (
                    <div>
                      Please ensure you have entered the proper Delivery Zip
                      Code. After you have confirmed the Delivery Zip Code's
                      accuracy, please try again.
                    </div>
                  )}
                  {unError && <div>Invalid UN# specified.</div>}
                  {Object.keys(formErrors).map((key) => (
                    <div key={key}>{formErrors[key]}</div>
                  ))}
                </Alert>
              </Box>
            )}

            {/* pickup delivery section */}
            {defaultDatas ? (
              <Box className="d-flex flex-column align-items-start">
                <Typography
                  variant="h5"
                  color="primary"
                  className="pickup_section pb-16"
                >
                  1. Pickup & Delivery
                </Typography>

                <Grid container spacing={2}>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <ReadyTime
                      deliveryData={deliveryData}
                      onDeliveryDataChange={handleReadyDataChange}
                      pickupData={pickupData}
                      formErrors={formErrors}
                      setErrorCodeReadyTime={setErrorCodeReadyTime}
                      setFormErrors={setFormErrors} // Pass setFormErrors as a prop
                      readyTimeData={readyTimeData}
                      onRequestTimeChange={handleRequestDeliveryTimeChange}
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <DeliveryTime
                      deliveryData={deliveryData}
                      onDeliveryDataChange={handleDeliveryDataChange}
                      formErrors={formErrors}
                      setFormErrors={setFormErrors} // Pass setFormErrors as a prop
                      DefaultData={defaultDatas}
                      setErrorCodeDeliveryTime={setErrorCodeDeliveryTime}
                      readyTimeData={readyTimeData}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item lg={6} md={6} sm={12} xs={12} className="mt-16">
                    <LongFormPickupAddress
                      onPickUpDataChange={handlePickUpDataChange}
                      DefaultData={defaultDatas}
                      formErrors={formErrors}
                      onZipCodeDataChange={handlePickupZipCodeDataChange}
                      setFormErrors={setFormErrors}
                      pickupZipCodeError={pickupZipCodeError}
                      SetPickupZipCodeError={SetPickUpZipCodeError}
                      countryData={countryData}
                      errorCodePickup={errorCodePickup}
                      setErrorCodePickup={setErrorCodePickup}
                      onRequestTimeChange={
                        handlePickupDeliveryTimeChange ||
                        handleRequestDeliveryTimeChange
                      }
                      addressDataList={addressData}
                      isLimitedRole={isLimitedRole}
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} xs={12} className="mt-16">
                    <LongFormDeliveryAddress
                      onDeliveryDataChange={handleDeliveryDataChange1}
                      DefaultData={defaultDatas}
                      DeliveryData={deliveryData}
                      formErrors={formErrors}
                      componentCalledFrom="LongForm"
                      deliveryZipCodeError={deliveryZipCodeError}
                      SetDeliveryZipeCodeError={SetDeliveryZipCodeError}
                      onZipCodeDataChange={handleDeliveryZipCodeDataChange}
                      setFormErrors={setFormErrors}
                      countryData={countryData}
                      errorCode={errorCode}
                      setErrorCode={setErrorCode}
                      onRequestTimeChange={handleRequestDeliveryTimeChange}
                      addressDataList={addressData}
                      isLimitedRole={isLimitedRole}
                    />
                  </Grid>
                </Grid>

                {/* shipment timing section */}
                <Box className="w-100 Divider mt-40 mb-40"></Box>

                <Typography
                  variant="h5"
                  color="primary"
                  className="ml-8 mt-40 mb-24"
                >
                  2. Billing & Service
                </Typography>

                <Grid container lg={12} spacing={2}>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <BillingAndRefer
                      DefaultData={defaultDatas}
                      onDeliveryDataChange={handleDeliveryDataChange}
                      setFormErrors={setFormErrors} // Pass setFormErrors as a prop
                      formErrors={formErrors}
                      setAccountData={setAccountData}
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Box className="d-flex flex-column border p16 rounded">
                      <Typography variant="h6" className="Sub-header">
                        Select a Service
                      </Typography>

                      <Select
                        variant="outlined"
                        id="ddService"
                        name="serviceGUID"
                        error={!!formErrors.serviceGUID}
                        defaultValue={selectedServiceID}
                        onChange={handleServiceChange}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          PaperProps: {
                            style: menuStyles,
                          },
                        }}
                      >
                        {serviceData.length > 0 &&
                          serviceData.map((service: any) => (
                            <MenuItem
                              key={service.serviceGUID}
                              value={service.serviceGUID}
                            >
                              {service.name}
                            </MenuItem>
                          ))}
                      </Select>
                    </Box>
                  </Grid>
                </Grid>
                <Box className="w-100 Divider mt-40 mb-40"></Box>

                <Typography variant="h5" color="primary" className="ml-8 mt-40">
                  3. Package Information
                </Typography>

                {/* package information section */}
                <PackageInfo
                  DefaultData={defaultDatas}
                  onContentChange={onContentChange}
                  onPakageInfoDataChange={handlePakageInfoDataChange}
                  onPiecesDataChange={handlePiecesDataChange}
                  formErrors={formErrors}
                  setFormErrors={setFormErrors} // Pass setFormErrors as a prop
                  setPiecesData={setPiecesData}
                  piecesData={piecesData}
                  selectedContent={selectedContent ? selectedContent : null}
                  longFormData={longFormData}
                  setPackageTypeDependancyLongFornCustID={
                    setPackageTypeDependancyCustID
                  }
                />

                {/* dangerous goods section */}
                <DangerousGoods
                  DefaultData={defaultDatas}
                  SelectedContent={selectedContent ? selectedContent : null}
                  onDangerousGoodDataChang={handleDangerousGoodDataChange}
                  formErrors={formErrors}
                  setFormErrors={setFormErrors} // Pass setFormErrors as a prop
                  onUnNumberChange={handleUNNumberDataChange}
                  piecesData={piecesData}
                  setPiecesData={setPiecesData}
                  dangerousGoodDataProps={dangerousGoodData}
                  setUNError={setUNError}
                />

                {/* <Attributes
                  handleAttChange={handleAttChange}
                  serviceID={longFormData.serviceID}
                  accountNumber={longFormData.accountNumber}
                  setIsDisplayAttributes={setIsDisplayAttributes}
                /> */}

                {/* <Divider className="mt-5 w-100" /> */}
                <Typography variant="h5" color="primary" className="ml-8 mt-40">
                  {`${isDisplayAttributes ? "5." : "4."}`} Shipper Information
                </Typography>
                {/* {defaultDatas && ( */}
                <Shipper
                  deliveryData={deliveryData}
                  onShipperDataChange={handleShipperDataChange}
                  DefaultData={defaultDatas}
                  formErrors={formErrors}
                  setFormErrors={setFormErrors} // Pass setFormErrors as a prop
                  isLimitedRole={isLimitedRole}
                />

                <Typography
                  variant="h5"
                  color="primary"
                  className=" ml-8 mt-40"
                >
                  {`${isDisplayAttributes ? "6." : "5."}`} Documents
                </Typography>
                <Documents onDocumentDataChange={handleDocumentDataChange} />

                <Typography
                  variant="h5"
                  color="primary"
                  className=" ml-8 mt-40"
                >
                  {`${isDisplayAttributes ? "7." : "6."}`} Notifications
                </Typography>

                <Notifications
                  DefaultData={defaultDatas}
                  onNotificationDataChange={handleNotificatonDataChange}
                  formErrors={formErrors}
                  setFormErrors={setFormErrors}
                  isLimitedRole={isLimitedRole}
                />

                {/* order actions section */}
              </Box>
            ) : (
              <Box>{/* <Loader /> */}</Box>
            )}
          </Box>
        </div>
        <div className="container-fluid">
          <Box className="d-flex justify-content-end gap-2 w-100 Divider-top pt-16 pb-16">
            <Button
              variant="outlined"
              className="d-flex rounded me-2"
              onClick={() => handleSubmit("newOrder")}
              id="btnBookOrderNew"
            >
              BOOK ORDER & NEW
            </Button>
            <LoadingButton
              variant="contained"
              color="primary"
              id="btnBookOrder"
              loadingPosition="end"
              className="d-flex rounded me-2"
              endIcon={<img src={arrowicon} alt="icon right" />}
              onClick={() => handleSubmit("submit")}
            >
              BOOK ORDER
            </LoadingButton>
          </Box>
        </div>
      </div>
      <div className={isRouteRecommendationPage ? "d-block" : "d-none"}>
        <RoutePreview
          onBackToLongform={setIsRouteRecommendationPage}
          isRouteRecommendationPage={isRouteRecommendationPage}
          fileUploadData={fileUploadData}
        />
      </div>
    </>
  );
};

export default LongForm;
