import { Box, Checkbox, FormControlLabel, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";

//import useLocalDateTime from "../../hook/use-date-with-timezone";
import CustomDatePicker from "../CustomDatePicker";
import { useLocation } from "react-router-dom";
interface DeliveryAddressProps {
  deliveryData: any;
  onDeliveryDataChange: (updatedData: any) => void;
  pickupData: any;
  formErrors: any;
  setFormErrors: (errors: any) => void;
  setErrorCodeReadyTime: (errors: any) => void;
  readyTimeData: any;
  onRequestTimeChange: (updatedData: any) => void;
}

const ReadyTime: React.FC<DeliveryAddressProps> = ({
  deliveryData,
  onDeliveryDataChange,
  pickupData,
  formErrors,
  setFormErrors,
  readyTimeData,
  setErrorCodeReadyTime,
  onRequestTimeChange,
}) => {
  const location = useLocation();
  const [willCall, setWillCall] = useState(false);
  const [dropOff, setDropOff] = useState(pickupData.dropOff || false);
  const [readyNow, setReadyNow] = useState(deliveryData.puReadyNow);
  const [pickupReadyTime, setPickupReadyTime] = useState<any>();
  const [localError, setLocalError] = useState<any>();

  // Synchronize dropOff state with pickupData
  useEffect(() => {
    if (pickupData.dropOff !== undefined) {
      setDropOff(pickupData.dropOff || false);
    }
    if (deliveryData.dropOff !== undefined) {
      setDropOff(deliveryData.dropOff || false);
    }
    if (deliveryData.puReadyNow !== undefined) {
      setReadyNow(deliveryData.puReadyNow);
    }
  }, [pickupData, deliveryData]);

  // Helper function to format date
  const formatDateToCustomString = (date: Date): string => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
  };

  // Add pickupReadyDay to pickupReadyTime if available
  const addDays = (date: Date, days: number) => {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  };

  const getPickupReadyTime = () => {
    if (pickupData.pickupReadyDay && pickupData.pickupReadyTime) {
      const readyDate = new Date(deliveryData.pickupReadyTime);
      return addDays(readyDate, pickupData.pickupReadyDay)
        .toISOString()
        .slice(0, 16);
    }
    if (
      pickupData.pickupReadyTime &&
      pickupData.pickupReadyTime != "1900-01-01T00:00:00"
    ) {
      return pickupData.pickupReadyTime;
    }
    if (
      deliveryData.pickupReadyTime &&
      deliveryData.pickupReadyTime != "1900-01-01T00:00:00"
    ) {
      return deliveryData.pickupReadyTime;
    }
    return null;
  };

  const getPickupReadyTime1 = (adjustedPickupTime: Date) => {
    if (pickupData.pickupReadyDay && pickupData.pickupReadyTime) {
      const readyDate = new Date(deliveryData.pickupReadyTime);
      return addDays(readyDate, pickupData.pickupReadyDay)
        .toISOString()
        .slice(0, 16);
    }
    const delTime = adjustedPickupTime;
    return delTime || null;
  };

  // Format deliveryData.pickupReadyTime to be used as min attribute
  const currentDate = new Date().toISOString().slice(0, 16); // Current date-time in the required format
  const formattedMinDateTime = deliveryData.pickupReadyTime
    ? new Date(deliveryData.pickupReadyTime).toISOString().slice(0, 16)
    : currentDate;
  // Function to check if a date is in the past
  const isPastDate = (dateStr: string) => {
    const selectedDate = new Date(dateStr);
    const now = new Date();
    return selectedDate < now;
  };
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const selectedDateTime = new Date(value);
    const currentDateTime = new Date();
    if (!value) {
      setLocalError(null);
      // setFormErrors((prevErrors: any) => ({
      //   ...prevErrors,
      //   puReadyTime: "Please specify a Shipment Ready Time.",
      // }));
      onDeliveryDataChange({ [name]: null });
      setPickupReadyTime(null);
      return;
    }

    if (name === "pickupReadyTime") {
      if (selectedDateTime < currentDateTime) {
        // setFormErrors((prevErrors: any) => ({
        //   ...prevErrors,
        //   puReadyTime: "",
        // }));
        // setLocalError("The ready time cannot be in the past.");
        setPickupReadyTime(selectedDateTime);
        onDeliveryDataChange({ ["pickupReadyTime"]: selectedDateTime });
      } else if (
        name !== "pickupReadyTime" ||
        selectedDateTime >= currentDateTime
      ) {
        setFormErrors((prevErrors: any) => {
          const { puReadyTime, ...rest } = prevErrors;
          return rest;
        });
        setLocalError(null);
        onDeliveryDataChange({ [name]: value });
      }
    }
  };

  useEffect(() => {
    setErrorCodeReadyTime(localError);
  }, [localError]);

  useEffect(() => {
    onDeliveryDataChange({ ["puReadyNow"]: readyNow });
  }, [readyNow]);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    if (name === "willCall") {
      setWillCall(checked);
      onDeliveryDataChange({
        ...deliveryData,
        willCall: checked,
        pickupReadyTime: null,
      });
      setFormErrors((prevErrors: any) => {
        const { puReadyTime, ...rest } = prevErrors;
        return rest;
      });
    } else if (name === "dropOff") {
      setDropOff(checked);
      onDeliveryDataChange({ ...deliveryData, dropOff: checked });
    } else if (name === "readyNow") {
      onRequestTimeChange(undefined);
      if (checked) {
        setReadyNow(true);
        setLocalError(null);
        onDeliveryDataChange({
          ...deliveryData,
          pickupReadyTime: null,
          puReadyNow: true,
        });
        setFormErrors((prevErrors: any) => {
          const { puReadyTime, ...rest } = prevErrors;
          return rest;
        });
      } else {
        onDeliveryDataChange({
          ...deliveryData,
          pickupReadyTime: null,
          puReadyNow: false,
        });
        setReadyNow(false);
      }
    }
  };

  const adjustDateToToday = (dateStr: string): Date => {
    const originalDate = new Date(dateStr);
    // Get the current date and time
    const now = new Date();
    // Create a new Date object with today's date but the same time as the original date
    const adjustedDate = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate(),
      originalDate.getHours(),
      originalDate.getMinutes(),
      originalDate.getSeconds(),
      originalDate.getMilliseconds()
    );

    return adjustedDate;
  };

  useEffect(() => {
    if (pickupReadyTime) {
      setFormErrors((prevErrors: any) => ({
        ...prevErrors,
        puReadyTime: "",
      }));
    }
  }, [pickupReadyTime]);

  useEffect(() => {
    const rateEstimateData = location?.state?.rateEstimateData;

    let displayValue = readyNow
      ? ""
      : (() => {
          if (getPickupReadyTime()) {
            const adjustedPickupTime = adjustDateToToday(getPickupReadyTime());
            const date =
              adjustedPickupTime < new Date()
                ? null
                : getPickupReadyTime1(adjustedPickupTime);
            return date;
          }
          return null;
        })();
    //cary data from rate estimate
    if (rateEstimateData && rateEstimateData?.pickupReadyTime) {
      displayValue =
        new Date(rateEstimateData?.pickupReadyTime) >= new Date()
          ? rateEstimateData?.pickupReadyTime
          : null;
    }

    setPickupReadyTime(displayValue);
    onDeliveryDataChange({ ["pickupReadyTime"]: displayValue });
  }, []);

  useEffect(() => {
    if (readyTimeData) {
      if (
        readyTimeData.pickupReadyTime &&
        readyTimeData.pickupReadyTime != "1900-01-01T00:00:00" &&
        readyTimeData.pickupReadyDay
      ) {
        setReadyNow(false);
        const pickupReadyTime = readyTimeData.pickupReadyTime;
        const pickupReadyDay = readyTimeData.pickupReadyDay;
        const newDate = new Date();

        // Extract hours and minutes from pickupReadyTime
        const [hours, minutes] = pickupReadyTime
          .split("T")[1]
          .split(":")
          .map(Number);
        newDate.setHours(hours, minutes, 0, 0);

        // Calculate the final date
        const finalDate = addDays(newDate, pickupReadyDay);
        const formattedFinalDate = formatDateToCustomString(finalDate);

        // Update the state with the formatted final date
        setPickupReadyTime(formattedFinalDate);
        onDeliveryDataChange({ pickupReadyTime: formattedFinalDate });
      } else {
        if (!readyNow) {
          const date = (() => {
            if (getPickupReadyTime()) {
              const adjustedPickupTime = adjustDateToToday(
                getPickupReadyTime()
              );
              return adjustedPickupTime < new Date()
                ? pickupReadyTime
                : getPickupReadyTime1(adjustedPickupTime);
            }
            return null;
          })();
          // Update the state with the calculated date or null
          //This seems unwanted code: raise a bug 59238
          //setPickupReadyTime(date);
          //onDeliveryDataChange({ ...deliveryData, pickupReadyTime: date });
        }
      }
    }
  }, [readyTimeData, readyNow]);

  // useEffect(() => {
  //   handleChange({
  //     target: { name: "pickupReadyTime", value: pickupReadyTime },
  //   } as any);
  // }, [pickupReadyTime])

  const updateDeliveryDataIfNeeded = () => {
    const pickupReadyTime = getPickupReadyTime();
    const isPast = isPastDate(pickupReadyTime);

    if (readyNow || isPast) {
      // If the date is past or 'readyNow' is true, set it to empty string
      deliveryData.pickupReadyTime = null;
    } else {
      // Otherwise, update deliveryData with the calculated pickupReadyTime
      deliveryData.pickupReadyTime = pickupReadyTime;
    }
  };

  useEffect(() => {
    updateDeliveryDataIfNeeded();
  }, [pickupReadyTime, readyNow]);

  useEffect(() => {
    if (pickupReadyTime) {
      setLocalError(null);
    }
  }, [pickupReadyTime, readyNow]);

  return (
    <Box className="d-flex flex-column w-100 border rounded p16">
      <Typography
        variant="h6"
        className="Sub-header"
        sx={{ paddingBottom: "8px!important" }}
      >
        Shipment Ready Time
      </Typography>

      <CustomDatePicker
        handleDateChange={handleChange}
        label="Ready Date"
        id="txtReadyDate"
        name="pickupReadyTime"
        value={pickupReadyTime}
        isClearable={false}
        isClearValue={readyNow || pickupReadyTime === null || willCall}
        helperText={localError}
        showError={
          !!formErrors.puReadyTime ||
          localError === "The ready time cannot be in the past."
        }
        showTimeSelect={true}
        isDisable={readyNow || willCall}
        minDate={currentDate}
      />

      {/* <TextField
        id="datetime-local"
        label="Ready Date"
        type="datetime-local"
        name="pickupReadyTime"
        InputLabelProps={{ shrink: true }}
        value={displayValue} // Set empty string if "Ready Now" is selected or the date is in the past
        onChange={handleChange}
        disabled={readyNow || willCall} // Disable if "Ready Now" or "Will Call" is selected
        error={!!localError || !!formErrors.puReadyTime} // Display error if either local or form error exists
        helperText={localError} // Display the local error or form error
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <IconButton>
                <img
                  src={calendarIcon}
                  alt="Calendar icon"
                  style={{ maxWidth: "100%" }}
                />
              </IconButton>
            </InputAdornment>
          ),
          style: {
            color: localError || formErrors.puReadyTime ? "#d32f2f" : "inherit",
          },
          inputProps: {
            min: formattedMinDateTime, // Use current date if pickupReadyTime is not available
          },
        }}
        FormHelperTextProps={{
          style: {
            color: "#d32f2f",
          },
        }}
      /> */}
      <Box className="d-flex gap-2 mt-2 order_chkbx">
        <div className="d-flex">
          <FormControlLabel
            className="chk_bx_50"
            control={
              <Checkbox
                id="cbReadyNow"
                name="readyNow"
                checked={readyNow}
                onChange={handleCheckboxChange}
                disabled={willCall}
              />
            }
            label="Ready Now"
          />
          <FormControlLabel
            className="chk_bx_50 pr-8"
            control={
              <Checkbox
                name="dropOff"
                id="cbDropOff"
                checked={dropOff}
                onChange={handleCheckboxChange}
              />
            }
            label="Drop Off"
            disabled={willCall}
          />
        </div>
        <div className="d-flex">
          <FormControlLabel
            className="chk_bx_50"
            control={
              <Checkbox
                id="cbWillCall"
                name="willCall"
                checked={willCall}
                onChange={handleCheckboxChange}
              />
            }
            label="Will Call"
          />
        </div>
      </Box>
    </Box>
  );
};

export default ReadyTime;
