import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../types/global";
import { LongFormRequestDTO } from "../../models/long-form-request-item-dto";
import { BookReOrderWithoutRoute } from "./book-re-order-without-route.reducer";



const useBookReOrderWithoutRouteLogic = () => {
  const dispatch = useDispatch();
  const { BookReOrderWithoutRouteLoading, BookReOrderWithoutRouteError } = useSelector((state: RootState) => state.bookReOrderWithoutRoute);

  useEffect(() => {
    if (BookReOrderWithoutRouteError) {
      // dispatch(showNotification('Active Order failed: ' + error, 'error'));
    }
  }, [BookReOrderWithoutRouteError, dispatch]);

  const handleBookReOrderWithoutRoute = async (param:LongFormRequestDTO ) => {
    try {
      const response = await dispatch(BookReOrderWithoutRoute(param) as any);
      return response;
    } catch (err) {
      throw err;
    }
  };

  return { BookReOrderWithoutRouteLoading, BookReOrderWithoutRouteError, BookReOrderWithoutRoute: handleBookReOrderWithoutRoute };
};

export default useBookReOrderWithoutRouteLogic;
