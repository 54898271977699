import { Dispatch } from "redux"; // Import Dispatch type
import axiosInstance from "../../config/axios-interceptor";
import { API_BASE_URL } from "../../config/constants";
import { LongFormRequestDTO } from "../../models/long-form-request-item-dto";


// Action Types for Default Data
const BOOK_ORDER_WITH_ROUTE_REQUEST  = "BOOK_ORDER_WITH_ROUTE_REQUEST ";
const BOOK_ORDER_WITH_ROUTE_SUCCESS = "BOOK_ORDER_WITH_ROUTE_SUCCESS";
const BOOK_ORDER_WITH_ROUTE_FAILURE = "BOOK_ORDER_WITH_ROUTE_FAILURE";


// Action Creators for Default Data
export const bookOrderWithRouteRequest = () => ({
    type: BOOK_ORDER_WITH_ROUTE_REQUEST ,
  });
  
  export const bookOrderWithRouteSuccess = () => ({
    type: BOOK_ORDER_WITH_ROUTE_SUCCESS,
  });
  
  export const bookOrderWithRouteFailure = (error: string) => ({
    type: BOOK_ORDER_WITH_ROUTE_FAILURE,
    payload: error,
  });


// Initial State for  Active Order
export interface BookOrderWithRouteState {
    isAuthenticated: boolean;
    BookOrderWithRouteLoading: boolean;
    BookOrderWithRouteError: string | null;
  }

  const initialAuthState: BookOrderWithRouteState = {
    isAuthenticated: false,
    BookOrderWithRouteLoading: false,
    BookOrderWithRouteError: null,
  };
  
  // Define the interface for the API response
interface BookOrderWithRouteResponse {
    success: boolean;
    message: string;
    data: any;
    statusCode: string;
  }
  
  // Reducer for Authentication
  const bookOrderWithRouteReducer = (state = initialAuthState, action: any): BookOrderWithRouteState => {
    switch (action.type) {
      case BOOK_ORDER_WITH_ROUTE_REQUEST :
        return { ...state, BookOrderWithRouteLoading: true, BookOrderWithRouteError: null };
      case BOOK_ORDER_WITH_ROUTE_SUCCESS:
        return { ...state, BookOrderWithRouteLoading: false, isAuthenticated: true };
      case BOOK_ORDER_WITH_ROUTE_FAILURE:
        return { ...state, BookOrderWithRouteLoading: false, BookOrderWithRouteError: action.payload };
      default:
        return state;
    }
  };
  
  // Thunk for Active Order
  export const BookOrderWithRoute = (param: LongFormRequestDTO) => async (dispatch: Dispatch) => {
      dispatch(bookOrderWithRouteRequest());
      try {
        const response = await axiosInstance.post(
          `${API_BASE_URL}/api/Orders/BookOrderWithRoute`,
          param
      );
        // Assuming  Active Order was successful if no error occurred
        dispatch(bookOrderWithRouteSuccess());
        const data: BookOrderWithRouteResponse = response.data;
        // Store token in localStorage
         if (response.status === 200 && data.success) {
          // Store token in localStorage
          return { data };
        } else {
          // Handle different status codes if necessary
          dispatch(bookOrderWithRouteFailure(data.message));
        }
        return response.data;
      } catch (error: any) {
        if(error.response.data.message){
          dispatch(bookOrderWithRouteFailure(error.response.data.message));
        }else{
          dispatch(bookOrderWithRouteFailure(error.message));
        }

      }
    };


    export default bookOrderWithRouteReducer;  
  