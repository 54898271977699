import { Dispatch } from "redux"; // Import Dispatch type
import axiosInstance from "../../config/axios-interceptor";
import { API_BASE_URL } from "../../config/constants";
import { LongFormRequestDTO } from "../../models/long-form-request-item-dto";


// Action Types for Default Data
const LONGFORM_ReORDER_REQUEST = "LONGFORM_ReORDER_REQUEST";
const LONGFORM_ReORDER_SUCCESS = "LONGFORM_ReORDER_SUCCESS";
const LONGFORM_ReORDER_FAILURE = "LONGFORM_ReORDER_FAILURE";


// Action Creators for Default Data
export const longFormReOrderRequest = () => ({
    type: LONGFORM_ReORDER_REQUEST,
  });
  
  export const longFormReOrderSuccess = () => ({
    type: LONGFORM_ReORDER_SUCCESS,
  });
  
  export const longFormReOrderFailure = (error: string) => ({
    type: LONGFORM_ReORDER_FAILURE,
    payload: error,
  });



// Initial State for  Active Order
export interface LongFormReOrderState {
    isAuthenticated: boolean;
    LongFormReOrderLoading: boolean;
    LongFormReOrderError: string | null;
  }


  const initialAuthState: LongFormReOrderState = {
    isAuthenticated: false,
    LongFormReOrderLoading: false,
    LongFormReOrderError: null,
  };
  

  // Define the interface for the API response
interface LongFormReOrderResponse {
    success: boolean;
    message: string;
    data: any;
    statusCode: string;
  }
  
  // Reducer for Authentication
  const longFormReOrderReducer = (state = initialAuthState, action: any): LongFormReOrderState => {
    switch (action.type) {
      case LONGFORM_ReORDER_REQUEST:
        return { ...state, LongFormReOrderLoading: true, LongFormReOrderError: null };
      case LONGFORM_ReORDER_SUCCESS:
        return { ...state, LongFormReOrderLoading: false, isAuthenticated: true };
      case LONGFORM_ReORDER_FAILURE:
        return { ...state, LongFormReOrderLoading: false, LongFormReOrderError: action.payload };
      default:
        return state;
    }
  };
  
  // Thunk for Active Order
  export const LongFormReOrder = (param: LongFormRequestDTO) => async (dispatch: Dispatch) => {
      dispatch(longFormReOrderRequest());
      try {
        const response = await axiosInstance.post(
          `${API_BASE_URL}/api/Orders/RECreateOrder`,
          param
      );
        // Assuming  Active Order was successful if no error occurred
        dispatch(longFormReOrderSuccess());
        const data: LongFormReOrderResponse = response.data;
        // Store token in localStorage
         if (response.status === 200 && data.success) {
          // Store token in localStorage
          return { data };
        } else {
          // Handle different status codes if necessary
          dispatch(longFormReOrderFailure(data.message));
        }
        return response.data;
      } catch (error: any) {
        if(error.response.data.message){
          dispatch(longFormReOrderFailure(error.response.data.message));
        }else{
          dispatch(longFormReOrderFailure(error.message));
        }

      }
    };


    export default longFormReOrderReducer;  
  