import React, { useMemo, useState } from "react";
import {
  Autocomplete,
  FormControl,
  InputLabel,
  MenuItem,
  useMediaQuery,
} from "@mui/material";

import {
  Box,
  Grid,
  Select,
  TextField,
  Typography,
  Button,
} from "@mui/material";
import { FieldArray } from "formik";
import ErrorInputAdornment from "../error-input-adornment";
import { handleDecimalValue } from "../../util/numeric-value";
import { AddressList, TissueOption } from "../../../models/health-care-dto";
import { getUniqueConcatenatedAddresses } from "../../util/common";

interface GiftTissueInfoProps {
  formik: any;
  destinationAddressData: AddressList;
  giftDetailsData: any;
}
const GiftTissueInfo = (props: GiftTissueInfoProps) => {
  const { formik, destinationAddressData, giftDetailsData } = props;

  const isMobile = useMediaQuery("(max-width:600px)");
  const [openStates, setOpenStates] = useState<Record<number, boolean>>({});
  const [openStatesDest, setOpenStatesDest] = useState<Record<number, boolean>>(
    {}
  );

  const handleOpen = (index: number) => {
    setOpenStates((prev) => ({ ...prev, [index]: true }));
  };

  const handleClose = (index: number) => {
    setOpenStates((prev) => ({ ...prev, [index]: false }));
  };

  const handleOpenDest = (index: number) => {
    setOpenStatesDest((prev) => ({ ...prev, [index]: true }));
  };

  const handleCloseDest = (index: number) => {
    setOpenStatesDest((prev) => ({ ...prev, [index]: false }));
  };
  interface MenuStyles {
    maxHeight: string;
  }

  const [menuStyles, setMenuStyles] = useState<MenuStyles>({
    maxHeight: "200px", // Default max height for desktop
  });

  const handleInputChange1 = (event: React.ChangeEvent<{}>, value: string) => {
    return value.trim();
  };

  // Custom filter options function to handle uniqueness
  const customFilterOptions = (options: any[], { inputValue }: any) => {
    const trimmedInputValue = inputValue.trim().toLowerCase();

    // Filter the options and ensure they are unique based on displayName
    const filteredOptions = options.filter((option) =>
      option.displayName.toLowerCase().includes(trimmedInputValue)
    );

    // Use a Set to ensure uniqueness
    const uniqueFilteredOptions = new Set(
      filteredOptions.map((option) => option.displayName)
    );

    // Convert the Set back to an array of options
    return filteredOptions.filter((option) =>
      uniqueFilteredOptions.has(option.displayName)
    );
  };

  // Custom filter options function to handle uniqueness
  const customFilterOptionsTissue = (options: any[], { inputValue }: any) => {
    const trimmedInputValue = inputValue.trim().toLowerCase();

    // Filter the options and ensure they are unique based on tissueOption
    const filteredOptions = options.filter((option) =>
      option.tissueOption.toLowerCase().includes(trimmedInputValue)
    );

    // Use a Set to ensure uniqueness
    const uniqueFilteredOptions = new Set(
      filteredOptions.map((option) => option.tissueOption)
    );

    // Convert the Set back to an array of options
    return filteredOptions.filter((option) =>
      uniqueFilteredOptions.has(option.tissueOption)
    );
  };

  const filteredOptions = useMemo(() => {
    const noAddress = {
      agencyAcronym: "",
      companyName:
        "Destination not preplanned. Please call 1-866-Screen5 for destination information",
      addressLine1: "",
      city: "",
      state: "",
      zipCode: "",
      country: "",
      addressType: "",
      timezoneID: "",
      standardAbbr: "",
      displayName:
        "Destination not preplanned. Please call 1-866-Screen5 for destination information",
      isDefault: true,
    };
    const restAddress = getUniqueConcatenatedAddresses(destinationAddressData);
    return [noAddress, ...restAddress];
  }, [destinationAddressData, formik.values.giftInfo]);

  const handleToggleDropdown = (index: number) => {
    setOpenStates((prev) => ({
      ...prev,
      [index]: !prev[index], // Toggle the specific index state
    }));
  };

  return (
    <>
      <Box className="mt-3 pb-40 w-100">
        <Grid container spacing={2}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Box className="rounded p16 border">
              <Typography variant="h6" className="Sub-header">
                Gift Details
              </Typography>
              <Grid container spacing={2}>
                <FieldArray name="giftInfo">
                  {({ push, remove }) => (
                    <>
                      {formik.values.giftInfo.map(
                        (item: any, index: number) => (
                          <>
                            <Grid
                              key={`info-${index}`}
                              item
                              lg={6}
                              md={6}
                              sm={6}
                              xs={6}
                            >
                              <FormControl size="medium" className="w-100">
                                <Autocomplete
                                  className="hcAutoComplete"
                                  id={`hcGifttissue`}
                                  onOpen={() => handleOpen(index)}
                                  onClose={() => handleClose(index)}
                                  options={giftDetailsData}
                                  getOptionLabel={(option) =>
                                    option.tissueOption || ""
                                  }
                                  filterOptions={customFilterOptionsTissue}
                                  onInputChange={handleInputChange1}
                                  onChange={(event, value) => {
                                    formik.setFieldValue(
                                      `giftInfo.${index}.giftIssue`,
                                      value ? value.tissueOption : ""
                                    );
                                  }}
                                  value={
                                    giftDetailsData.find(
                                      (option: any) =>
                                        option.tissueOption === item.giftIssue
                                    ) || ""
                                  }
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      name={`giftInfo.${index}.giftIssue`}
                                      label="Gifts/Tissue"
                                      variant="outlined"
                                      error={
                                        Boolean(
                                          formik.touched.giftInfo &&
                                            (formik.touched.giftInfo as any)[
                                              index
                                            ]?.giftIssue
                                        ) &&
                                        Boolean(
                                          formik.errors.giftInfo &&
                                            (formik.errors.giftInfo as any)[
                                              index
                                            ]?.giftIssue
                                        )
                                      }
                                      InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                          <>{params.InputProps.endAdornment}</>
                                        ),
                                      }}
                                    />
                                  )}
                                  sx={{ width: isMobile ? "100%" : undefined }}
                                />
                              </FormControl>
                            </Grid>
                            <Grid item lg={6} md={6} sm={6} xs={6}>
                              <TextField
                                label="Quantity"
                                variant="outlined"
                                className="w-100"
                                size="medium"
                                name={`giftInfo.${index}.quantity`}
                                id={`hcQuantity`}
                                inputProps={{ min: 1, maxLength: 4 }}
                                onKeyDown={handleDecimalValue}
                                onChange={formik.handleChange}
                                value={item.quantity}
                                error={
                                  Boolean(
                                    formik.touched.giftInfo &&
                                      (formik.touched.giftInfo as any)[index]
                                        ?.quantity
                                  ) &&
                                  Boolean(
                                    formik.errors.giftInfo &&
                                      (formik.errors.giftInfo as any)[index]
                                        ?.quantity
                                  )
                                }
                                InputProps={{
                                  endAdornment: Boolean(
                                    formik.touched.giftInfo &&
                                      (formik.touched.giftInfo as any)[index]
                                        ?.quantity
                                  ) &&
                                    Boolean(
                                      formik.errors.giftInfo &&
                                        (formik.errors.giftInfo as any)[index]
                                          ?.quantity
                                    ) && <ErrorInputAdornment />,
                                }}
                              />
                            </Grid>
                            <Grid item lg={9 / 2} md={9 / 2} sm={9 / 2} xs={12}>
                              <FormControl size="medium" className="w-100">
                                <Autocomplete
                                  className="hcAutoComplete"
                                  id={`hcAlternatedestination`}
                                  onOpen={() => handleOpenDest(index)}
                                  onClose={() => handleCloseDest(index)}
                                  options={filteredOptions}
                                  getOptionLabel={(option) =>
                                    option.displayName || ""
                                  }
                                  renderOption={(props, option) => (
                                    <li {...props}>
                                      {option.isDefault && (
                                        <>
                                          <span>
                                            <strong>
                                              {option.companyName || ""}
                                            </strong>
                                          </span>
                                        </>
                                      )}

                                      {!option.isDefault && (
                                        <>
                                          <span>
                                            {option.agencyAcronym || ""} --{" "}
                                            <strong>
                                              {option.companyName || ""}
                                            </strong>
                                            {option.addressLine1 &&
                                              `, ${option.addressLine1}`}
                                            {option.city && `, ${option.city}`}
                                            {", "}
                                            {option.state || ""}{" "}
                                            {option.zipCode || ""}
                                          </span>
                                        </>
                                      )}
                                    </li>
                                  )}
                                  filterOptions={customFilterOptions}
                                  onInputChange={handleInputChange1}
                                  onChange={(event, value) => {
                                    formik.setFieldValue(
                                      `giftInfo.${index}.alternativeDestination`,
                                      value ? value.displayName : ""
                                    );
                                  }}
                                  value={
                                    filteredOptions.find(
                                      (option: any) =>
                                        option.displayName ===
                                        item.alternativeDestination
                                    ) || ""
                                  }
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      name={`giftInfo.${index}.alternativeDestination`}
                                      label="Alternate Destination"
                                      variant="outlined"
                                      InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                          <>
                                            {params.InputProps.endAdornment}

                                            {/* Dropdown icon */}
                                          </>
                                        ),
                                      }}
                                    />
                                  )}
                                  sx={{ width: isMobile ? "100%" : undefined }}
                                />
                              </FormControl>
                            </Grid>

                            <Grid item lg={9 / 2} md={9 / 2} sm={9 / 2} xs={12}>
                              <TextField
                                label="Alternate Destination (Optional)"
                                variant="outlined"
                                className="w-100"
                                size="medium"
                                name={`giftInfo.${index}.alternativeDestination2`}
                                id={`hcAlternativeDestinationOptional`}
                                onChange={formik.handleChange}
                                value={item.alternativeDestination2}
                                inputProps={{ maxLength: 4000 }}
                              />
                            </Grid>

                            <Grid
                              item
                              lg={3}
                              md={3}
                              sm={3}
                              xs={12}
                              className="d-flex align-center"
                            >
                              <Box className="d-flex justify-content-end w-100">
                                {index + 1 == formik.values.giftInfo.length && (
                                  <>
                                    <Button
                                      variant="outlined"
                                      id="hcBtnAddPiece"
                                      className="width100px rounded add-edit_btn"
                                      onClick={() => {
                                        push({
                                          giftIssue: "",
                                          quantity: "",
                                          alternativeDestination: "",
                                          alternativeDestination2: "",
                                        });
                                      }}
                                    >
                                      ADD PIECE
                                    </Button>
                                  </>
                                )}

                                {formik.values.giftInfo.length > 1 &&
                                  index + 1 !=
                                    formik.values.giftInfo.length && (
                                    <>
                                      <Button
                                        variant="outlined"
                                        id="hcBtndeletePiece"
                                        className="width100px rounded add-edit_btn delete_btn"
                                        onClick={() => remove(index)}
                                      >
                                        DELETE PIECE
                                      </Button>
                                    </>
                                  )}
                                {/*  */}
                              </Box>
                            </Grid>
                          </>
                        )
                      )}
                    </>
                  )}
                </FieldArray>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default GiftTissueInfo;
