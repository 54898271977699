import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../types/global";
import { LongFormRequestDTO } from "../../models/long-form-request-item-dto";
import { BookReOrderWithRoute } from "./book-re-order-with-route.reducer";




const useBookReOrderWithRouteLogic = () => {
  const dispatch = useDispatch();
  const { BookReOrderWithRouteLoading, BookReOrderWithRouteError } = useSelector((state: RootState) => state.bookReOrderWithRoute);

  useEffect(() => {
    if (BookReOrderWithRouteError) {
      // dispatch(showNotification('Active Order failed: ' + error, 'error'));
    }
  }, [BookReOrderWithRouteError, dispatch]);

  const handleBookReOrderWithRoute = async (param:LongFormRequestDTO ) => {
    try {
      const response = await dispatch(BookReOrderWithRoute(param) as any);
      return response;
    } catch (err) {
      throw err;
    }
  };

  return { BookReOrderWithRouteLoading, BookReOrderWithRouteError, bookReOrderWithRoute: handleBookReOrderWithRoute };
};

export default useBookReOrderWithRouteLogic;
