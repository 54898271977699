import React, { useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import logo from "../../assets/images/logo/mnx_logo_login.svg";
import { Link, useNavigate } from "react-router-dom";
import AnchorTemporaryDrawer from "./sidebar";
import {
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";

interface Props {
  window?: () => Window;
}

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
}


const Header: React.FC = (props: Props) => {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [closeSidebar, setCloseSidebar] = React.useState(false);
  const [isRoles, setisRoles] = React.useState([]);
  const [isLimited, setisLimited] = React.useState(false);
  
  const drawerWidth = 240;
const navItems = [
  { text: "TRACK ORDERS", url: "/track-order" },
  { text: "NEW ORDER", url: "/longform-order" },
  ...(!isLimited ? [{ text: "PRINT LABELS", url: "/batch-prints" }] : []),
];

  const toPascalCase = (str:any) => {
    return str
      .replace(/\w\S*/g, (word:any) => word.charAt(0).toUpperCase() + word.substr(1).toLowerCase())
      .replace(/\s+/g, '');
  };

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const navigate = useNavigate();
  const handleLogoClick = () => {
    setCloseSidebar((prevState) => !prevState);
    navigate("/");
  };

  const handleNavigation = (url: string) => {
    setCloseSidebar((prevState) => !prevState);
    if (window.location.pathname !== url) {
      navigate(url);
    } else {
      window.location.href = url;
    }
  };
  
  useEffect(() => {
    const isRolesAvailable = JSON.parse(localStorage.getItem("roles") || "[]");
    const isLimitedUser = isRolesAvailable.includes("limited");
    if(isLimitedUser)
    {
      setisLimited(true);
    }
    else
    {
      setisLimited(false);
    }
    setisRoles(isRolesAvailable);
  }, []);
  
  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Box className="lognin-image left-align loginlogo">
        <img className="login_image" src={logo} alt="MNX" />
      </Box>
      <Divider />
      <List>
        {navItems.map((item) => (
          <ListItem key={item.text} disablePadding>
            <ListItemButton sx={{ textAlign: "center" }} id="ibDrawer">
              <ListItemText primary={item.text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <div className="Top-nav">
      <CssBaseline />
      <AppBar component="nav" className="header">
        <Toolbar className="login-header">
          {/* <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton> */}
          <Box
            className="lognin-image left-align loginlogo"
            component="div"
            sx={{ flexGrow: 1, display: { sm: "block" } }}
          >
            <img
              src={logo}
              alt="MNX"
              style={{ cursor: "pointer" }}
              onClick={() => {
                if (isRoles.length > 0) {
                  handleLogoClick();
                }

              }}
              id="ibLogo"
            />
            {/* <Link
              to="/"
              onClick={() => {
                setCloseSidebar((prevState) => !prevState);
              }}
            >
              <img src={logo} alt="MNX" />
            </Link> */}
          </Box>
          <Box sx={{ display: { xs: "none", sm: "block" } }}>         
            {isRoles.length > 0 && navItems.map((item) => (
              <Button
              id={`btn${toPascalCase(item.text)}`}
              onClick={() => handleNavigation(item.url)}
                // onClick={() => {
                //   setCloseSidebar((prevState) => !prevState);
                //   setTimeout(() => {
                //     navigate(item.url);
                //   }, 0);
                // }}
                className="Menu-item nav_btn"
                key={item.text}
              >
                {item.text}
              </Button>
            ))}
            {/* <Button onClick={handleLogout}>LOGOUT</Button> */}
          </Box>
          {/* <IconButton size="large" aria-label="search" color="inherit">
            <SearchIcon />
          </IconButton> */}

          <AnchorTemporaryDrawer
            isOpen={mobileOpen}
            closeSidebar={closeSidebar}
            onClose={handleDrawerToggle}
          />
        </Toolbar>
      </AppBar>
      {/* <nav className="header" style={{ borderBottom: "1px #046386 solid" }}>
        <Drawer
          container={container}
          color="white"
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </nav> */}
    </div>
  );
};

export default Header;
