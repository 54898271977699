import moment from "moment";
import { MAX_COMPANY_ID } from "../../config/constants";
import { RateEstimateChargeDTO } from "../../models/rate-estimate-dto";

export const geCalculateEstimatePayload = (formik: any) => {
    const {
        accountNumber,
        serviceID,
        orderDateTime,
        totalDMWeight,
        totalWeight,
        declValue,
        totalDst,
        totalDstUnit,
        pickupAddressCode,
        pickupCountryID,
        pickupCityName,
        pickupStateProvID,
        pickupPostalCode,
        pickupReadyTime,
        pickupDistance,
        pickupDistanceUnit,
        pickupAttempts,pickupDropAirportID,pickupWaitTime,
        deliveryAddressCode,
        deliveryCountryID,
        deliveryCityName,
        deliveryStateProvID,
        deliveryPostalCode,
        requestedDeliveryTime,
        deliveryDistance,
        deliveryDistanceUnit,
        deliveryAttempts,
        deliveryDropAirportID,
        isDangerousGoods,
        deliveryWaitTime,
        attributeList
      } = formik.values;
      const totalPieces = formik.values.packageInfo.reduce(
        (acc: any, info: any) => acc + info.pieces,
        0
      );
      const weightUOM = formik.values.packageInfo[0].weightUOM;
      const weight = formik.values.packageInfo[0].weight;
      const param = {
        companyID: MAX_COMPANY_ID,
        accountNumber: accountNumber == 'All' ? null : accountNumber,
        serviceID: parseInt(serviceID) || 0,
        orderDate: orderDateTime,
        orderTime: orderDateTime,
        pieces: totalPieces || 0,
        weight: totalWeight || weight,
        dimWeight: totalDMWeight || 0,
        weightUOM: weightUOM || 'LB',
        declaredValue: parseFloat(declValue) || 0,
        totalDistance: parseFloat(totalDst) || 0,
        totalDistanceUOM: totalDstUnit || "",
        puAddressCode: pickupAddressCode || '',
        puCountryID: pickupCountryID || '',
        puCityName: pickupCityName || '',
        puStateProvID: pickupStateProvID || '',
        puPostalCode: pickupPostalCode || '',
        puDate: pickupReadyTime || '',
        puTime: pickupReadyTime || '',
        puDistance: parseFloat(pickupDistance) ||  0,
        puDistanceUOM:pickupDistanceUnit || '',
        puAirportID: pickupDropAirportID || '',
        puWaitingTime: parseInt(pickupWaitTime) ||  0,
        puAttemptCount: parseInt(pickupAttempts) || 0,
        delAddressCode: deliveryAddressCode || '',
        delCountryID: deliveryCountryID || "",
        delCityName: deliveryCityName || '',
        delStateProvID: deliveryStateProvID || '',
        delPostalCode: deliveryPostalCode || "",
        delDate: requestedDeliveryTime || '',
        delTime: requestedDeliveryTime || '',
        delDistance: parseFloat(deliveryDistance) ||  0,
        delDistanceUOM: deliveryDistanceUnit || '',
        delAirportID: deliveryDropAirportID || '',
        delWaitingTime: parseInt(deliveryWaitTime) ||  0,
        delAttemptCount: parseInt(deliveryAttempts) ||  0,
        attributeList: attributeList && attributeList.length > 0 ? attributeList.join(',') : '',
        gelPackCount: 0,
        dryIceWeightKG: 0,
        carrierToProvideDryIce: true,
        carrierToProvideGelPack: true,
        isDangerousGoods: isDangerousGoods,
        commuterFlightCount: 0,
      };

      return param;
}

export const estimateETAPayload = (formik: any, serviveData: any, isFlightOptionVisible: boolean = false) => {
  const params = geCalculateEstimatePayload(formik)
  const {pickupTimezoneID, deliveryTimezoneID,pickupAddress1,pickupAddress2,deliveryAddress1,deliveryAddress2,pickupReadyTime} = formik.values;
const {orderTime, accountNumber, serviceID, weight, weightUOM,totalDistance, totalDistanceUOM, dimWeight, puPostalCode, puAirportID, delPostalCode, pieces, companyID, puCountryID, delCountryID, puCityName, delCityName, puStateProvID, delStateProvID, delAirportID, delDistance, delDistanceUOM} =params 
const localDateTime = moment().format('YYYY-MM-DDTHH:mm:ss');  
const serviceItem = serviveData?.find(
  (_acc: any) => _acc.serviceID == serviceID // eslint-disable-line
);
const etaPayload = {
    userID: accountNumber == 'All' ? null : accountNumber,
    userName: accountNumber == 'All' ? null : accountNumber,
    accountNumber: accountNumber == 'All' ? null : accountNumber,
    serviceID:serviceID.toString(),
    usageDateTime: orderTime,
    weight: weight,
    weightUOM: weightUOM,
    pickupPostalCode:puPostalCode,
    pickupAirportID: puAirportID,
    deliveryPostalCode :delPostalCode,
    deliveryAirportID:delAirportID,
    pieces: pieces,
    companyID: companyID,
    userGUID : '',
    "rateCalculationDetails": {
    "serviceID": serviceID.toString(),
    "puCountryID": puCountryID,
    "puPostalCode": puPostalCode,
    "puCity": puCityName,
    "puState": puStateProvID,
    "puTimezoneID": pickupTimezoneID,
    "puAirportCode": puAirportID,
    "puAddrs1": pickupAddress1,
    "puAddrs2": pickupAddress2,
    "puAddrs3": "",
    "delCountryID": delCountryID,
    "delPostal": delPostalCode,
    "delAddrs1": deliveryAddress1,
    "delAddrs2": deliveryAddress2,
    "delAddrs3": "",
    "delCity": delCityName,
    "delState": delStateProvID,
    "delTimezoneID": deliveryTimezoneID, //USA-ET
    "delAirportCode": delAirportID,
    "serviceCode": serviceItem?.serviceCode,
    "shipmentGuid": "",
    "accountNumber": accountNumber,
    "baseDateTime": localDateTime,
    "totalDist": totalDistance.toString(),
    "totalDistanceUOM": totalDistanceUOM,
    "deliveryDistance": delDistance.toString(),
    "deliveryDistanceUOM": delDistanceUOM,
    "deliveryDistanceStr": delDistance.toString(),
    "weightUnits": weightUOM,
    "weight": weight.toString(),
    "dimWeight": dimWeight ?  dimWeight.toString() : '0',
    "departAfter": pickupReadyTime,
    // "pickupDateTime": orderTime,
    "pickupDateTime": pickupReadyTime,
    "serviceNonIntegrated": isFlightOptionVisible
  }


  }
  return etaPayload;

}
export const getTotalDistancePayload = (accounts: any = '', formik: any = '',defaultData: any = "",
  keyName: string = "",
  apiData: any = "", _Code: any = '') => {
    const {
      accountNumber,
      pickupPostalCode,
      pickupCountryID,
      pickupDropAirportID,
      deliveryCountryID,
      deliveryDropAirportID,
      deliveryPostalCode,
    } = formik.values;

    const _accountNumber = defaultData
      ? defaultData?.accountNumber
      : accountNumber;
   
   
    const account = accounts?.find(
      (_acc: any) => _acc.accountNumber == _accountNumber // eslint-disable-line
    );
   

    let postalCode = "";
    let countryID = "";
    let airportID = "";

    let otherPostalCode = "";
    let otherCountryID = "";
    let otherAirportID = "";
    let type = keyName == "pickupPostalCode" ? "PD" : "DD"; // eslint-disable-line

    if (defaultData) {
      postalCode =
        keyName == "pickupPostalCode" // eslint-disable-line
          ? defaultData?.pickupPostalCode
          : defaultData?.deliveryPostalCode;
      countryID =
        keyName == "pickupPostalCode" // eslint-disable-line
          ? defaultData?.pickupCountryID
          : defaultData?.deliveryCountryID;
      airportID =
        keyName == "pickupPostalCode" // eslint-disable-line
          ? defaultData?.pickupDropAirportID
            ? defaultData?.pickupDropAirportID
            : apiData?.airportID
          : defaultData?.deliveryDropAirportID
          ? defaultData?.deliveryDropAirportID
          : apiData?.airportID;

      otherPostalCode =
        keyName == "pickupPostalCode" // eslint-disable-line
          ? defaultData?.deliveryPostalCode
          : defaultData?.pickupPostalCode;
      otherCountryID =
        keyName == "pickupPostalCode" // eslint-disable-line
          ? defaultData?.deliveryCountryID
          : defaultData?.pickupCountryID;
      otherAirportID =
        keyName == "pickupPostalCode" // eslint-disable-line
          ? defaultData?.deliveryDropAirportID
            ? defaultData?.deliveryDropAirportID
            : deliveryDropAirportID
          : defaultData?.pickupDropAirportID
          ? defaultData?.pickupDropAirportID
          : pickupDropAirportID;
    } else {
      const pickupCountry = keyName == "pickupPostalCode" ? apiData?.countryID : pickupCountryID;
      const deliveryCountry = keyName == "deliveryPostalCode" ?apiData?.countryID : deliveryCountryID;
      const pID = 
      postalCode = pickupCountryID ? pickupCountryID : 
        keyName == "pickupPostalCode" ? pickupPostalCode : deliveryPostalCode; // eslint-disable-line
      countryID =
        keyName == "pickupPostalCode" ? pickupCountryID?.trim() ? pickupCountry : apiData?.countryID : deliveryCountryID?.trim() ? deliveryCountry : apiData?.countryID; // eslint-disable-line
      // airportID =
      //   keyName == "pickupPostalCode"
      //     ? pickupDropAirportID
      //     : deliveryDropAirportID;
       airportID =
        keyName == "pickupPostalCode" // eslint-disable-line
          ? apiData?.airportID
          : apiData?.airportID;
          
      otherPostalCode =
        keyName == "pickupPostalCode" ? deliveryPostalCode : pickupPostalCode; // eslint-disable-line
     
        otherCountryID =
        keyName == "pickupPostalCode" ? deliveryCountryID?.trim() ? deliveryCountryID : apiData?.countryID : pickupCountryID?.trim() ? pickupCountryID : apiData?.countryID; // eslint-disable-line

        // otherCountryID =
        // keyName == "pickupPostalCode" ? deliveryCountryID : pickupCountryID; // eslint-disable-line
      otherAirportID =
        keyName == "pickupPostalCode" // eslint-disable-line
          ? deliveryDropAirportID
          : pickupDropAirportID;
    }

    if(_Code && postalCode != _Code) { // eslint-disable-line
      postalCode = _Code //send the latest postal code
    }
    const params = {
      postalCode: postalCode,
      countryID: countryID,
      airportID: airportID ? airportID : null,
      distanceUOM: "MI",
      otherPostalCode: otherPostalCode,
      otherairportID: otherAirportID ? otherAirportID : null,
      otherCountryID: otherCountryID,
      companyID: MAX_COMPANY_ID,
      type: type,
      userName: defaultData ? defaultData?.logonName : account?.displayName,
    };

    return params;
}
export const getCalculateTotalWeight = (formik: any, weight: any = 0, index: number = 0) => {
  const value = parseFloat(weight) || 0;
  const updatedPackageInfo = formik.values.packageInfo.map((info: any, i: number) => {
    if (i === index) {
      return { ...info, weight: value };
    }
    return info;
  });

  const totalWeight = updatedPackageInfo.reduce((acc: any, info: any) => acc + (parseFloat(info.weight) || 0), 0);

  return totalWeight > 0 ? totalWeight : '';
};

  export const getCalculateTotalDmWeight = (formik: any, dmWeigth: any = 0, index: number = 0) => {
    const value = parseFloat(dmWeigth) || 0;

    // Update the dm weight at the given index
    const updatedPackageInfo =  formik.values.packageInfo.map((info: any, i: number) => {
          if (i === index) {
            return { ...info, dimWt: value };
          }
          return info;
        });

    // Calculate the total DM weight
    const totalDMWeight = updatedPackageInfo.reduce(
      (acc: any, info: any) => acc + info.dimWt,
      0
    );
    
    return  totalDMWeight && totalDMWeight > 0 ? parseFloat(totalDMWeight) : '';

   
  };

export const calculateTotalAmount = (_data: RateEstimateChargeDTO[]) => {
  if(!_data || _data?.length === 0) {
    return 0;
  } 
  const totalWeight = _data.reduce(
      (acc: any, info: any) => acc + info.amount,
      0
    );
    
    return totalWeight ? totalWeight.toFixed(2) : totalWeight;
  }

  export const calculateTotalTaxAmount = (_data: RateEstimateChargeDTO[]) => {
    if(!_data || _data?.length === 0) {
      return 0;
    }
    const totalTax = _data.reduce(
      (acc: any, info: any) => acc + info.taxAmount,
      0
    );
    return totalTax ? totalTax.toFixed(2) : totalTax;
  }

  export const calculateTotalNetAmount = (_data: RateEstimateChargeDTO[]) => {
    if(!_data || _data?.length === 0) {
      return 0;
    }
    const totalNetAmount = _data.reduce(
      (acc: any, info: any) => acc + info.netAmount,
      0
    );
    return totalNetAmount ? totalNetAmount.toFixed(2) : totalNetAmount;
  }

// export  const sortAndUpdateTheData = (_data: any, order: string, orderBy: string) => {
//     return _data.slice().sort((a: any, b: any) => {
//       if (order === "asc") {
//         return a[orderBy] > b[orderBy] ? 1 : -1;
//       } else {
//         return a[orderBy] < b[orderBy] ? 1 : -1;
//       }
//     });
//   };

export const sortAndUpdateTheData = (_data: any, order: string, orderBy: string) => {
  return _data.slice().sort((a: any, b: any) => {
    let aValue = a[orderBy];
    let bValue = b[orderBy];

    // Handle boolean values (e.g., isDangerousGoods)
    if (typeof aValue === 'boolean' && typeof bValue === 'boolean') {
      return (aValue === bValue) ? 0 : (order === 'asc' ? (aValue ? 1 : -1) : (aValue ? -1 : 1));
    }

    // Convert to numbers if they are numeric strings
    if (!isNaN(aValue) && !isNaN(bValue)) {
      aValue = parseFloat(aValue);
      bValue = parseFloat(bValue);
    }

    if (aValue > bValue) {
      return order === 'asc' ? 1 : -1;
    }
    if (aValue < bValue) {
      return order === 'asc' ? -1 : 1;
    }
    return 0;
  });
};

const fieldsToCompare = [
  "pickupReadyTime",
  "totalWeight",
  "totalDMWeight",
  "pickupPostalCode",
  "pickupCountryID",
  "pickupAddressCode",
  "pickupStateProvID",
  "pickupAddress1",
  "pickupCityName",
  "pickupDropAirportID",
  "pickupTimezoneID",
  "deliveryPostalCode",
  "deliveryCountryID",
  "deliveryStateProvID",
  "deliveryAddressCode",
  "deliveryDropAirportID",
  "deliveryTimezoneID",
  "requestedDeliveryTime",
  "deliveryAddress1"

];

export const checkIfValueIsUpdatedAfterCalculate = (oldValues: any, newValues: any) => {
 
  
  const isTopLevelChanged = fieldsToCompare.some(
    (field) => oldValues[field] !== newValues[field]
  );

  if (isTopLevelChanged) return true;

  // Check commodity in packageInfo array
  const oldPackages = oldValues.packageInfo || [];
  const newPackages = newValues.packageInfo || [];

  // Ensure both arrays have the same length
  if (oldPackages.length !== newPackages.length) return true;

  // Compare commodity field in each package
  const isCommodityChanged = oldPackages.some((oldPackage: any, index: number) => {
    const newPackage = newPackages[index];
    return oldPackage.commodity !== newPackage?.commodity;
  });

  return isCommodityChanged;
}