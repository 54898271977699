import { Dispatch } from "redux"; // Import Dispatch type
import axiosInstance from "../../config/axios-interceptor";
import { API_BASE_URL } from "../../config/constants";
import { ActiveOrderItemDto } from "../../models/active-order-item-dto";

// Action Types for Track
const WILL_CALL_REQUEST = "WILL_CALL_REQUEST";
const WILL_CALL_SUCCESS = "WILL_CALL_SUCCESS";
const WILL_CALL_FAILURE = "WILL_CALL_FAILURE";

// Action Creators for track
export const willCallRequest = () => ({
  type: WILL_CALL_REQUEST,
});

export const willCallSuccess = (data: ActiveOrderItemDto[]) => ({
  type: WILL_CALL_SUCCESS,
});

export const willCallFailure = (error: string) => ({
  type: WILL_CALL_FAILURE,
  payload: error,
});

// Initial State for Track
export interface willcallState {
  isAuthenticated: boolean;
  willCallLoading: boolean;
  willCallError: string | null;
}

const initialAuthState: willcallState = {
  isAuthenticated: false,
  willCallLoading: false,
  willCallError: null,
};

// Define the interface for the API response
interface WillCallResponse {
  success: boolean;
  message: string;
  data: ActiveOrderItemDto[];
  statusCode: string;
}

// Reducer for Authentication
const willCallReducer = (
  state = initialAuthState,
  action: any
): willcallState => {
  switch (action.type) {
    case WILL_CALL_REQUEST:
      return { ...state, willCallLoading: true, willCallError: null };
    case WILL_CALL_SUCCESS:
      return { ...state, willCallLoading: false, isAuthenticated: true };
    case WILL_CALL_FAILURE:
      return {
        ...state,
        willCallLoading: false,
        willCallError: action.payload,
      };
    default:
      return state;
  }
};

// Thunk for Active Order
export const willCall =
  (param: { shipmentguid: string }) => async (dispatch: Dispatch) => {
    dispatch(willCallRequest());
    try {
      const response = await axiosInstance.post(
        `${API_BASE_URL}/api/Lookup/GetTimezonefromZipCode`,
        param
      );
      if (response.status === 200) {
        const data: WillCallResponse = response.data;
        if (data.success) {
          dispatch(willCallSuccess(data.data));
          return { data: data.data };
        } else {
          dispatch(willCallFailure(data.message));
          return { error: data.message };
        }
      } else {
        dispatch(willCallFailure("Unexpected response status"));
        return { error: "Unexpected response status" };
      }
    } catch (error: any) {
      const errorMessage = error.response?.data?.message || error.message;
      dispatch(willCallFailure(errorMessage));
      return { error: errorMessage };
    }
  };

export default willCallReducer;
