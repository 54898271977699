import { Box, Alert, Typography } from "@mui/material";

const DisplayError = (props: any) => {
  const { formik } = props;
  const displayErrors = () => {
    const errors = formik.errors;
    const touched = formik.touched;
    const errorMessages: string[] = [];

    if (errors.companyName && touched.companyName) {
      errorMessages.push(errors.companyName);
    }
    if (errors.name && touched.name) {
      errorMessages.push(errors.name);
    }
    if (errors.emailAddress && touched.emailAddress) {
      errorMessages.push(errors.emailAddress);
    }
    if (errors.phoneNumber && touched.phoneNumber) {
      errorMessages.push(errors.phoneNumber);
    }
    if (errors.readyTime && touched.readyTime) {
      errorMessages.push(errors.readyTime);
    }

    if (errors.donorId && touched.donorId) {
      errorMessages.push(errors.donorId);
    }
    if (errors.originAddress && touched.originAddress) {
      errorMessages.push(errors.originAddress);
    }
    if (errors.timeOfDeath && touched.timeOfDeath) {
      errorMessages.push(errors.timeOfDeath);
    }

    if (errors.giftInfo && Array.isArray(errors.giftInfo)) {
      errors.giftInfo.forEach((itemError: any, index: any) => {
        if (itemError && typeof itemError === "object") {
          Object.keys(itemError).forEach((key) => {
            if (
              touched.giftInfo &&
              touched.giftInfo[index] &&
              touched.giftInfo[index][key as keyof typeof itemError]
            ) {
              errorMessages.push(
                `Item ${index + 1}: ${itemError[key as keyof typeof itemError]}`
              );
            }
          });
        }
      });

      if (errors.giftInfo && typeof errors.giftInfo === "string") {
        errorMessages.push(errors.giftInfo);
      }
    }

    return errorMessages.map((error, index) => (
      <Typography key={index}>{error}</Typography>
    ));
  };

  const isEmptyObject = (obj: any) => {
    return Object.keys(obj).length === 0;
  };

  return (
    <>
      {!isEmptyObject(displayErrors()) && (
        <Box className="w-100 mt-24 mb-24">
          <Alert variant="filled" severity="error" id="txtDeError">
            {displayErrors()}
          </Alert>
        </Box>
      )}
    </>
  );
};

export default DisplayError;
