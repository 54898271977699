import {
  Box,
  Button,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import useCommodityLogic from "../../lookup/commodity/commodity.logic";
import {
  ContentItemDto,
  PackageTypeItemDto,
} from "../../../models/long-form-item-dto";
import usePackageTypeLogic from "../../lookup/package-type/package-type.logic";
import useContentLogic from "../../lookup/contents/contents.logic";
import { MAX_COMPANY_ID } from "../../../config/constants";
import useCurrencyLogic from "../../lookup/currency/currency.logic";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import { red } from "@mui/material/colors";
import {
  handleDecimalValue,
  handleNumericValue,
} from "../../util/numeric-value";

import useGetShipmentPieceLogic from "../../lookup/shipment-piece/get-shipment-piece/get-shipment-piece.logic";
import { ShipmentPieceDto } from "../../../models/shipment-piece-dto";
import EditPiecesDialog from "../edit-active-order/EditPiecesDialog";
import { EditWillCallRequestDTO } from "../../../models/edit-will-call-order-request-item-dto";

import moment from "moment";

interface PackageInfoProps {
  shipmentGUID: string;
  formErrors: any;
  setFormErrors: (errors: any) => void;
  updateEditOrderData: EditWillCallRequestDTO;
  isAlert: boolean;
  handleInputChange: (updatedData: any) => void;
  setEditablePiecesData: React.Dispatch<
    React.SetStateAction<ShipmentPieceDto[]>
  >;
  setAllPiecesData: React.Dispatch<React.SetStateAction<ShipmentPieceDto[]>>;
  selectedContent: any;
  onContentChange: (content: ContentItemDto | null) => void;
  mySettingsData: any;
}

const PackageInfo: React.FC<PackageInfoProps> = ({
  shipmentGUID,
  formErrors,
  setFormErrors,
  updateEditOrderData,
  isAlert,
  handleInputChange,
  setEditablePiecesData,
  setAllPiecesData,
  selectedContent,
  onContentChange,
  mySettingsData,
}) => {
  const { accountNumber } = updateEditOrderData;
  const [commodities, setCommodities] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const { commodityError, commodity } = useCommodityLogic();

  const {
    packageTypeError,
    packageType,
    packageTypeDependancy,
    calculateDimWtAPI,
  } = usePackageTypeLogic();
  const { contentError, content } = useContentLogic();
  const [packageTypes, setPackageTypes] = useState<PackageTypeItemDto[]>([]);
  const [contents, setContents] = useState<ContentItemDto[]>([]);
  const [open, setOpen] = useState(false);
  const { currencyError, currency } = useCurrencyLogic();
  const [piecesData, setPiecesData] = useState<ShipmentPieceDto[]>([]);
  const { getShipmentPiece } = useGetShipmentPieceLogic();
  const [packageTypeDependancyCustID, setPackageTypeDependancyCustID] =
    useState<any>(null);
  const [currencies, setCurrencies] = useState<
    {
      currencyID: string;
      shortName: string;
      name: string;
      description: string;
      symbolText: string;
      symbolImage: string;
    }[]
  >([]);

  useEffect(() => {
    getDimDependacyWt();
  }, [
    updateEditOrderData.puReadyTime,
    updateEditOrderData.puReadyNow,
    updateEditOrderData.puCountryID,
    updateEditOrderData.delCountryID,
    updateEditOrderData.serviceID,
    updateEditOrderData.accountNumber,
    packageTypeDependancyCustID,
  ]);

  const getDimDependacyWt = async () => {
    const updatedItems = [...piecesData!];

    for (let i = 0; i < updatedItems.length; i++) {
      const item = updatedItems[i];
      const puReadtDate = updateEditOrderData.puReadyNow
        ? moment().format("YYYY-MM-DDTHH:mm:ss.SSS")
        : updateEditOrderData.puReadyTime
        ? moment(updateEditOrderData.puReadyTime).format(
            "YYYY-MM-DDTHH:mm:ss.SSS"
          )
        : moment().format("YYYY-MM-DDTHH:mm:ss.SSS");
      const params = {
        puReadyDateTime: puReadtDate,
        serviceID: updateEditOrderData?.serviceID || 0,
        origCountryID: updateEditOrderData?.puCountryID || "",
        destCountryID: updateEditOrderData?.delCountryID || "",
        length: item?.length || 0,
        width: item?.width || 0,
        height: item?.height || 0,
        sizeUOM: updateEditOrderData?.sizeUOM || "IN",
        weightUOM: item?.weightUOM || "",
        qunatity: item?.quantity || 1,
        customerID: packageTypeDependancyCustID?.apiCustomerID || 0,
      };

      try {
        // Call the API
        const response = await calculateDimWtAPI(params);
        if (response.data && response.data.data) {
          updatedItems[i].dimWeight = response.data.data;
        }
      } catch (error) {}

      setAllPiecesData(updatedItems);
    }
  };

  const getDimWt = async (
    paramsData: any,
    updateItem: any,
    updateItemIndex: number,
    updateDirectState: boolean = false
  ) => {
    try {
      setLoading(true);
      const response = await calculateDimWtAPI(paramsData);

      if (response.data && response.data) {
        if (updateDirectState) {
          setPiecesData((prevPiecesData) => {
            const updatedData = [...prevPiecesData];
            updatedData[updateItemIndex] = {
              ...updatedData[updateItemIndex],
              ...updateItem,
              dimWeight: response.data.data,
              isEdit: true,
            };
            return updatedData;
          });
        }
        return (updateItem[updateItemIndex].dimWeight = response.data.data);
      }
    } catch (err) {
    } finally {
      setLoading(false); // Ensure loading is always false
    }
  };
  const getCommodity = useCallback(async () => {
    try {
      setLoading(true);
      const param = {
        companyID: MAX_COMPANY_ID,
      };

      const response = await commodity();
      if (response.data && response.data.data) {
        setCommodities(response.data.data);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const param = { accountNumber };

        const response = await packageTypeDependancy(param as any);
        if (response.data && response.data.data) {
          // Handle the response data
          const allPackagingAvailable =
            response.data.data?.allPackagingAvailable;
          const customerID = allPackagingAvailable
            ? 0
            : response.data.data?.customerID;
          setPackageTypeDependancyCustID({
            customerID: customerID,
            apiCustomerID: response.data.data?.customerID,
          });
          getPackageType(customerID);
        }
      } catch (err) {
        // Handle errors
      } finally {
        setLoading(false);
      }
    };

    if (accountNumber) {
      // Ensure the effect runs only when accountNumber is valid
      fetchData();
    }
  }, [accountNumber]);

  const getPackageType = useCallback(async (customerID: number) => {
    try {
      setLoading(true);
      const param = {
        companyID: MAX_COMPANY_ID,
        showDeleted: false,
        customerID: customerID,
        includeBlank: true,
      };

      const response = await packageType(param);
      if (response.data && response.data.data) {
        setPackageTypes(response.data.data);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  }, []);

  const getContents = useCallback(async () => {
    try {
      setLoading(true);
      const param = {
        companyID: MAX_COMPANY_ID,
        contentsReferenceGroupGUID: "",
      };

      const response = await content(param);
      if (response.data && response.data.data) {
        setContents(response.data.data);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    getCommodity();

    getContents();
  }, [getCommodity, getContents]);

  useEffect(() => {
    getCurrency();
    getShipmentPieceList();
  }, []);

  const getShipmentPieceList = async () => {
    try {
      const param = {
        shipmentGUID: shipmentGUID,
      };
      const response = await getShipmentPiece(param);
      if (response.data && response.data.data.length > 0) {
        const updatedPiecesData = response.data.data.map((piece: any) => ({
          ...piece,
          height: piece.height === 0 ? 0 : piece.height, // Update height to null if it is 0
          weight: piece.weight === 0 ? 0 : piece.weight, // Update weight to null if it is 0
          width: piece.weight === 0 ? 0 : piece.width, // Update weight to null if it is 0
          length: piece.length === 0 ? 0 : piece.length, // Update weight to null if it is 0
          declaredValue: piece.weight === 0 ? 0 : piece.declaredValue, // Update weight to null if it is 0
          isEdit: false, // Set isEdit flag to false by default
          commodity: "",
        }));
        updatedPiecesData[0].commodity = updateEditOrderData.commodity;
        if (
          piecesData &&
          piecesData.length === 1 &&
          !piecesData[0]?.shipmentPieceID
        ) {
          //merge default piece on add/edit new piece
          const mergePieces = [...piecesData, ...updatedPiecesData];
          setPiecesData(mergePieces);
        } else {
          setPiecesData(updatedPiecesData);
        }
      } else {
        let defaultPiece = [
          {
            contents: updateEditOrderData?.contents || "",
            weight: updateEditOrderData?.weight,
            // dimWeight: calculateDimWeight(
            //   "US",
            //   "US",
            //   updateEditOrderData?.pieces || 1,
            //   updateEditOrderData?.length || 0,
            //   updateEditOrderData?.width || 0,
            //   updateEditOrderData?.height || 0,
            //   "IN",
            //   updateEditOrderData?.weightUOM
            // ),
            weightUOM: updateEditOrderData?.weightUOM,
            length: updateEditOrderData?.length || 0,
            width: updateEditOrderData?.width || 0,
            height: updateEditOrderData?.height || 0,
            sizeUOM: updateEditOrderData?.sizeUOM,
            commodity: updateEditOrderData?.commodity,
            declaredValue: updateEditOrderData?.declaredValue || 0.0,
            declaredValueCurrencyID:
              updateEditOrderData?.declaredValueCurrencyID,
            quantity: updateEditOrderData?.quantity || 1,
            isEdit: true,
          },
        ];
        setPiecesData(defaultPiece as any);
        const puReadtDate = updateEditOrderData.puReadyNow
          ? moment().format("YYYY-MM-DDTHH:mm:ss.SSS")
          : updateEditOrderData.puReadyTime
          ? moment(updateEditOrderData.puReadyTime).format(
              "YYYY-MM-DDTHH:mm:ss.SSS"
            )
          : moment().format("YYYY-MM-DDTHH:mm:ss.SSS");
        const params = {
          puReadyDateTime: puReadtDate,
          serviceID: updateEditOrderData?.serviceID,
          origCountryID: updateEditOrderData?.puCountryID,
          destCountryID: updateEditOrderData?.delCountryID,
          length: updateEditOrderData?.length || 0,
          width: updateEditOrderData?.width || 0,
          height: updateEditOrderData?.height || 0,
          sizeUOM: updateEditOrderData?.sizeUOM || "IN",
          weightUOM: updateEditOrderData?.weightUOM,
          qunatity: updateEditOrderData?.quantity || 1,
          customerID: packageTypeDependancyCustID?.apiCustomerID || 0,
        };
        getDimWt(params, defaultPiece, 0, true);
      }
    } catch (err) {}
  };
  useEffect(() => {
    setAllPiecesData(piecesData);
    const editablePiecesData = piecesData.filter((piece) => piece.isEdit);
    setEditablePiecesData(editablePiecesData);
  }, [piecesData]);

  useEffect(() => {
    if (selectedContent && piecesData) {
      setPiecesData((prevState: any) => {
        const updatedArray = [...prevState]; // Create a shallow copy of the array
        updatedArray[0] = {
          ...updatedArray[0], // Spread the existing object at index 0
          isDangerousGoods: selectedContent?.isDangerousGoods, // Update values
          isRadioactive: selectedContent?.isRadioactive,
        };

        return updatedArray; // Return the updated array
      });
    }
  }, [selectedContent]);
  const getCurrency = async () => {
    try {
      const response = await currency();
      if (response.data && response.data.data) {
        setCurrencies(response.data.data);
      }
    } catch (err) {}
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  interface MenuStyles {
    maxHeight: string;
    maxWidth: string;
  }

  const [menuStyles, setMenuStyles] = useState<MenuStyles>({
    maxHeight: "200px", // Default max height for desktop
    maxWidth: "48%",
  });

  const isXs = useMediaQuery("(max-width:600px)"); // Adjust the breakpoint as needed

  useEffect(() => {
    if (isXs) {
      setMenuStyles((prevStyles) => ({
        ...prevStyles,
        maxWidth: "80%",
      }));
    } else {
      setMenuStyles((prevStyles) => ({
        ...prevStyles,
        maxWidth: "48%",
      }));
    }
  }, [isXs]);

  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    // Creating an HTMLInputElement-like event object
    const { name, value } = event.target;
    const syntheticEvent = {
      target: {
        name: name,
        value: value,
      },
    } as ChangeEvent<HTMLInputElement>;
    handleInputChange(syntheticEvent);
  };

  const handlePiecesInputChange = (e: any, index: number) => {
    const { name, value } = e.target;
    // Create a copy of piecesData
    const updatedPieceDetails = [...piecesData!];
    // Handle specific logic for "count"
    if (name === "quantity" && parseFloat(value) < 1) {
      return;
    }
    if (name === "weight") {
      const errorKey = `allPiecesData[${index}].${name}`;
      // Example logic to check and delete error from formErrors
      if (formErrors[errorKey]) {
        setFormErrors((prevErrors: any) => {
          const updatedErrors = { ...prevErrors };
          delete updatedErrors[errorKey];
          return updatedErrors;
        });
      }
    }
    // Handle specific logic for "packagingTypeGUID_Outer"
    if (name === "contents") {
      const selectedPackage = contents.find((pkg) => pkg.properName === value);
      if (selectedPackage) {
        updatedPieceDetails[index] = {
          ...updatedPieceDetails[index],
          dgUNNumber: selectedPackage.unNumber,
          dgCommodityClass: selectedPackage.commodityClass,
          isDangerousGoods: selectedPackage.isDangerousGoods,
          isRadioactive: selectedPackage.isRadioactive,
        };
        onContentChange(selectedPackage);
      }
    }

    updatedPieceDetails[index] = {
      ...updatedPieceDetails[index],
      [name]: value,
    };
    const pieceCount = updatedPieceDetails[index].quantity;
    const length = updatedPieceDetails[index].length || 0;
    const width = updatedPieceDetails[index].width || 0;
    const height = updatedPieceDetails[index].height || 0;
    const weightUOM = updatedPieceDetails[index].weightUOM || "";

    const puReadtDate = updateEditOrderData.puReadyNow
      ? moment().format("YYYY-MM-DDTHH:mm:ss.SSS")
      : updateEditOrderData.puReadyTime
      ? moment(updateEditOrderData.puReadyTime).format(
          "YYYY-MM-DDTHH:mm:ss.SSS"
        )
      : moment().format("YYYY-MM-DDTHH:mm:ss.SSS");
    const params = {
      puReadyDateTime: puReadtDate,
      serviceID: updateEditOrderData?.serviceID,
      origCountryID: updateEditOrderData?.puCountryID,
      destCountryID: updateEditOrderData?.delCountryID,
      length: length || 0,
      width: width || 0,
      height: height || 0,
      sizeUOM: updateEditOrderData?.sizeUOM || "IN",
      weightUOM: weightUOM,
      qunatity: pieceCount || 1,

      customerID: packageTypeDependancyCustID?.apiCustomerID || 0,
    };
    getDimWt(params, updatedPieceDetails, index);

    // Update the general field value
    updatedPieceDetails[index] = {
      ...updatedPieceDetails[index],
      [name]: value,
      isEdit: true, // Mark as e
    };
    // Update state or handle the updated data
    setPiecesData(updatedPieceDetails);
  };

  const handlePiecesSelectChange = (e: any, index: any) => {
    const { name, value } = e.target;
    const syntheticEvent = {
      target: {
        name: name,
        value: value,
      },
    } as ChangeEvent<HTMLInputElement>;
    handlePiecesInputChange(syntheticEvent, index);
  };

  const handlePackagesSelectChanges = (e: any, index: number) => {
    const { name, value } = e.target;

    const selectedPackage = packageTypes.find(
      (pkg) => pkg.packagingTypeGUID === value
    );

    if (selectedPackage) {
      const updatedPieceDetails = {
        length: selectedPackage.length || "",
        width: selectedPackage.width || "",
        height: selectedPackage.height || "",
        packagingTypeGUID_Outer: value,
      };

      // const dimWeight = calculateDimWeight(
      //   "US",
      //   "US",
      //   1,
      //   selectedPackage.length ?? 0,
      //   selectedPackage.width ?? 0,
      //   selectedPackage.height ?? 0,
      //   "IN",
      //   selectedPackage.weightUOM || "LB"
      // );

      const puReadtDate = updateEditOrderData.puReadyNow
        ? moment().format("YYYY-MM-DDTHH:mm:ss.SSS")
        : updateEditOrderData.puReadyTime
        ? moment(updateEditOrderData.puReadyTime).format(
            "YYYY-MM-DDTHH:mm:ss.SSS"
          )
        : moment().format("YYYY-MM-DDTHH:mm:ss.SSS");
      const params = {
        puReadyDateTime: puReadtDate,
        serviceID: 0,
        origCountryID: updateEditOrderData?.puCountryID,
        destCountryID: updateEditOrderData?.delCountryID,
        length: updateEditOrderData?.length || 0,
        width: updateEditOrderData?.width || 0,
        height: updateEditOrderData?.height || 0,
        sizeUOM: updateEditOrderData?.sizeUOM || "IN",
        weightUOM: updateEditOrderData?.weightUOM,
        qunatity: updateEditOrderData?.pieces || 1,
        customerID: packageTypeDependancyCustID?.apiCustomerID || 0,
      };
      getDimWt(params, updatedPieceDetails, index, true);

      //from getDimwt state will be updated
    }
  };
  const measurementUnit = updateEditOrderData?.sizeUOM?.toLowerCase() || "in";
  return (
    <Box className="d-flex flex-column w-100 border rounded">
      <Typography variant="h6" className="Sub-header p16">
        Package Information
      </Typography>
      {piecesData.map((piece, index) => (
        <Box className={`${index >= 1 ? "bcg-grey p16" : "p16"}`}>
          <Box key={piece.shipmentPieceID} className=" ">
            <Typography variant="body1" className="pb-8 piece-info">
              Piece {index + 1}
            </Typography>
            <Box className="d-flex gap-2 Divider pb-16 mb-16">
              <Grid container spacing={2}>
                <Grid item lg={3} md={3} sm={3} xs={12}>
                  <TextField
                    type="number"
                    variant="outlined"
                    label="Quantity"
                    id="txtQuantity"
                    className="w-100"
                    name="quantity"
                    defaultValue={piece.quantity}
                    onChange={(e) => handlePiecesInputChange(e, index)}
                    onKeyDown={handleNumericValue}
                    value={piece.quantity}
                    inputProps={{ min: 1, maxLength: 4 }}
                    size="small"
                    InputProps={{
                      readOnly: isAlert,
                    }}
                  />
                </Grid>
                <Grid item lg={3} md={3} sm={3} xs={9}>
                  <FormControl className="w-100" size="small">
                    <InputLabel>Commodity</InputLabel>
                    <Select
                      variant="outlined"
                      defaultValue=""
                      className="w-100"
                      id="ddCommodity"
                      label="Commodity"
                      name="commodity"
                      onChange={(e) => handlePiecesInputChange(e, index)}
                      value={piece.commodity}
                      error={!!formErrors.commodity}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left",
                        },

                        PaperProps: {
                          style: menuStyles,
                        },
                      }}
                    >
                      {commodities.length > 0 &&
                        commodities.map((item: any) => (
                          <MenuItem
                            key={item.commodityGuid}
                            value={item.commodityName}
                          >
                            {item.commodityName}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item lg={3} md={3} sm={3} xs={6}>
                  <TextField
                    variant="outlined"
                    size="small"
                    label="Weight"
                    className="w-100"
                    name="weight"
                    id="txtWeight"
                    defaultValue={piece.weight}
                    value={piece.weight}
                    onKeyDown={handleDecimalValue}
                    onChange={(e) => handlePiecesInputChange(e, index)}
                    error={!!formErrors[`allPiecesData[${index}].weight`]}
                    InputProps={{
                      endAdornment: !!formErrors[
                        `allPiecesData[${index}].weight`
                      ] && (
                        <InputAdornment
                          position="end"
                          disablePointerEvents={true}
                        >
                          <InfoRoundedIcon
                            style={{ color: red[500] }}
                            fontSize="small"
                          ></InfoRoundedIcon>
                        </InputAdornment>
                      ),
                      readOnly: isAlert,
                    }}
                    inputProps={{ min: 0, maxLength: 4 }}
                  />
                </Grid>
                <Grid item lg={3} md={3} sm={3} xs={3}>
                  <FormControl className="w-100" size="small">
                    <InputLabel>lbs</InputLabel>
                    <Select
                      variant="outlined"
                      className="w-100"
                      label="Unit"
                      id="ddWeightUOM"
                      name="weightUOM"
                      value={piece.weightUOM || ""}
                      onChange={(e) => handlePiecesInputChange(e, index)}
                      defaultValue={piece.weightUOM}
                      disabled={isAlert}
                    >
                      <MenuItem value="LB">LB</MenuItem>
                      <MenuItem value="KG">KG</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Box>

            <Typography variant="body1" className="pt-16 piece-info pb-8">
              Dimensions
            </Typography>
            <Box className="d-flex gap-2 mt-2 Divider pb-16">
              <Grid container spacing={2}>
                <Grid item lg={3} md={3} sm={3} xs={6}>
                  <TextField
                    variant="outlined"
                    id="txtLength"
                    label={`Length (${measurementUnit})`}
                    className="w-100"
                    name="length"
                    value={piece.length || ""}
                    onChange={(e) => handlePiecesInputChange(e, index)}
                    onKeyDown={handleNumericValue}
                    inputProps={{ min: 0, maxLength: 4 }}
                  />
                </Grid>
                <Grid item lg={3} md={3} sm={3} xs={6}>
                  <TextField
                    variant="outlined"
                    label={`Width (${measurementUnit})`}
                    id="txtWidth"
                    className="w-100"
                    name="width"
                    value={piece.width || ""}
                    onChange={(e) => handlePiecesInputChange(e, index)}
                    onKeyDown={handleNumericValue}
                    inputProps={{ min: 0, maxLength: 4 }}
                  />
                </Grid>
                <Grid item lg={3} md={3} sm={3} xs={6}>
                  <TextField
                    variant="outlined"
                    label={`Height (${measurementUnit})`}
                    id="txtHeight"
                    className="w-100"
                    name="height"
                    value={piece.height || ""}
                    onChange={(e) => handlePiecesInputChange(e, index)}
                    onKeyDown={handleNumericValue}
                    inputProps={{ min: 0, maxLength: 4 }}
                  />
                </Grid>
                <Grid item lg={3} md={3} sm={3} xs={6}>
                  <TextField
                    variant="outlined"
                    label="Dim. Weight (lbs)"
                    id="txtDimWeight"
                    className="w-100"
                    name="dimWeight"
                    disabled
                    value={piece.dimWeight}
                    InputLabelProps={{
                      shrink: true, // Ensures the label moves to the top
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
            <Typography variant="body1" className="pt-16 piece-info pb-8">
              Contents & Value
            </Typography>
            <Box className="d-flex gap-2">
              <Grid container spacing={2}>
                <Grid item lg={4} sm={4} md={4} xs={6}>
                  <FormControl className="w-100">
                    <InputLabel>Packaging</InputLabel>
                    <Select
                      variant="outlined"
                      defaultValue=""
                      className="w-100"
                      id="ddOuterPackage"
                      label="Packaging"
                      name="packagingTypeGUID_Outer"
                      value={piece.packagingTypeGUID_Outer}
                      onChange={(e) => handlePackagesSelectChanges(e, index)}
                      renderValue={(selected) => {
                        const selectedType = packageTypes.find(
                          (pkg) => pkg.packagingTypeGUID === selected
                        );
                        return selectedType ? selectedType.name : "";
                      }}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left",
                        },

                        PaperProps: {
                          style: menuStyles,
                        },
                      }}
                    >
                      <MenuItem disabled className="dropdown-grid Table-head">
                        <TableHead>
                          <TableRow className="table-fixed">
                            <TableCell className="weight-500 font14 width104 thead-cell">
                              Name
                            </TableCell>
                            <TableCell className="weight-500 font14 width200 thead-cell">
                              Description
                            </TableCell>
                            <TableCell className="weight-500 font14 width180 thead-cell">
                              Dimensions
                            </TableCell>
                            <TableCell className="weight-500 font14 width94p thead-cell">
                              Wt
                            </TableCell>
                          </TableRow>
                        </TableHead>
                      </MenuItem>
                      {packageTypes.map(
                        (row: PackageTypeItemDto, index: any) => (
                          <MenuItem
                            className="dropdown-grid"
                            key={row.packagingTypeGUID}
                            value={row.packagingTypeGUID}
                          >
                            <TableBody className="table-fixed ptb-8">
                              <TableCell className="width104 Info-wrap">
                                {row.name}
                              </TableCell>
                              <TableCell className="width200 Info-wrap">
                                {row.description}
                              </TableCell>
                              <TableCell className="width180 Info-wrap">
                                {row.length && row.width && row.height
                                  ? `${row.length} X ${row.width} X ${row.height} ${row.sizeUOM}`
                                  : ""}
                              </TableCell>
                              <TableCell className="width94p Info-wrap">
                                {row.weight && row.weightUOM
                                  ? `${row.weight} ${row.weightUOM}`
                                  : ""}
                              </TableCell>
                            </TableBody>
                          </MenuItem>
                        )
                      )}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item lg={4} sm={4} md={4} xs={6}>
                  <FormControl className="w-100">
                    <InputLabel>Inner</InputLabel>
                    <Select
                      variant="outlined"
                      id="ddInnerPackage"
                      defaultValue=""
                      className="w-100"
                      label="Inner"
                      name="packagingTypeGUID_Inner"
                      value={piece.packagingTypeGUID_Inner}
                      onChange={(e) => handlePiecesSelectChange(e, index)}
                      renderValue={(selected) => {
                        const selectedType = packageTypes.find(
                          (pkg) => pkg.packagingTypeGUID === selected
                        );
                        return selectedType ? selectedType.name : "";
                      }}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left",
                        },

                        PaperProps: {
                          style: menuStyles,
                        },
                      }}
                    >
                      <MenuItem disabled className="dropdown-grid Table-head">
                        <TableHead>
                          <TableRow className="table-fixed">
                            <TableCell className="weight-500 font14 width104 thead-cell">
                              Name
                            </TableCell>
                            <TableCell className="weight-500 font14 width200 thead-cell">
                              Description
                            </TableCell>
                            <TableCell className="weight-500 font14 width180 thead-cell">
                              Dimensions
                            </TableCell>
                            <TableCell className="weight-500 font14 width94p thead-cell">
                              Wt
                            </TableCell>
                          </TableRow>
                        </TableHead>
                      </MenuItem>
                      {packageTypes.map(
                        (row: PackageTypeItemDto, index: any) => (
                          <MenuItem
                            className="dropdown-grid"
                            key={index}
                            value={row.packagingTypeGUID}
                          >
                            <TableBody className="table-fixed ptb-8">
                              <TableCell className="width104 Info-wrap">
                                {row.name}
                              </TableCell>
                              <TableCell className="width200 Info-wrap">
                                {row.description}
                              </TableCell>
                              <TableCell className="width180 Info-wrap">
                                {row.length && row.width && row.height
                                  ? `${row.length} X ${row.width} X ${row.height} ${row.sizeUOM}`
                                  : ""}
                              </TableCell>
                              <TableCell className="width94p Info-wrap">
                                {row.weight && row.weightUOM
                                  ? `${row.weight} ${row.weightUOM}`
                                  : ""}
                              </TableCell>
                            </TableBody>
                          </MenuItem>
                        )
                      )}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item lg={4} sm={4} md={4} xs={12}>
                  <FormControl className="w-100">
                    <InputLabel>Contents</InputLabel>
                    <Select
                      variant="outlined"
                      className="w-100"
                      id="ddContent"
                      label="Contents"
                      name="contents"
                      inputProps={{ maxLength: 100 }}
                      value={piece.contents}
                      onChange={(e) => handlePiecesSelectChange(e, index)}
                      renderValue={(selected) => selected}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "right",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "right",
                        },

                        PaperProps: {
                          style: menuStyles,
                        },
                      }}
                    >
                      {contents.length > 0 && (
                        <MenuItem disabled className="dropdown-grid Table-head">
                          <TableHead>
                            <TableRow className="table-fixed">
                              <TableCell className="weight-500 font14 width94p thead-cell">
                                UN #
                              </TableCell>
                              <TableCell className="weight-500 font14 width230 thead-cell">
                                Proper Name
                              </TableCell>
                              <TableCell className="weight-500 font14 width110 thead-cell">
                                Class
                              </TableCell>
                              <TableCell className="weight-500 font14 width94p thead-cell">
                                DG
                              </TableCell>
                              <TableCell className="weight-500 font14 width94p thead-cell">
                                RA
                              </TableCell>
                            </TableRow>
                          </TableHead>
                        </MenuItem>
                      )}
                      {contents.length > 0 ? (
                        contents.map((row: ContentItemDto, index: any) => (
                          <MenuItem
                            className="dropdown-grid"
                            key={index}
                            value={row.properName}
                          >
                            <TableBody className="table-fixed ptb-8">
                              <TableCell className="width94p Info-wrap">
                                {row.unNumber}
                              </TableCell>
                              <TableCell className="width230 Info-wrap">
                                {row.properName}
                              </TableCell>
                              <TableCell className="width110 Info-wrap">
                                {row.commodityClass}
                              </TableCell>
                              <TableCell className="width94p Info-wrap">
                                {row.isDangerousGoods ? "True" : "False"}
                              </TableCell>
                              <TableCell className="width94p Info-wrap">
                                {row.isRadioactive ? "True" : "False"}
                              </TableCell>
                            </TableBody>
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem disabled>No options available</MenuItem>
                      )}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item lg={4} md={4} sm={4} xs={8}>
                  <Grid container spacing={1}>
                    <Grid item lg={4} md={4} sm={6} xs={6}>
                      <FormControl className="w-100">
                        <InputLabel>Currency</InputLabel>
                        <Select
                          variant="outlined"
                          defaultValue=""
                          className="w-100"
                          id="ddCurrency"
                          label="Contents"
                          name="declaredValueCurrencyID"
                          value={piece.declaredValueCurrencyID}
                          onChange={(e) => handlePiecesSelectChange(e, index)}
                          MenuProps={{
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left",
                            },
                            transformOrigin: {
                              vertical: "top",
                              horizontal: "left",
                            },

                            PaperProps: {
                              style: menuStyles,
                            },
                          }}
                        >
                          {currencies.length > 0 &&
                            currencies.map((item) => (
                              <MenuItem
                                key={item.currencyID}
                                value={item.currencyID}
                              >
                                {item.shortName}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item lg={8} sm={6} md={8} xs={6}>
                      <TextField
                        variant="outlined"
                        id="txtValue"
                        label="Value"
                        className="w-100"
                        name="declaredValue"
                        defaultValue={
                          piece.declaredValue ? piece.declaredValue : "" || ""
                        }
                        onKeyDown={handleDecimalValue}
                        onChange={(e) => handlePiecesInputChange(e, index)}
                        inputProps={{ maxLength: 8 }}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item lg={8} md={8} sm={8} xs={12}>
                  {
                    <Box
                      className="d-flex justify-content-end w-100"
                      sx={{ height: "56px" }}
                    >
                      <Button
                        variant="outlined"
                        id="btnAddEdit"
                        className="width100px rounded add-edit_btn"
                        onClick={handleClickOpen}
                      >
                        ADD/EDIT PIECE
                      </Button>
                    </Box>
                  }
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      ))}

      <EditPiecesDialog
        open={open}
        handleClose={handleClose}
        shipmentGUID={shipmentGUID}
        setPiecesData={setPiecesData}
        piecesData={piecesData}
        getShipmentPieceList={getShipmentPieceList}
        accessModule="editOrder"
        packageTypeDependancyCustID={packageTypeDependancyCustID}
        updateEditOrderData={updateEditOrderData}
      />
    </Box>
  );
};

export default PackageInfo;
