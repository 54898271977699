import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../types/global";
import { HealthCareDonorRequestDto } from "../../models/healthcare-donor-item-dto";
import { HealthCareDonor } from "./healthcare-donor.reducer";

const useHealthCareDonorLogic = () => {
  const dispatch = useDispatch();
  const { HealthCareDonorLoading, HealthCareDonorError } = useSelector(
    (state: RootState) => state.healthCareDonor
  );

  useEffect(() => {
    if (HealthCareDonorError) {
      // dispatch(showNotification('Active Order failed: ' + error, 'error'));
    }
  }, [HealthCareDonorError, dispatch]);

  const handleHealthCareDonor = async (param: HealthCareDonorRequestDto) => {
    try {
      const response = await dispatch(HealthCareDonor(param) as any);
      return response;
    } catch (err) {
      throw err;
    }
  };

  return { HealthCareDonorLoading, HealthCareDonorError, healthCareDonor: handleHealthCareDonor };
};

export default useHealthCareDonorLogic;
