import { Dispatch } from "redux"; // Import Dispatch type
import axiosInstance from "../../config/axios-interceptor";
import { API_BASE_URL } from "../../config/constants";
import { LongFormRequestDTO } from "../../models/long-form-request-item-dto";


// Action Types for Default Data
const BOOK_ORDER_WITHOUT_ROUTE_REQUEST  = "BOOK_ORDER_WITHOUT_ROUTE_REQUEST ";
const BOOK_ORDER_WITHOUT_ROUTE_SUCCESS = "BOOK_ORDER_WITHOUT_ROUTE_SUCCESS";
const BOOK_ORDER_WITHOUT_ROUTE_FAILURE = "BOOK_ORDER_WITHOUT_ROUTE_FAILURE";


// Action Creators for Default Data
export const BookReOrderWithoutRouteRequest = () => ({
    type: BOOK_ORDER_WITHOUT_ROUTE_REQUEST ,
  });
  
  export const BookReOrderWithoutRouteSuccess = () => ({
    type: BOOK_ORDER_WITHOUT_ROUTE_SUCCESS,
  });
  
  export const BookReOrderWithoutRouteFailure = (error: string) => ({
    type: BOOK_ORDER_WITHOUT_ROUTE_FAILURE,
    payload: error,
  });


// Initial State for  Active Order
export interface BookReOrderWithoutRouteState {
    isAuthenticated: boolean;
    BookReOrderWithoutRouteLoading: boolean;
    BookReOrderWithoutRouteError: string | null;
  }

  const initialAuthState: BookReOrderWithoutRouteState = {
    isAuthenticated: false,
    BookReOrderWithoutRouteLoading: false,
    BookReOrderWithoutRouteError: null,
  };
  
  // Define the interface for the API response
interface BookReOrderWithoutRouteResponse {
    success: boolean;
    message: string;
    data: any;
    statusCode: string;
  }
  
  // Reducer for Authentication
  const BookReOrderWithoutRouteReducer = (state = initialAuthState, action: any): BookReOrderWithoutRouteState => {
    switch (action.type) {
      case BOOK_ORDER_WITHOUT_ROUTE_REQUEST :
        return { ...state, BookReOrderWithoutRouteLoading: true, BookReOrderWithoutRouteError: null };
      case BOOK_ORDER_WITHOUT_ROUTE_SUCCESS:
        return { ...state, BookReOrderWithoutRouteLoading: false, isAuthenticated: true };
      case BOOK_ORDER_WITHOUT_ROUTE_FAILURE:
        return { ...state, BookReOrderWithoutRouteLoading: false, BookReOrderWithoutRouteError: action.payload };
      default:
        return state;
    }
  };
  
  // Thunk for Active Order
  export const BookReOrderWithoutRoute = (param: LongFormRequestDTO) => async (dispatch: Dispatch) => {
      dispatch(BookReOrderWithoutRouteRequest());
      try {
        const response = await axiosInstance.post(
          `${API_BASE_URL}/api/Orders/REBookOrderWithoutRoute`,
          param
      );
        // Assuming  Active Order was successful if no error occurred
        dispatch(BookReOrderWithoutRouteSuccess());
        const data: BookReOrderWithoutRouteResponse = response.data;
        // Store token in localStorage
         if (response.status === 200 && data.success) {
          // Store token in localStorage
          return { data };
        } else {
          // Handle different status codes if necessary
          dispatch(BookReOrderWithoutRouteFailure(data.message));
        }
        return response.data;
      } catch (error: any) {
        if(error.response.data.message){
          dispatch(BookReOrderWithoutRouteFailure(error.response.data.message));
        }else{
          dispatch(BookReOrderWithoutRouteFailure(error.message));
        }

      }
    };


    export default BookReOrderWithoutRouteReducer;  
  