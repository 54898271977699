import {
  Box,
  Paper,
  TextField,
  Button,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Alert,
} from "@mui/material";
import { Grid } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { visuallyHidden } from "@mui/utils";

import useAccounntsLogic from "../../shared/lookup/accounts/accounts.logic";
import { useDecodeJWT } from "../../shared/hook/use-decodeJWT";

import { useMediaQuery } from "@mui/material";
import { AddressBookDto } from "../../models/address-book-dto";
import deleteicon from "../../assets/images/logo/delete-icon.svg";

import editicon from "../../assets/images/logo/Edit_icon.svg";

import { Add } from "@mui/icons-material";
import DownloadIcon from "@mui/icons-material/Download";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import CustomDatePicker from "../../shared/components/CustomDatePicker";
import useServiceLogic from "../../shared/lookup/service/service.logic";
import useCountryLogic from "../../shared/lookup/country/country.logic";
import useAddressLogic from "../../shared/lookup/address/address.logic";
import { AddressItemDto } from "../../models/address-item-dto";
import axiosInstance from "../../config/axios-interceptor";
import { API_BASE_URL } from "../../config/constants";
import { downloadAndSavePDF } from "../../shared/util/download-pdf";
import moment from "moment";
type Order = "desc" | "asc";

//   const [countryData, setCountryData] = useState<CountryItemDto[]>([]);
//   const [zipCodeData, setZipCodeData] = useState<ZipCodeItemDto[]>([]);

interface HeadCell {
  disablePadding: boolean;
  id: keyof AddressBookDto;
  label: string;
  numeric: boolean;
  minwidth: string;
}

interface addressBooksProps {
  title: string;
  orderType: string;
  // other props
}

const headCells: HeadCell[] = [
  {
    id: "addressCode",
    numeric: true,
    disablePadding: false,
    label: "CODE",
    minwidth: "150",
  },
  {
    id: "companyName",
    numeric: true,
    disablePadding: false,
    label: "COMPANY",
    minwidth: "200",
  },
  {
    id: "address1",
    numeric: true,
    disablePadding: false,
    label: "ADDRESS",
    minwidth: "232",
  },
  {
    id: "cityName",
    numeric: true,
    disablePadding: false,
    label: "CITY",
    minwidth: "125",
  },
  {
    id: "countryID",
    numeric: true,
    disablePadding: false,
    label: "COUNTRY",
    minwidth: "101",
  },
  {
    id: "attention",
    numeric: true,
    disablePadding: false,
    label: "ATTN/SEE",
    minwidth: "125",
  },
  {
    id: "phoneNumber",
    numeric: true,
    disablePadding: false,
    label: "PHONE",
    minwidth: "140",
  },
  {
    id: "airportID",
    numeric: true,
    disablePadding: false,
    label: "A/P",
    minwidth: "70",
  },
];

interface EnhancedTableProps {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof AddressBookDto
  ) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

const ShipmentVerificationReport: React.FC<addressBooksProps> = ({
  title,
  orderType,
}) => {
  const currentDateTime = new Date();
  const [verificationRequestParams, setverificationRequestParams] = useState({
    pickupDate: currentDateTime,
    deliveryDate: null,
    origin: "None",
    destination: "None",
    serviceID: "None",
  });
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] =
    React.useState<keyof AddressBookDto>("companyName");

  const [isLoading, setIsLoading] = useState(false);
  const [expanded, setExpanded] = useState(false);

  const [accounts, setAccounts] = useState<any>([]);
  const [addressBookData, setAddressBookData] = useState<AddressBookDto[]>([]);
  const [selectedAddress, setSelectedAddress] = useState<
    AddressBookDto | undefined
  >(undefined);
  const token = useDecodeJWT();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRecord, setTotalRecord] = useState(0);
  const isMobile = useMediaQuery("(max-width:600px)");
  const [isAddEditAddressModal, setAddEditAddressModal] = useState(false);
  const userGroupGUID: any = localStorage.getItem("userGroupGUID");
  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof AddressBookDto
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    setPage(0);
  };

  interface MenuStyles {
    maxHeight: string;
  }

  const [menuStyles, setMenuStyles] = useState<MenuStyles>({
    maxHeight: "200px", // Default max height for desktop
  });

  const handleChangePage = (
    _: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (pageSize: number) => {
    if (pageSize === -1) {
      setRowsPerPage(-1);
    } else {
      setRowsPerPage(pageSize);
    }
    setPage(0);
  };

  const handleTextFieldChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setdateErrors(null);
    setverificationRequestParams({
      ...verificationRequestParams,
      [name]: value,
    });
  };

  const handleEdit = (row: any) => {
    setAddEditAddressModal(true);
    setSelectedAddress(row);
  };

  const handleDelete = async (addressGUID: string | undefined) => {
    const confirm = window.confirm(
      "Are you sure you want to delete this address?"
    );
  };

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  function EnhancedTableHead(props: EnhancedTableProps) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler =
      (property: keyof AddressBookDto) =>
      (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
      };
  }

  const [serviceData, setServiceData] = useState<any>([]);
  const { service } = useServiceLogic();
  const { error, address } = useAddressLogic();
  const [addressData, setAddressData] = useState<AddressItemDto[]>([]);
  const [dateErrors, setdateErrors] = useState<string | null>(null);

  useEffect(() => {
    getService();
    getAddress();
  }, []);

  // Service Dropdown
  const getService = async () => {
    try {
      const param: any = {
        companyID: 15693,
        userGUID: token.UserGUID,
        serviceAvailType: "U",
        baseDateTime: new Date(),
        origCountryID: "",
        origCityName: "",
        origStateProvID: "",
        origPostalCode: "",
        origAirportID: "",
        destCountryID: "",
        destCityName: "",
        destStateProvID: "",
        destPostalCode: "",
        destAirportID: "",
      };
      const response = await service(param);
      if (response.data && response.data.data) {
        setServiceData(response.data.data);
      } else {
        setServiceData([]);
      }
    } catch {
      setServiceData([]);
    }
  };

  const getAddress = async () => {
    try {
      setIsLoading(true);
      const param = {
        addressCode: "",
        companyName: "",
        cityName: "",
        stateProvID: "",
        countryID: "",
        postalCode: "",
        userGroupGUID: userGroupGUID,
      };
      const response = await address(param);
      if (response.data && response.data.data) {
        const res = response.data.data;
        if (!param.addressCode) {
          setAddressData(res);
        }

        if (param.addressCode && res.length > 0) {
        }
      }
      setIsLoading(false);
    } catch {
      setIsLoading(false);
      setAddressData([]);
    }
  };

  const DownloadReport = async () => {
    try {
      setdateErrors(null);
      if (
        verificationRequestParams.pickupDate == null &&
        verificationRequestParams.deliveryDate == null
      ) {
        setdateErrors("Date is required.");
        return;
      }
      const puPostalcode = addressData.find(
        (address: any) =>
          address.addressGUID === verificationRequestParams.origin
      )?.postalCode;

      const delPostalcode = addressData.find(
        (address: any) =>
          address.addressGUID === verificationRequestParams.destination
      )?.postalCode;

      const param = {
        userId: parseInt(token.UserId),
        pickupDateTime: verificationRequestParams.pickupDate
          ? moment(verificationRequestParams.pickupDate).format(
              "YYYY-MM-DDTHH:mm:ss"
            )
          : null,
        puPostalCode: puPostalcode == undefined ? "" : puPostalcode,
        delPostalCode: delPostalcode == undefined ? "" : delPostalcode,
        puAirportID: "",
        delAirportID: "",
        deliveryDateTime: verificationRequestParams.deliveryDate
          ? moment(verificationRequestParams.deliveryDate).format(
              "YYYY-MM-DDTHH:mm:ss"
            )
          : null,
        serviceID:
          verificationRequestParams?.serviceID === "None"
            ? null
            : parseInt(verificationRequestParams?.serviceID),
      };
      const response = await axiosInstance.post(
        `${API_BASE_URL}/api/Report/api/ShipmentVerifiCationAtPickup`,
        param,
        { responseType: "arraybuffer" }
      );
      if (response.status === 200) {
        const pdfTitle = "mnxpickupreport.pdf";
        const result = await downloadAndSavePDF(response.data, pdfTitle);
        if (result.success) {
        }
      }
    } catch (error: any) {}
  };

  const toPascalCase = (str: any) => {
    return str
      .replace(
        /\w\S*/g,
        (word: any) =>
          word.charAt(0).toUpperCase() + word.substr(1).toLowerCase()
      )
      .replace(/\s+/g, "");
  };

  return (
    <div className="container-fluid active-order address-book">
      <>
        <Box
          className="Header-main mb-8 pt-16"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography
            className="Main-Header side-by-side-header"
            variant="h4"
            color={"primary"}
          >
            Shipment Verification Report
          </Typography>

          <Typography
            className="info-text"
            variant="h6"
            sx={{
              display: {
                xs: "none", // Hide on mobile devices
                sm: "flex", // Show on small and larger devices
                justifyContent: "left",
              },
            }}
          >
            Enter the Report Criteria and press the "Download Report” button.
          </Typography>
        </Box>

        {dateErrors && (
          <Box className="w-100">
            <Alert severity="error" variant="filled" id="txtSvrDateErrors">
              {dateErrors}
            </Alert>
          </Box>
        )}
        <Box className="d-flex flex-column w-100 border p16 rounded mb-16">
          <Typography variant="h6" className="header-sub-title">
            Report Criteria
          </Typography>

          <Grid
            container
            spacing={2}
            sx={{
              marginTop: {
                xs: 1, // 8px margin-top for mobile
                sm: 0, // 0 margin-top for larger screens
              },
            }}
          >
            <Grid item lg={12}>
              <Grid container spacing={2}>
                <Grid item lg={12}>
                  <Grid container spacing={2}>
                    {/* First Row */}
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <Grid container spacing={2}>
                        <Grid item lg={6} md={6} sm={11 / 2} xs={6}>
                          <div className="small-cal-view">
                            <CustomDatePicker
                              id="PickupDate"
                              handleDateChange={handleTextFieldChange as any}
                              label="Pickup Date"
                              name="pickupDate"
                              value={verificationRequestParams.pickupDate}
                              isClearable={true}
                            />
                          </div>
                        </Grid>
                        <Grid item lg={6} md={6} sm={11 / 2} xs={6}>
                          <div className="small-cal-view">
                            <CustomDatePicker
                              id="DeliveryDate"
                              handleDateChange={handleTextFieldChange as any}
                              label="Delivery Date"
                              name="deliveryDate"
                              value={verificationRequestParams.deliveryDate}
                              isClearable={true}
                            />
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item lg={11 / 4} md={11 / 4} sm={11 / 2} xs={6}>
                      <FormControl size="small" fullWidth>
                        <InputLabel id="demo-select-small-label">
                          Origin
                        </InputLabel>
                        <Select
                          id="ddSvrOrigin"
                          label="Origin"
                          value={verificationRequestParams.origin}
                          onChange={(e) =>
                            setverificationRequestParams({
                              ...verificationRequestParams,
                              origin: e.target.value,
                            })
                          }
                          MenuProps={{
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left",
                            },
                            transformOrigin: {
                              vertical: "top",
                              horizontal: "left",
                            },

                            PaperProps: {
                              style: menuStyles,
                            },
                          }}
                        >
                          <MenuItem value="None">(None)</MenuItem>
                          {addressData.length > 0 &&
                            addressData.map((address: any) => (
                              <MenuItem
                                key={address.addressGUID}
                                value={address.addressGUID}
                              >
                                {address.companyName}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item lg={11 / 4} md={11 / 4} sm={11 / 2} xs={6}>
                      <FormControl size="small" fullWidth>
                        <InputLabel id="demo-select-small-label">
                          Destination
                        </InputLabel>
                        <Select
                          id="ddSvrDestination"
                          label="Destination"
                          value={verificationRequestParams.destination}
                          onChange={(e) =>
                            setverificationRequestParams({
                              ...verificationRequestParams,
                              destination: e.target.value,
                            })
                          }
                          MenuProps={{
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left",
                            },
                            transformOrigin: {
                              vertical: "top",
                              horizontal: "left",
                            },

                            PaperProps: {
                              style: menuStyles,
                            },
                          }}
                        >
                          <MenuItem value="None">(None)</MenuItem>
                          {addressData.length > 0 &&
                            addressData.map((address: any) => (
                              <MenuItem
                                key={address.addressGUID}
                                value={address.addressGUID}
                              >
                                {address.companyName}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item lg={3} md={3} sm={11 / 2} xs={12}>
                      <FormControl size="small" className="w-100">
                        <InputLabel id="demo-select-small-label">
                          Service Type
                        </InputLabel>
                        <Select
                          id="ddSvrServicetype"
                          label="Service Type"
                          value={verificationRequestParams.serviceID || "None"}
                          onChange={(e) =>
                            setverificationRequestParams({
                              ...verificationRequestParams,
                              serviceID: e.target.value,
                            })
                          }
                          MenuProps={{
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left",
                            },
                            transformOrigin: {
                              vertical: "top",
                              horizontal: "left",
                            },

                            PaperProps: {
                              style: menuStyles,
                            },
                          }}
                        >
                          <MenuItem value="None">(None)</MenuItem>
                          {serviceData.length > 0 &&
                            serviceData.map((service: any) => (
                              <MenuItem
                                key={service.serviceGUID}
                                value={service.serviceID}
                              >
                                {service.name}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item lg={2} md={2} sm={6} xs={12}>
                      <Button
                        sx={{
                          width: {
                            xs: "100%", // 100% width on xs screens
                            sm: "215px", // 215px width for sm and above
                          },
                        }}
                        id="btnDownloadReport"
                        variant="contained"
                        color="primary"
                        className="rounded"
                        onClick={() => {
                          DownloadReport();
                        }}
                      >
                        <DownloadIcon className="mr-8" />
                        Download Report
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </>
    </div>
  );
};

// Mobile card view component
const MobileCardView: React.FC<{
  row: AddressBookDto;
  handleEdit: (row: AddressBookDto) => void;
  handleDelete: (addressGUID: string) => void;
}> = ({ row, handleEdit, handleDelete }) => {
  return (
    <Paper
      className="Card_view"
      elevation={3}
      sx={{ marginBottom: "16px", padding: "0" }}
    >
      <Typography
        variant="h6"
        className="Order-head Field_info Addr_grid"
        style={{ height: 40, paddingTop: 10, paddingLeft: 10 }}
      >
        <img
          className="Delete-icon mr-16"
          src={editicon}
          alt="Delete icon"
          id="ibSvrEdit"
          onClick={() => handleEdit(row)}
          style={{ width: 21, height: 21 }}
        />

        <img
          className="Delete-icon w14h18"
          id="ibSvrDelete"
          src={deleteicon}
          alt="Delete icon"
          onClick={() => handleDelete(row.addressGUID as string)}
        />
      </Typography>
      <Typography variant="h6" className="Order-head Field_info Addr_grid">
        <span className="field_label">Code</span>{" "}
        <span className=" Field_value">{row.addressCode}</span>
      </Typography>
      <Typography variant="body1" className="Field_info">
        <span className="field_label">Comapany Name</span>
        <span className="Field_value">{row.companyName}</span>
      </Typography>
      <Typography variant="body1" className="Field_info">
        <span className="field_label">Address</span>{" "}
        <span className="Field_value">
          {`${row.address1?.trim()}${row.address2 ? `, ${row.address2}` : ""}`}
        </span>
      </Typography>
      <Typography variant="body1" className="Field_info">
        <span className="field_label">City</span>{" "}
        <span className="Field_value">{row.cityName}</span>
      </Typography>
      <Typography variant="body1" className="Field_info">
        <span className="field_label"> Country </span>
        <span className="Field_value">{row.countryID}</span>
      </Typography>
      <Typography variant="body1" className="Field_info">
        <span className="field_label">ATTN/SEE </span>
        <span className="Field_value">{row.attention}</span>
      </Typography>
      <Typography variant="body1" className="Field_info">
        <span className="field_label">Phone</span>
        <span className="Field_value">{row.phoneNumber}</span>
      </Typography>
      <Typography variant="body1" className="Field_info">
        <span className="field_label">A/P</span>
        <span className="Field_value">{row.airportID}</span>
      </Typography>
    </Paper>
  );
};

export default ShipmentVerificationReport;
