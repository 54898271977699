import { useState, useEffect } from 'react';

const useRole = (role: string) => {
  const [hasRole, setHasRole] = useState(false);

  useEffect(() => {
    const roles = JSON.parse(localStorage.getItem("roles") || "[]");
    setHasRole(roles.includes(role));
  }, [role]);

  return hasRole;
};

export default useRole;
