import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../types/global";
import { LongFormRequestDTO } from "../../models/long-form-request-item-dto";
import { BookOrderWithRoute } from "./book-order-with-route.reducer";




const useBookOrderWithRouteLogic = () => {
  const dispatch = useDispatch();
  const { BookOrderWithRouteLoading, BookOrderWithRouteError } = useSelector((state: RootState) => state.bookOrderWithRoute);

  useEffect(() => {
    if (BookOrderWithRouteError) {
      // dispatch(showNotification('Active Order failed: ' + error, 'error'));
    }
  }, [BookOrderWithRouteError, dispatch]);

  const handleBookOrderWithRoute = async (param:LongFormRequestDTO ) => {
    try {
      const response = await dispatch(BookOrderWithRoute(param) as any);
      return response;
    } catch (err) {
      throw err;
    }
  };

  return { BookOrderWithRouteLoading, BookOrderWithRouteError, bookOrderWithRoute: handleBookOrderWithRoute };
};

export default useBookOrderWithRouteLogic;
