import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { visuallyHidden } from "@mui/utils";
import { ActiveOrderItemDto } from "../../models/active-order-item-dto";
import { formatDate } from "../../shared/util/date-utils";
import { formatPickupLocation } from "../../shared/util/format-pickup-location";
import { formatDeliveryLocation } from "../../shared/util/format-delivery-location";

import CommonPagination from "../../shared/components/CustomPagination";
import useAccounntsLogic from "../../shared/lookup/accounts/accounts.logic";
import { useDecodeJWT } from "../../shared/hook/use-decodeJWT";
import useActiveOrdersLogic from "../active-orders/active-orders.logic";
import Loader from "../../shared/util/loader";
import { useMediaQuery } from "@mui/material";
import NoDataFound from "../../shared/components/NoDataFound";

import { Link, useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ActiveOrderDialog from "../../shared/components/active-order-dialog/active-order-dialog";
import axiosInstance from "../../config/axios-interceptor";
import { API_BASE_URL } from "../../config/constants";

type Order = "desc" | "asc";

interface HeadCell {
  disablePadding: boolean;
  id: keyof ActiveOrderItemDto;
  label: string;
  numeric: boolean;
  minwidth: string;
}

const headCells: HeadCell[] = [
  {
    id: "shipmentNumber",
    numeric: true,
    disablePadding: false,
    label: "ORDER #",
    minwidth: "110",
  },
  {
    id: "orderDate",
    numeric: true,
    disablePadding: false,
    label: "PLACED",
    minwidth: "110",
  },
  {
    id: "reference",
    numeric: true,
    disablePadding: false,
    label: "REFER",
    minwidth: "110",
  },
  {
    id: "pickupCompanyName",
    numeric: true,
    disablePadding: false,
    label: "PICKUP AT",
    minwidth: "299",
  },
  {
    id: "deliveryCompanyName",
    numeric: true,
    disablePadding: false,
    label: "DELIVERY TO",
    minwidth: "299",
  },
];

interface EnhancedTableProps {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof ActiveOrderItemDto
  ) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

const WillCallOrders: React.FC = () => {
  const [order, setOrder] = React.useState<Order>("desc");
  const [orderBy, setOrderBy] =
    useState<keyof ActiveOrderItemDto>("shipmentNumber");

  const { account } = useAccounntsLogic();
  const [isLoading, setIsLoading] = useState(false);
  const { activeOrders } = useActiveOrdersLogic();
  const [accounts, setAccounts] = useState<any>([]);
  const [willCallOrdesData, setWillCallOrdesData] = useState<
    ActiveOrderItemDto[]
  >([]);
  const token = useDecodeJWT();
  const [selectAccount, setSelectAccount] = React.useState("All");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRecord, setTotalRecord] = useState(0);
  const isMobile = useMediaQuery("(max-width:599px)");
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const [selectedShipmentGUID, setSelectedShipmentGUID] =
    React.useState<string>("");
  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof ActiveOrderItemDto
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    setPage(0);
  };

  function EnhancedTableHead(props: EnhancedTableProps) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler =
      (property: keyof ActiveOrderItemDto) =>
      (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
      };

    return (
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => (
            <TableCell
              width={headCell.minwidth}
              key={headCell.id}
              align={"left"}
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
          <TableCell key="activate" align={"left"} width="140">
            ACTIVATE
          </TableCell>
          <TableCell key="edit" align={"left"} width="180">
            EDIT
          </TableCell>
        </TableRow>
      </TableHead>
    );
  }

  const getAccounts = useCallback(async () => {
    try {
      const param = {
        userId: token.UserId,
        companyID: 0,
        useIncludeAllItemrId: false,
      };

      const response = await account(param, true); //true is passed to get always data from api to update the redux data.
      if (response.data && response.data.data) {
        setAccounts(response.data.data);
      } else {
        setAccounts([]);
      }
    } catch {
      setAccounts([]);
    }
    // eslint-disable-next-line
  }, [token.UserId]);

  useEffect(() => {
    getAccounts();
  }, [getAccounts]);

  const getWillCallOrders = async () => {
    try {
      setIsLoading(true);
      const param = {
        orderType: "WillCallOrders",
        accountNumber: selectAccount === "All" ? "" : selectAccount,
        orderBy: orderBy,
        sorting: order,
        pagesize: rowsPerPage === -1 ? totalRecord : rowsPerPage,
        pageNo: page + 1,
      };
      const response = await activeOrders(param);

      if (response.data && response.data.data) {
        setWillCallOrdesData(response.data.data);
        setTotalRecord(response.data.totalCount);
      }
      setIsLoading(false);
    } catch {
      setIsLoading(false);
      setWillCallOrdesData([]);
      setTotalRecord(0);
    }
  };

  useEffect(() => {
    getWillCallOrders();
    // eslint-disable-next-line
  }, [order, orderBy, selectAccount, page, rowsPerPage]);

  const handleChange = (event: SelectChangeEvent) => {
    setSelectAccount(event.target.value as string);
  };

  const handleChangePage = (
    _: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (pageSize: number) => {
    if (pageSize === -1) {
      setRowsPerPage(-1);
    } else {
      setRowsPerPage(pageSize);
    }
    setPage(0);
  };

  const handleClickOpen = (shipmentGUID: string) => {
    setSelectedShipmentGUID(shipmentGUID);
    setOpen(true);
  };

  const handleDialogClose = (isReload: boolean) => {
    setOpen(false);
    if (isReload) {
      setIsLoading(true);
      getWillCallOrders();
    }
  };

  const handleDelete = async (shipmentGUID: string) => {
    try {
      const confirmDelete = window.confirm(
        "Are you sure you want to delete this entry?"
      );
      if (confirmDelete) {
        const response = await axiosInstance.post(
          `${API_BASE_URL}/api/Orders/WillCAllOrder/Delete?ShipmentGUID=${shipmentGUID}`
        );

        if (response.status) {
          setIsLoading(true);
          getWillCallOrders();
        }
      }
    } catch (error: any) {}
  };

  return (
    <div className="container-fluid active-order">
      <>
        <Box
          className="Main_head_lbl pt-16 pb-0"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box>
            <Typography
              className="Main-Header Sub_info_text marginbottom16"
              variant="h4"
              color={"primary"}
            >
              Will Call Orders
            </Typography>
          </Box>
          <Box className="dropdown-combo">
            <FormControl size="small" sx={{ width: isMobile ? 320 : 295 }}>
              <InputLabel id="demo-select-small-label">
                Select Account
              </InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="ddWcAccount"
                value={selectAccount}
                label="Select Account"
                onChange={handleChange}
                sx={{ width: isMobile ? "100%" : undefined }}
              >
                <MenuItem value="All">All</MenuItem>
                {accounts.length > 0 &&
                  accounts.map((account: any) => (
                    <MenuItem
                      key={account.userAccountRowGUID}
                      value={account.accountNumber}
                    >
                      {account.displayName}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Box>
        </Box>
        <Typography variant="h6" className="will-call-order">
          Shipments waiting to be activated.
        </Typography>
        {/* <div className="d-flex align-items-center">
            <div className="content pb-15 active-order">
              <div className="subtitle"></div>
            </div>
          </div> */}

        <Box sx={{ width: "100%" }}>
          <Paper className="ActiveOrder" sx={{ width: "100%", mb: 4 }}>
            <TableContainer className="table-container mat-table-call-order1">
              {/* Add class name for styling */}
              <Table aria-labelledby="tableTitle">
                <EnhancedTableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  rowCount={willCallOrdesData.length}
                />
                <TableBody>
                  {isLoading ? (
                    <TableRow>
                      <TableCell className="" colSpan={12} align="center">
                        <Loader />
                      </TableCell>
                    </TableRow>
                  ) : willCallOrdesData.length === 0 ? (
                    <TableRow className="Nodata_found" id="trWillCallOrderNoDataFound">
                      <TableCell
                        className="No-data-section"
                        colSpan={12}
                        align="center"
                        id="tdnoDataFound"
                      >
                        <NoDataFound
                          heading="No Will Call Orders Found"
                          title={
                            "The currently selected accounts do not have any will call orders. Try selecting a new account in the menu above or one of the following:"
                          }
                          leftBtnText={"VIEW RECENT DELIVERIES"}
                          rightBtnText={"NEW ORDER"}
                          leftBtnUrl={"/recent-deliveries"}
                          rightBtnUrl={"/longform-order"}
                        />
                      </TableCell>
                    </TableRow>
                  ) : (
                    willCallOrdesData.map(
                      (row: ActiveOrderItemDto, index: any) => {
                        return isMobile ? (
                          <MobileCardView key={row.shipmentGUID} row={row} />
                        ) : (
                          <TableRow hover key={row.shipmentGUID} id="trWillCallOrder">
                            <TableCell style={{ minWidth: 100 }} id="tdShipmentNumber">
                              <Link
                                to={`/edit-will-call-order?ShipmentGUID=${row?.shipmentGUID}`}
                                className="primary"
                                id={`lbtnOrderId`}
                              >
                                {row?.shipmentNumber}
                              </Link>
                            </TableCell>
                            <TableCell style={{ minWidth: 110 }} id="tdOrderDate">
                              {formatDate(row.orderDate)}
                            </TableCell>
                            <TableCell style={{ minWidth: 110 }} id="tdReference">
                              {row.reference}
                            </TableCell>
                            <TableCell style={{ minWidth: 110 }} id="tdPickup">
                              <Box
                                dangerouslySetInnerHTML={{
                                  __html: formatPickupLocation(row),
                                }}
                              ></Box>
                            </TableCell>
                            <TableCell id="tdDelivery">
                              <Box
                                dangerouslySetInnerHTML={{
                                  __html: formatDeliveryLocation(row),
                                }}
                              ></Box>
                            </TableCell>
                            <TableCell
                              className="vertical-center"
                              id="tdActivate"
                              style={{ minWidth: 190 }}
                            >
                              {row.pickupCompanyName !== "" &&
                              row.pickupCityName !== "" &&
                              row.pickupCountryID !== "" &&
                              row.deliveryCompanyName !== "" &&
                              row.deliveryCityName !== "" &&
                              row.deliveryCountryID !== "" &&
                              row.pieces &&
                              row.weight > 0 ? (
                                <Button
                                  variant="outlined"
                                  color="primary"
                                  size="small"
                                  className="d-flex activate_btn rounded-3"
                                  id="btnActivate"
                                  onClick={() =>
                                    handleClickOpen(row.shipmentGUID)
                                  }
                                >
                                  ACTIVATE
                                </Button>
                              ) : (
                                <Button
                                  variant="outlined"
                                  color="primary"
                                  size="small"
                                  className="d-flex activate_btn rounded-3"
                                  id="btnAddMoreInfo"
                                  onClick={() => {
                                    navigate(
                                      `/edit-will-call-order?ShipmentGUID=${row?.shipmentGUID}`
                                    );
                                  }}
                                >
                                  ADD MORE INFO
                                </Button>
                              )}
                            </TableCell>
                            <TableCell style={{ minWidth: 40 }} id="tdEdit">
                              <IconButton
                                color="default"
                                size="medium"
                                id="btnEditWillCallOrder"
                                onClick={() => {
                                  navigate(
                                    `/edit-will-call-order?ShipmentGUID=${row?.shipmentGUID}`
                                  );
                                }}
                              >
                                <EditIcon color="action" />
                              </IconButton>
                              <IconButton
                                color="default"
                                size="medium"
                                id="btnDeleteWillCallOrder"
                                onClick={() => handleDelete(row.shipmentGUID)}
                              >
                                <DeleteIcon color="action" />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        );
                      }
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            {/* {!isLoading && willCallOrdesData.length > 0 && ( */}
            <CommonPagination
              count={totalRecord}
              page={page}
              rowsPerPage={rowsPerPage}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
            {/* )} */}
          </Paper>
        </Box>
        <ActiveOrderDialog
          open={open}
          handleClose={handleDialogClose}
          shipmentGUID={selectedShipmentGUID}
        />
      </>
    </div>
  );
};

// Mobile card view component
const MobileCardView: React.FC<{
  row: ActiveOrderItemDto;
}> = ({ row }) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleDialogClose = () => {
    setOpen(false);
  };

  return (
    <Paper
      className="Card_view"
      elevation={3}
      sx={{ marginBottom: "16px", padding: "0" }}
    >
      <Typography variant="h6" className="Order-head Field_info">
        <span className="field_label">Order #</span>{" "}
        <span className="Order_id Field_value">
          <Link
            to={`/edit-will-call-order?ShipmentGUID=${row?.shipmentGUID}`}
            className="primary"
            id={`lbtnOrderId`}
          >
            {row?.shipmentNumber}
          </Link>
        </span>
      </Typography>
      <Typography variant="body1" className="Field_info">
        <span className="field_label">Placed</span>{" "}
        <span className="Field_value">{formatDate(row.orderDate)}</span>
      </Typography>
      {/* <Typography variant="body1"><span>Placed:</span> <span>{formatDate(row.orderDate)}</span></Typography> */}
      <Typography variant="body1" className="Field_info">
        <span className="field_label">Refer</span>{" "}
        <span className="Field_value">{row.reference}</span>
      </Typography>
      <Typography variant="body1" className="Field_info">
        <span className="field_label"> Pickup </span>
        <span className="Field_value">
          <Box
            dangerouslySetInnerHTML={{
              __html: formatPickupLocation(row),
            }}
          ></Box>
        </span>
        {/* <span> Pickup Location: </span>{formatPickupLocation(row)} */}
      </Typography>
      <Typography variant="body1" className="Field_info">
        <span className="field_label">Delivery </span>
        <span className="Field_value">
          <Box
            dangerouslySetInnerHTML={{
              __html: formatDeliveryLocation(row),
            }}
          ></Box>
        </span>
        {/* <span>Delivery Location:</span> <span>{formatDeliveryLocation(row)}</span> */}
      </Typography>
      <div style={{ padding: "8px" }}>
        <Button
          variant="outlined"
          color="primary"
          size="small"
          className="d-flex w-100 rounded-3 activate_btn"
          onClick={handleClickOpen}
          id="btnActivate"
        >
          ACTIVATE
        </Button>
      </div>
      <ActiveOrderDialog
        open={open}
        handleClose={handleDialogClose}
        shipmentGUID={row.shipmentGUID}
      />
    </Paper>
  );
};

export default WillCallOrders;
