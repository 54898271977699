import { Box, Typography, Grid, Button, Alert } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import useDefaultDataLogic from "../long-form-orders/long-form-order.logic";
import { MAX_COMPANY_ID } from "../../config/constants";
import AccountSettings from "../../shared/components/my-settings/AccountSettings";
import { MySettingDto } from "../../models/my-setting-dto";
import ContactInfo from "../../shared/components/my-settings/ContactInfo";
import MeasurementDefaults from "../../shared/components/my-settings/MeasurementDefaults";
import ServiceAndPackageDefaults from "../../shared/components/my-settings/ServiceAndPackageDefaults";
import NotificationSettings from "../../shared/components/my-settings/NotificationSettings";
import ReferenceSettings from "../../shared/components/my-settings/ReferenceSettings";
import DefaultPickupDetails from "../../shared/components/my-settings/DefaultPickupDetails";
import DefaultDeliveryDetails from "../../shared/components/my-settings/DefaultDeliveryDetails";
import DangerousGoodsDefaults from "../../shared/components/my-settings/DangerousGoodsDefaults";
import useMySettingsLogic from "./my-settings.logic";
import { CountryItemDto } from "../../models/country-item-dto";
import useCountryLogic from "../../shared/lookup/country/country.logic";
import { extractTime } from "../../shared/util/numeric-value";
import {
  isDisplayDateTime,
  formatRefValue,
  handleReferenceUpdate,
} from "../../shared/util/common";
const MySettings: React.FC = () => {
  const { defaultData } = useDefaultDataLogic();
  const [mySetting, setMySetting] = useState<MySettingDto>();
  const [errors, setErrors] = useState<any>({});
  const [successMessage, setSuccessMessage] = useState<string>("");
  const { MySettingsError, MySettings } = useMySettingsLogic();
  const [countryData, setCountryData] = useState<CountryItemDto[]>([]);
  const { country } = useCountryLogic();
  const [unError, setUNError] = useState<boolean>(false);
  const errorRef: any = useRef(null);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [pickupZipCodeError, SetPickUpZipCodeError] = useState<boolean>(false);
  const [deliveryZipCodeError, SetDeliveryZipCodeError] =
  useState<boolean>(false);
  const [pickupZipCodeWarn,setPickupZipCodeWarn]=useState<boolean>(false);
  const [deliveryZipCodeWarn, setDeliveryZipCodeWarn]=useState<boolean>(false);
  const [pickupSubmitCount,setPickupSubmitCount]=useState(0);
  const [deliverySubmitCount,setDeliverySubmitCount]=useState(0);
  const userRole = JSON.parse(localStorage.getItem("roles") || "[]");
  const [isLimited, setisLimited] = React.useState(false);

  useEffect(() => {
    getDefaultData();
    getCountry();
    const isLimitedUser = userRole.includes("limited");
    if(isLimitedUser)
    {
      setisLimited(true);
    }
    else
    {
      setisLimited(false);
    }
  }, []);

  const getDefaultData = async () => {
    try {
      const param = {
        companyID: MAX_COMPANY_ID,
      };
      const response = await defaultData(param);
      if (response.data && response.data.data) {
        const res = response.data.data;
        res.pieces = res.pieces == null || undefined ? 1 : res.pieces;
        setMySetting(res);
        if (res.contentsReferenceGroupGUID) {
          localStorage.setItem(
            "contentsReferenceGroupGUID",
            res.contentsReferenceGroupGUID
          );
        }
      }
    } catch (err) {
      setMySetting(undefined);
    }
  };

  const getCountry = async () => {
    try {
      const param = {};
      const response = await country(param);
      if (response.data && response.data.data) {
        setCountryData(response.data.data);
      }
    } catch (err) {
      setCountryData([]);
    }
  };

  const validate = () => {
    const newErrors: any = {};
    if(pickupZipCodeError && mySetting?.pickupPostalCode && mySetting?.pickupCountryID === "US")
    {
      return;
    }
    if(deliveryZipCodeError && mySetting?.deliveryPostalCode && mySetting?.deliveryCountryID === "US")
      {
        return;
      }
      
    if (!mySetting?.logonName) newErrors.logonName = "Please enter a User ID.";
    if (!mySetting?.firstName)
      newErrors.firstName = "Please enter a First Name.";
    if (!mySetting?.lastName) newErrors.lastName = "Please enter a Last Name.";
    if (!mySetting?.accountNumberShipper)
      newErrors.accountNumberShipper =
        "Please select a Shipper Account Number.";
    if (!mySetting?.emailAddress)
      newErrors.emailAddress = "Please enter a valid email address.";
    if (!mySetting?.userCompanyName)
      newErrors.userCompanyName = "Please enter a Company Name.";
    // if (!mySetting?.commodity)
    //   newErrors.commodity = "Please select a Commodity.";
    if (!mySetting?.contentsLabel)
      newErrors.contentsLabel = "Please enter a Content Labels.";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | { name?: string; value: unknown }>
  ) => {
    const { name, type, value, checked } = e.target as HTMLInputElement;

    if (
      name === "pieces" ||
      name === "length" ||
      name === "width" ||
      name === "height"
    ) {
      // Convert the value to a number
      const numericValue = Number(value);
      // Handle empty value by allowing the user to clear the input
      if (value === "") {
        setMySetting((prevData: any) => ({
          ...prevData,
          [name]: "",
        }));
      }
      // Only update the state if the value is a number greater than or equal to 1
      else if (!isNaN(numericValue) && numericValue >= 1) {
        setMySetting((prevData) => ({
          ...prevData,
          [name]: numericValue,
        }));
      }
      // If the value is less than 1, keep it at 1
      else {
        setMySetting((prevData) => ({
          ...prevData,
          [name]: 1,
        }));
      }
    } else if (name === "Country") {
      setMySetting((prevData) => ({
        ...prevData,
        [name]: value,
        pickupCountryID: value,
      }));
    } else if (name === "deliveryCountry") {
      setMySetting((prevData) => ({
        ...prevData,
        [name]: value,
        deliveryCountryID: value,
      }));
    } else {
      // For other fields, handle the input normally
      setMySetting((prevData) => ({
        ...prevData,
        [name]: type === "checkbox" ? checked : value,
      }));
    }
    setErrors((prevErrors: any) => {
      const newErrors = { ...prevErrors };
      delete newErrors[name];
      return newErrors;
    });
  };

  const handleUNNumberDataChange = (data: any) => {
    if (data.length > 0) {
      setUNError(false);
    } else {
      setUNError(true);
      errorRef?.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
        top: 50,
      });
    }
  };

  const combineDateWithTime = (timeString: string): string => {
    const today = new Date(); // Get today's date
    const [hours, minutes] = timeString.split(":"); // Split the time string
    // Set the hours and minutes to the current date
    today.setHours(parseInt(hours, 10));
    today.setMinutes(parseInt(minutes, 10));
    today.setSeconds(0);
    today.setMilliseconds(0);
    // Format the date manually to prevent timezone offset issues
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
    const day = String(today.getDate()).padStart(2, "0");
    const hoursFormatted = String(today.getHours()).padStart(2, "0");
    const minutesFormatted = String(today.getMinutes()).padStart(2, "0");
    // Return the date and time in the required format
    return `${year}-${month}-${day}T${hoursFormatted}:${minutesFormatted}:00`;
  };

  // const handleSubmit = async () => {
  //   try {
  //     debugger;
  //     setSuccessMessage("");
  //     setErrorMessage("");
  //     setUNError(false);
     

  //     if (validate()) {
  //       if (mySetting) {
  //         mySetting.dgDryIceWeight = Number(mySetting.dgDryIceWeight);
  //         mySetting.dgDryIceWeight = mySetting.dgDryIceWeight ? mySetting.dgDryIceWeight : null;
  //         mySetting.dgDate = mySetting.dgDate ? mySetting.dgDate : null;
  //         mySetting.pickupReadyTime = mySetting.pickupReadyTime
  //           ? combineDateWithTime(extractTime(mySetting.pickupReadyTime))
  //           : null; // Convert to ISO string if necessary
  //         mySetting.deliveryRequestTime = mySetting.deliveryRequestTime
  //           ? combineDateWithTime(extractTime(mySetting.deliveryRequestTime))
  //           : null;

  //         const {
  //           accountNumber,
  //           referenceTypeID,
  //           referenceTypeID2,
  //           referenceTypeID3,
  //           referenceTypeID4,
  //           referenceTypeID5,
  //           referenceTypeID6,
  //           referenceTypeID7,
  //           referenceTypeID8,
  //           referenceTypeID9,
  //         } = mySetting;

  //         mySetting.reference = handleReferenceUpdate(
  //           referenceTypeID,
  //           mySetting,
  //           accountNumber,
  //           "reference"
  //         );
  //         mySetting.reference2 = handleReferenceUpdate(
  //           referenceTypeID2,
  //           mySetting,
  //           accountNumber,
  //           "reference2"
  //         );
  //         mySetting.reference3 = handleReferenceUpdate(
  //           referenceTypeID3,
  //           mySetting,
  //           accountNumber,
  //           "reference3"
  //         );
  //         mySetting.reference4 = handleReferenceUpdate(
  //           referenceTypeID4,
  //           mySetting,
  //           accountNumber,
  //           "reference4"
  //         );
  //         mySetting.reference5 = handleReferenceUpdate(
  //           referenceTypeID5,
  //           mySetting,
  //           accountNumber,
  //           "reference5"
  //         );
  //         mySetting.reference6 = handleReferenceUpdate(
  //           referenceTypeID6,
  //           mySetting,
  //           accountNumber,
  //           "reference6"
  //         );
  //         mySetting.reference7 = handleReferenceUpdate(
  //           referenceTypeID7,
  //           mySetting,
  //           accountNumber,
  //           "reference7"
  //         );
  //         mySetting.reference8 = handleReferenceUpdate(
  //           referenceTypeID8,
  //           mySetting,
  //           accountNumber,
  //           "reference8"
  //         );
  //         mySetting.reference9 = handleReferenceUpdate(
  //           referenceTypeID9,
  //           mySetting,
  //           accountNumber,
  //           "reference9"
  //         );
  //       if(mySetting.pickupCountryID != "US" && mySetting.pickupCountryID != "AE" && pickupZipCodeError && pickupSubmitCount == 0){
  //           setSuccessMessage("");
  //           errorRef?.current?.scrollIntoView({
  //             behavior: "smooth",
  //             block: "start",
  //             top: 50,
  //           });
  //           setPickupZipCodeWarn(true);
  //           setPickupSubmitCount(1);
  //         } 
  //        if(mySetting.deliveryCountryID != "US" && mySetting.deliveryCountryID != "AE" && deliveryZipCodeError && deliverySubmitCount == 0){
  //           setSuccessMessage("");
  //           errorRef?.current?.scrollIntoView({
  //             behavior: "smooth",
  //             block: "start",
  //             top: 50,
  //           });
  //           setDeliveryZipCodeWarn(true);
  //           setDeliverySubmitCount(1);
  //         }
  //         else{
  //         setPickupZipCodeWarn(false);
  //         setDeliveryZipCodeWarn(false);
  //         setPickupSubmitCount(0);
  //         setDeliverySubmitCount(0);
  //         const data = await MySettings(mySetting);
  //         const response = data.data;
  //         if (response.statusCode == 200) {
  //           setSuccessMessage(response.message);
  //           errorRef?.current?.scrollIntoView({
  //             behavior: "smooth",
  //             block: "start",
  //             top: 50,
  //           });
  //         }
  //       }}
  //     } else {
  //       setSuccessMessage("");
  //       errorRef?.current?.scrollIntoView({
  //         behavior: "smooth",
  //         block: "start",
  //         top: 50,
  //       });
  //     }
  //   } catch (err) {
  //     setSuccessMessage("");
  //     setErrorMessage(MySettingsError || "");
  //     setPickupZipCodeWarn(false);
  //     setDeliveryZipCodeWarn(false);
  //   } finally {
  //   }
  // };

  const handleSubmit = async () => {
    try {
      setSuccessMessage("");
      setErrorMessage("");
      setUNError(false);
  
      if (!validate()) {
        setSuccessMessage("");
        errorRef?.current?.scrollIntoView({
          behavior: "smooth",
          block: "start",
          top: 50,
        });
        return;
      }
  
      if (mySetting) {
        // Handle numeric and date conversions
        mySetting.dgDryIceWeight = mySetting.dgDryIceWeight ? Number(mySetting.dgDryIceWeight) : null;
        mySetting.dgDate = mySetting.dgDate || null;
        mySetting.pickupReadyTime = mySetting.pickupReadyTime 
          ? combineDateWithTime(extractTime(mySetting.pickupReadyTime)) 
          : null;
        mySetting.deliveryRequestTime = mySetting.deliveryRequestTime 
          ? combineDateWithTime(extractTime(mySetting.deliveryRequestTime)) 
          : null;
  
        // Update references
        const { accountNumber } = mySetting;
        mySetting.reference = handleReferenceUpdate(mySetting.referenceTypeID, mySetting, accountNumber, "reference");
        mySetting.reference2 = handleReferenceUpdate(mySetting.referenceTypeID2, mySetting, accountNumber, "reference2");
        mySetting.reference3 = handleReferenceUpdate(mySetting.referenceTypeID3, mySetting, accountNumber, "reference3");
        mySetting.reference4 = handleReferenceUpdate(mySetting.referenceTypeID4, mySetting, accountNumber, "reference4");
        mySetting.reference5 = handleReferenceUpdate(mySetting.referenceTypeID5, mySetting, accountNumber, "reference5");
        mySetting.reference6 = handleReferenceUpdate(mySetting.referenceTypeID6, mySetting, accountNumber, "reference6");
        mySetting.reference7 = handleReferenceUpdate(mySetting.referenceTypeID7, mySetting, accountNumber, "reference7");
        mySetting.reference8 = handleReferenceUpdate(mySetting.referenceTypeID8, mySetting, accountNumber, "reference8");
        mySetting.reference9 = handleReferenceUpdate(mySetting.referenceTypeID9, mySetting, accountNumber, "reference9");
  
        // Handle pickup and delivery zip code validations
        const isInvalidPickupZip = 
          mySetting.pickupCountryID !== "US" &&
          mySetting.pickupCountryID !== "AE" &&
          pickupZipCodeError &&
          pickupSubmitCount === 0;
  
        const isInvalidDeliveryZip = 
          mySetting.deliveryCountryID !== "US" &&
          mySetting.deliveryCountryID !== "AE" &&
          deliveryZipCodeError &&
          deliverySubmitCount === 0;
  
          if (isInvalidPickupZip || isInvalidDeliveryZip) {
            setSuccessMessage("");
            errorRef?.current?.scrollIntoView({
              behavior: "smooth",
              block: "start",
              top: 50,
            });
            if (isInvalidPickupZip) {
              setPickupZipCodeWarn(true);
              setPickupSubmitCount(1);
            }
            if (isInvalidDeliveryZip) {
              setDeliveryZipCodeWarn(true);
              setDeliverySubmitCount(1);
            }
            return;
          }
  
        // Reset warnings and submit data
        setPickupZipCodeWarn(false);
        setDeliveryZipCodeWarn(false);
        setPickupSubmitCount(0);
        setDeliverySubmitCount(0);
  
        const data = await MySettings(mySetting);
        const response = data.data;
  
        if (Number(response.statusCode) == 200) {
          setSuccessMessage(response.message);
          errorRef?.current?.scrollIntoView({
            behavior: "smooth",
            block: "start",
            top: 50,
          });
        }
      }
    } catch (err) {
      setSuccessMessage("");
      setErrorMessage(MySettingsError || "");
      setPickupZipCodeWarn(false);
      setDeliveryZipCodeWarn(false);
    }
  };
  
  return (
    <>
      <form>
        {mySetting && (
          <div className="my-settings">
            <Box
              className="Header-main pt-16 pb-16 plr-24"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              ref={errorRef}
            >
              <Typography
                className="Main-Header side-by-side-header"
                variant="h4"
                color={"primary"}
              >
                My Settings
              </Typography>
              <Typography className="info-text text-right" variant="h6">
                Use this page to manage default settings for your account.
              </Typography>
            </Box>
            {(Object.keys(errors).length > 0 || errorMessage || unError || (pickupZipCodeError && mySetting.pickupCountryID === "US")  || (deliveryZipCodeError && mySetting.deliveryCountryID === "US" )|| pickupZipCodeWarn || deliveryZipCodeWarn) && (
              <Box className="w-100 mt-24 mb-24">
                <Alert variant="filled" severity="error" id="txtMsError">
                  {Object.keys(errors).map((key) => (
                    <div key={key}>{errors[key]}</div>
                  ))}
                  {errorMessage && <div>{errorMessage}</div>}
                  {unError && <div>Invalid UN# specified.</div>}
                  {(pickupZipCodeError && mySetting.pickupCountryID === "US") && <div>You have entered an invalid Pickup Zip Code. Please ensure you have entered the proper Zip Code. If you continue to experience issues, please contact MNX.</div>}
                  {(deliveryZipCodeError && mySetting.deliveryCountryID === "US" ) && (
                  <div>You have entered an invalid Delivery Zip Code. Please ensure you have entered the proper Zip Code. If you continue to experience issues, please contact MNX.</div>
                )}
                 {pickupZipCodeWarn && (
                  <div>Please ensure you have entered the proper Pickup Zip Code. After you have confirmed the Pickup Zip Code's accuracy, please try again.</div>
                )}
                {deliveryZipCodeWarn && (
                  <div>Please ensure you have entered the proper Delivery Zip Code. After you have confirmed the Delivery Zip Code's accuracy, please try again.</div>
                )}
                </Alert>
              </Box>
            )}

            {successMessage && (
              <Box className="w-100 mt-24 mb-24">
                <Alert variant="filled" severity="success" id="txtMsError">
                  <div>{successMessage}</div>
                </Alert>
              </Box>
            )}

            <Box className="d-flex flex-column">
              <Typography
                variant="h5"
                color="primary"
                className="setting-head pb-16"
              >
                1. Personal Settings
              </Typography>
              <AccountSettings
                mySetting={mySetting}
                handleInputChange={handleInputChange}
                formErrors={errors}
              />
              <Box className="mt-16 align-items-start Divider pb-32 mb-32">
                <Grid container spacing={2}>
                  <ContactInfo
                    mySetting={mySetting}
                    handleInputChange={handleInputChange}
                    formErrors={errors}
                  />
                  <MeasurementDefaults
                    mySetting={mySetting}
                    handleInputChange={handleInputChange}
                    countryData={countryData}
                  />
                  <ServiceAndPackageDefaults
                    mySetting={mySetting}
                    handleInputChange={handleInputChange}
                    formErrors={errors}
                  />
                </Grid>
              </Box>
              {/* package information section */}

              {/* order actions section */}
            </Box>
            <Box className="d-flex flex-column">
              <Typography
                variant="h5"
                color="primary"
                className="setting-head pb-16"
              >
                2. Pickup and Delivery Settings
              </Typography>
              <Box className="mt-16 align-items-start Divider pb-32 mb-32">
                <Grid container spacing={2}>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <Box className="p16 border rounded">
                      <Typography className="sub_header padding0 mb-16">
                        Default Pickup Details
                      </Typography>
                      <DefaultPickupDetails
                        mySetting={mySetting}
                        handleInputChange={handleInputChange}
                        countryData={countryData}
                        SetPickupZipCodeError={SetPickUpZipCodeError}
                        pickupZipCodeError={pickupZipCodeError}
                        setSuccessMessage={setSuccessMessage}
                      />
                    </Box>
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <Box className="p16 border rounded">
                      <Typography className="sub_header padding0 mb-16">
                        Default Delivery Details
                      </Typography>
                      <DefaultDeliveryDetails
                        mySetting={mySetting}
                        handleInputChange={handleInputChange}
                        countryData={countryData}
                        SetDeliveryZipCodeError={SetDeliveryZipCodeError}
                        deliveryZipCodeError={deliveryZipCodeError}
                        setSuccessMessage={setSuccessMessage}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>

            <Box className="d-flex flex-column">
              <Typography variant="h5" color="primary" className="setting-head">
                3. Reference Settings
              </Typography>
              <Box className="mt-16 Divider pb-32 mb-32">
                <ReferenceSettings
                  mySetting={mySetting}
                  handleInputChange={handleInputChange}
                  formErrors={errors}
                />
              </Box>
            </Box>

            <Box className="d-flex flex-column Divider pb-32 mb-32">
              <Typography
                variant="h5"
                color="primary"
                className="setting-head pb-16"
              >
                4. Notification Settings
              </Typography>

              <Box className="d-flex gap-4 w-100">
                <Grid container spacing={2}>
                  <NotificationSettings
                    mySetting={mySetting}
                    handleInputChange={handleInputChange}
                  />
                </Grid>
              </Box>
            </Box>
            <Box className="d-flex flex-column">
              <Typography
                variant="h5"
                color="primary"
                className="setting-head pb-16"
              >
                5. Dangerous Goods
              </Typography>

              <Box className="d-flex gap-16 mt-3 w-100 align-flex-start flex-col">
                <DangerousGoodsDefaults
                  mySetting={mySetting}
                  handleInputChange={handleInputChange}
                  onUnNumberChange={handleUNNumberDataChange}
                />
              </Box>
            </Box>
          </div>
        )}
      </form>
      <div className="container-fluid">
        <Box className="d-flex justify-content-end gap-2 w-100 Divider-top pt-16 pb-16">
              <Button
        variant="contained"
        color="primary"
        className="d-flex rounded me-2"
        onClick={() => handleSubmit()}
        id="btnMsSaveChanges"
        disabled={isLimited} // Disable when isLimitedUser is false
      >
        SAVE CHANGES
      </Button>

        </Box>
      </div>
    </>
  );
};

export default MySettings;
