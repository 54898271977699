import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../types/global";

import { calculateDimWtDto } from "../../../models/piece-item-dto";
import {
  packageType,
  packageTypeDependancy,
  calculateDimWtAPI,
} from "./package-type.reducer";

const usePackageTypeLogic = () => {
  const dispatch = useDispatch();
  const { packageLoading, packageTypeError, packageTypeData } = useSelector(
    (state: RootState) => state.packageType
  );

  useEffect(() => {
    if (packageTypeError) {
      // dispatch(showNotification('Account failed: ' + error, 'error'));
    }
  }, [packageTypeError, dispatch]);

  const handlePackageType = async (param: {
    companyID: number;
    showDeleted: boolean;
    customerID: number;
    includeBlank: boolean;
  }) => {
    try {
      // if (packageTypeData && packageTypeData?.data?.data.length > 0) {
      //   return packageTypeData;
      // } else {
      //   const response = await dispatch(packageType(param) as any);
      //   return response;
      // }
      const response = await dispatch(packageType(param) as any);
      return response;
    } catch (err) {}
  };

  const handlePackageTypeDependancy = async (param: {
    accountNumber: number;
  }) => {
    try {
      const response = await dispatch(packageTypeDependancy(param) as any);
      return response;
    } catch (err) {}
  };

  const handleCalculateDimWtAPI = async (param: calculateDimWtDto) => {
    try {
      const response = await dispatch(calculateDimWtAPI(param) as any);
      return response;
    } catch (err) {}
  };

  return {
    packageLoading,
    packageTypeError,
    packageType: handlePackageType,
    calculateDimWtAPI: handleCalculateDimWtAPI,
    packageTypeDependancy: handlePackageTypeDependancy,
  };
};

export default usePackageTypeLogic;
