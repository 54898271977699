import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../types/global";
import { track } from "../track-order/track-order.reducer";
import { SignupItemDto } from "../../models/SignupItemDto";
import { signup } from "./signup.reducer";

const useSignupLogic = () => {
  const dispatch = useDispatch();
  const { signupLoading, signupError } = useSelector(
    (state: RootState) => state.signup
  );

  useEffect(() => {
    if (signupError) {
      // dispatch(showNotification('Login failed: ' + error, 'error'));
    }
  }, [signupError, dispatch]);

  const handleSignup = async (param: SignupItemDto) => {
    try {
      const response = await dispatch(signup(param) as any);
      return response;
    } catch (err) {
      throw err;
    }
  };

  return { signupLoading, signupError, signup: handleSignup };
};

export default useSignupLogic;
