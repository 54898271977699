import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../types/global";
import { healthCareDto } from "../../models/health-care-dto";
import {
  HealthCare,
  getHealthAddress,
  getGiftDetails,
} from "./healthcare-form.reducer";

const useHealthCareLogic = () => {
  const dispatch = useDispatch();
  const { HealthCarerError, HealthCarerLoading } = useSelector(
    (state: RootState) => state.healthCare
  );

  const handleHealthCare = async (param: healthCareDto) => {
    try {
      const response = await dispatch(HealthCare(param) as any);
      return response;
    } catch (err) {
      throw err;
    }
  };

  const healthAddress = async (param: any) => {
    try {
      const response = await dispatch(getHealthAddress(param) as any);
      return response;
    } catch (err) {
      throw err;
    }
  };

  const giftDetails = async () => {
    try {
      const response = await dispatch(getGiftDetails() as any);
      return response;
    } catch (err) {
      throw err;
    }
  };

  return {
    HealthCarerLoading,
    HealthCarerError,
    healthCare: handleHealthCare,
    healthAddress,
    giftDetails,
  };
};

export default useHealthCareLogic;
