import {
  Box,
  Paper,
  SelectChangeEvent,
  Table,
  TextField,
  Button,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";
import { Grid } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { visuallyHidden } from "@mui/utils";
import { formatDate } from "../../shared/util/date-utils";
import { formatPickupLocation } from "../../shared/util/format-pickup-location";
import { formatDeliveryLocation } from "../../shared/util/format-delivery-location";
import { formatDateTZ } from "../../shared/util/date-tz-utils";
import CommonPagination from "../../shared/components/CustomPagination";
import useAccounntsLogic from "../../shared/lookup/accounts/accounts.logic";
import { useDecodeJWT } from "../../shared/hook/use-decodeJWT";
import useAddressBookLogic from "./address-book.logic";
import Loader from "../../shared/util/loader";
import { useMediaQuery } from "@mui/material";
import { AddressBookDto } from "../../models/address-book-dto";
import deleteicon from "../../assets/images/logo/delete-icon.svg";

import editicon from "../../assets/images/logo/Edit_icon.svg";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { Add } from "@mui/icons-material";
import AddEditAddress from "../../shared/components/add-edit-address-dialog/add-edit-address-dialog";
import NoDataFound from "../../shared/components/NoDataFound";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
type Order = "desc" | "asc";

//   const [countryData, setCountryData] = useState<CountryItemDto[]>([]);
//   const [zipCodeData, setZipCodeData] = useState<ZipCodeItemDto[]>([]);

interface HeadCell {
  disablePadding: boolean;
  id: keyof AddressBookDto;
  label: string;
  numeric: boolean;
  minwidth: string;
}

interface addressBooksProps {
  title: string;
  orderType: string;
  // other props
}

const headCells: HeadCell[] = [
  {
    id: "addressCode",
    numeric: true,
    disablePadding: false,
    label: "CODE",
    minwidth: "150",
  },
  {
    id: "companyName",
    numeric: true,
    disablePadding: false,
    label: "COMPANY",
    minwidth: "200",
  },
  {
    id: "address1",
    numeric: true,
    disablePadding: false,
    label: "ADDRESS",
    minwidth: "232",
  },
  {
    id: "cityName",
    numeric: true,
    disablePadding: false,
    label: "CITY",
    minwidth: "125",
  },
  {
    id: "countryID",
    numeric: true,
    disablePadding: false,
    label: "COUNTRY",
    minwidth: "101",
  },
  {
    id: "attention",
    numeric: true,
    disablePadding: false,
    label: "ATTN/SEE",
    minwidth: "125",
  },
  {
    id: "phoneNumber",
    numeric: true,
    disablePadding: false,
    label: "PHONE",
    minwidth: "140",
  },
  {
    id: "airportID",
    numeric: true,
    disablePadding: false,
    label: "A/P",
    minwidth: "70",
  },
];

interface EnhancedTableProps {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof AddressBookDto
  ) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

const AddressBooks: React.FC<addressBooksProps> = ({ title, orderType }) => {
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] =
    React.useState<keyof AddressBookDto>("companyName");

  const { error, account } = useAccounntsLogic();
  const [isLoading, setIsLoading] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const { addressBooks, deleteAddress } = useAddressBookLogic();
  const [accounts, setAccounts] = useState<any>([]);
  const [addressBookData, setAddressBookData] = useState<AddressBookDto[]>([]);
  const [selectedAddress, setSelectedAddress] = useState<
    AddressBookDto | undefined
  >(undefined);
  const token = useDecodeJWT();
  const [selectAccount, setSelectAccount] = React.useState("All");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRecord, setTotalRecord] = useState(0);
  const isMobile = useMediaQuery("(max-width:600px)");
  const [isAddEditAddressModal, setAddEditAddressModal] = useState(false);
  const userGroupGUID: any = localStorage.getItem("userGroupGUID");
  const [searchFormState, setSearchFormState] = useState({
    companyName: "",
    streetAddress: "",
    stateProvID: "",
    addressCode: "",
    countryID: "",
    postalCode: "",
  });
  const [isClose,setIsClose]=useState(false);
  const userRole = JSON.parse(localStorage.getItem("roles") || "[]");
  const [isLimited, setisLimited] = React.useState(false);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof AddressBookDto
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    setPage(0);
  };

  useEffect(() => {
    getAddressBooks();

    const isLimitedUser = userRole.includes("limited");
    if(isLimitedUser)
    {
      setisLimited(true);
    }
    else
    {
      setisLimited(false);
    }
  }, [order, orderBy, selectAccount, page, rowsPerPage, orderType]);

  const getAccounts = useCallback(async () => {
    try {
      const param = {
        userId: token.UserId,
        companyID: 0,
        useIncludeAllItemrId: false,
      };

      const response = await account(param);
      if (response.data && response.data.data) {
        setAccounts(response.data.data);
      } else {
        setAccounts([]);
      }
    } catch {
      setAccounts([]);
    }
  }, [token.UserId]);

  useEffect(() => {
    getAccounts();
  }, [getAccounts]);

  const getAddressBooks = async () => {
    try {
      setIsLoading(true);

      const {
        companyName,
        streetAddress,
        stateProvID,
        addressCode,
        countryID,
        postalCode,
      } = searchFormState;
      const param = {
        userGroupGUID: userGroupGUID,
        companyName: companyName,
        streetAddress: streetAddress,
        stateProvID: stateProvID,
        addressCode: addressCode,
        countryID: countryID,
        postalCode: postalCode,
        returnAllIfNoParms: true,
        orderBy: orderBy,
        sorting: order,
        pagesize: rowsPerPage ,
        pageNo: page + 1,
      };
      const response = await addressBooks(param);
      if (response.data && response.data.data) {
        setAddressBookData(response.data.data);
        setTotalRecord(response.data.totalCount);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      setAddressBookData([]);
      setTotalRecord(0);
    }
  };

  const handleChangePage = (
    _: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (pageSize: number) => {
    if (pageSize === -1) {
      setRowsPerPage(-1);
    } else {
      setRowsPerPage(pageSize);
    }
    setPage(0);
  };

  const handleTextFieldChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;

    setSearchFormState({
      ...searchFormState,
      [name]: value,
    });
  };
  const handleSearch = () => {
    if (page > 0) {
      //just reset the page so api will call
      setPage(0);
    } else {
      getAddressBooks();
    }
  };

  const handleEdit = (row: any) => {
    if(!isLimited)
    {
    setIsClose(false);
    setAddEditAddressModal(true);
    setSelectedAddress(row);}
  };

  const handleDelete = async (addressGUID: string | undefined) => {
    if(!isLimited)
      {
    const confirm = window.confirm(
      "Are you sure you want to delete this address?"
    );

    if (confirm && addressGUID) {
      setIsLoading(true);
      const param = {
        original_AddressGUID: addressGUID,
      };
      const response = await deleteAddress(param);
      if (response?.data?.success) {
        setIsLoading(false);
        getAddressBooks();
      }
    }
    }
  };

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  function EnhancedTableHead(props: EnhancedTableProps) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler =
      (property: keyof AddressBookDto) =>
      (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
      };

    return (
      <TableHead className="addr_head">
        <TableRow sx={{ borderBottom: "1px solid rgba(224, 224, 224, 1)" }}>
          <Grid container>
            <Grid>
               <Button
                id="btnNew"
                variant="outlined"
                className="rounded new_btn"
                disabled= {isLimited}
                onClick={() => {
                  if (isLimited) return; 
                  setAddEditAddressModal(true);
                  setSelectedAddress(undefined);
                }}
               >
                <Add className="Plus-icon mr-8" style={{ color: "#046386" }} />
                New
              </Button>
            </Grid>
          </Grid>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={"left"}
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  return (
    <div className="container-fluid active-order address-book">
      <>
        <Box
          className="Header-main mb-8 pt-16"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography
            className="Main-Header side-by-side-header"
            variant="h4"
            color={"primary"}
          >
            Address Book
          </Typography>

          <Typography
            className="info-text"
            variant="h6"
            sx={{
              display: {
                xs: "none", // Hide on mobile devices
                sm: "flex", // Show on small and larger devices
                justifyContent: "end",
              },
            }}
          >
            Use this page to maintain a list of common addresses. use the % as
            the wildcard in the search criteria fields.
          </Typography>
        </Box>
        <Box className="d-flex flex-column w-100 border p16 rounded">
          <Typography variant="h6" className="header-sub-title">
            Search Criteria
            {isMobile && (
              <>
                <Button onClick={toggleExpand} style={{ float: "right" }}>
                  {expanded ? <ExpandLess /> : <ExpandMore />}
                </Button>
              </>
            )}
          </Typography>

          <Grid
            container
            spacing={2}
            className={isMobile && !expanded ? "hide-search-default" : ""}
            sx={{
              marginTop: {
                xs: 1, // 8px margin-top for mobile
                sm: 0, // 0 margin-top for larger screens
              },
            }}
          >
            <Grid item lg={12}>
              <Grid container spacing={2}>
                <Grid item lg={12}>
                  <Grid container spacing={2}>
                    {/* First Row */}
                    <Grid item lg={12 / 7} md={3} xs={6}>
                      <TextField
                        size="small"
                        id="txtAddressCode"
                        fullWidth
                        label="Code"
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        name="addressCode"
                        onChange={handleTextFieldChange}
                        inputProps={{ maxLength: 52 }}
                      />
                    </Grid>

                    <Grid item lg={12 / 7} md={3} xs={6}>
                      <TextField
                        size="small"
                        id="txtCompanyName"
                        fullWidth
                        label="Company Name"
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        name="companyName"
                        onChange={handleTextFieldChange}
                      />
                    </Grid>

                    <Grid item lg={12 / 7} md={3} xs={6}>
                      <TextField
                        size="small"
                        id="txtStreetAddress"
                        fullWidth
                        label="Street Address"
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        name="streetAddress"
                        onChange={handleTextFieldChange}
                      />
                    </Grid>
                    <Grid
                      item
                      lg={12 / 7}
                      md={3}
                      xs={6}
                      sx={{
                        display: {
                          xs: "none",
                          sm: "none",
                          md: "block",
                          lg: "none",
                        },
                      }}
                    >
                      {/* Empty item */}
                    </Grid>

                    {/* Empty space for tablet view */}
                    {/* <Grid
                      item
                      lg={12 / 7}
                      md={3}
                      xs={6}
                      hidden={{ lgUp: true }}
                    >
                      
                    </Grid> */}

                    {/* Second Row */}
                    <Grid item lg={12 / 7} md={3} xs={6}>
                      <TextField
                        size="small"
                        id="txtState"
                        fullWidth
                        label="State/Province"
                        variant="outlined"
                        name="stateProvID"
                        InputLabelProps={{ shrink: true }}
                        onChange={handleTextFieldChange}
                      />
                    </Grid>

                    <Grid item lg={12 / 7} md={3} xs={6}>
                      <TextField
                        size="small"
                        id="txtPostalCode"
                        fullWidth
                        label="Postal Code"
                        variant="outlined"
                        name="postalCode"
                        InputLabelProps={{ shrink: true }}
                        onChange={handleTextFieldChange}
                      />
                    </Grid>

                    <Grid item lg={12 / 7} md={3} xs={6}>
                      <TextField
                        size="small"
                        id="txtCountry"
                        fullWidth
                        label="Country"
                        variant="outlined"
                        name="countryID"
                        InputLabelProps={{ shrink: true }}
                        onChange={handleTextFieldChange}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={12 / 7}
                      md={3}
                      xs={6}
                      sx={{
                        display: {
                          xs: "block",
                          sm: "none",
                          md: "none",
                          lg: "none",
                        },
                      }}
                    ></Grid>
                    <Grid item lg={12 / 7} md={3} xs={6}>
                      <Button
                        id="btnSearch"
                        variant="contained"
                        fullWidth
                        className="d-flex rounded"
                        startIcon={<SearchOutlinedIcon />}
                        onClick={handleSearch}
                      >
                        SEARCH
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>

        <Box className="mt-16" sx={{ width: "100%" }}>
          <Paper className="ActiveOrder mb-40" sx={{ width: "100%", mb: 2 }}>
            <TableContainer className="address-grid">
              {" "}
              {/* Add class name for styling */}
              <Table aria-labelledby="tableTitle">
                <EnhancedTableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  rowCount={addressBookData.length}
                />
                {isMobile && (
                    <Button
                    variant="outlined"
                    id="btnAddNew"
                    className="d-flex rounded new_btn"
                    disabled= {isLimited}
                    onClick={() => {
                      if (isLimited) return; // Prevent action if isLimited is true
                      setAddEditAddressModal(true);
                      setSelectedAddress(undefined);
                    }}
                  >
                    <Add
                      className="Plus-icon wh14 mr-8"
                      style={{ color: "#046386" }}
                    />
                    New
                  </Button>
                )}
                <TableBody>
                  {isLoading ? (
                    <TableRow>
                      <TableCell className="" colSpan={12} align="center">
                        <Loader />
                      </TableCell>
                    </TableRow>
                  ) : addressBookData.length === 0 ? (
                    <TableRow className="No-lbl-found" id="trAddressBookDataNotFound">
                      <TableCell
                        className="Nodata_found Nodata-height"
                        align="center"
                        colSpan={12}
                        id="tdDataNotFound"
                        sx={{
                          backgroundColor: "rgba(167, 35, 35, 0.04)",
                          marginBottom: 10,
                        }}
                      >
                        {/* {notData} */}
                        <div
                          className="nodata-section No-data-section"
                          style={{ backgroundColor: "none" }}
                        >
                          <NoDataFound
                            heading="No Addresses Found"
                            title={
                              "To add to your address list, please select the 'New' from the above table row."
                            }
                            leftBtnText={""}
                            rightBtnText={""}
                            leftBtnUrl={""}
                            rightBtnUrl={""}
                            isDisplayBtn={false}
                          />
                        </div>
                      </TableCell>
                    </TableRow>
                  ) : (
                    addressBookData.map((row: AddressBookDto, index: any) => {
                      return isMobile ? (
                        <>
                          <MobileCardView
                            key={row.addressGUID}
                            row={row}
                            handleEdit={handleEdit}
                            handleDelete={handleDelete}
                          />
                        </>
                      ) : (
                        <TableRow hover key={row.addressGUID} id="trAddressBook">
                          <TableCell
                            className="border-top textCenter"
                            style={{
                              minWidth: 100,
                            }}
                            id="tdEditDeleteBtn"
                          >
                            <img
                              id="btnEdit"
                              className="Delete-icon mr-16"
                              src={editicon}
                              alt="Delete icon"
                              onClick={() => handleEdit(row)}
                              style={{ width: 21, height: 21 }}
                            />

                            <img
                              id="btnDelete"
                              className="Delete-icon w14h18"
                              src={deleteicon}
                              alt="Delete icon"
                              onClick={() => handleDelete(row.addressGUID)}
                            />
                          </TableCell>
                          <TableCell style={{ minWidth: 150 }} id="tdAddressCode">
                            {row.addressCode}
                          </TableCell>
                          <TableCell style={{ minWidth: 200 }} id="tdCompanyName">
                            {row.companyName}
                          </TableCell>
                          <TableCell style={{ minWidth: 232 }} id="tdAddress">
                             {[
                               row.address1?.trim(),
                               row.address2?.trim(),
                               row.address3?.trim(),
                             ]
                               .filter(Boolean) 
                               .join(", ") || ""}
                          </TableCell>
                          <TableCell style={{ minWidth: 125 }} id="tdCityName">
                            {row.cityName}
                          </TableCell>
                          <TableCell style={{ minWidth: 101 }} id="tdCountryID">
                            {row.countryID}
                          </TableCell>
                          <TableCell style={{ minWidth: 125 }} id="tdAttention">
                            {row.attention}
                          </TableCell>
                          <TableCell style={{ minWidth: 140 }} id="tdPhoneNumber">
                            {row.phoneNumber}
                          </TableCell>
                          <TableCell style={{ minWidth: 70 }} id="tdAirportID">
                            {row.airportID}
                          </TableCell>
                        </TableRow>
                      );
                    })
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            {!isLoading && addressBookData.length > 0 && (
              <CommonPagination
                count={totalRecord}
                page={page}
                rowsPerPage={rowsPerPage}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            )}
          </Paper>
        </Box>
      </>
      <AddEditAddress
        open={isAddEditAddressModal}
        handleClose={() => {
          setAddEditAddressModal(false);
          setSelectedAddress(undefined);
          setIsClose(true);
        }}
        currentAddress={selectedAddress}
        onAddNewAddress={() => {
          getAddressBooks();
          setAddEditAddressModal(false);
          setSelectedAddress(undefined);
        }}
        setIsClose={setIsClose}
        isClose={isClose}
      />
    </div>
  );
};

// Mobile card view component
const MobileCardView: React.FC<{
  row: AddressBookDto;
  handleEdit: (row: AddressBookDto) => void;
  handleDelete: (addressGUID: string) => void;
}> = ({ row, handleEdit, handleDelete }) => {
  return (
    <Paper
      className="Card_view"
      elevation={3}
      sx={{ marginBottom: "16px", padding: "0" }}
    >
      <Typography
        variant="h6"
        className="Order-head Field_info Addr_grid"
        style={{ height: 40, paddingTop: 10, paddingLeft: 10 }}
      >
        <img
          className="Delete-icon mr-16"
          id="btnNewEdit"
          src={editicon}
          alt="Delete icon"
          onClick={() => handleEdit(row)}
          style={{ width: 21, height: 21 }}
        />

      <img
        className="Delete-icon w14h18"
        id="btnNewDelete"
        src={deleteicon}
        alt="Delete icon"
        onClick={() => handleDelete(row.addressGUID as string)}
      />
      </Typography>
      <Typography variant="h6" className="Order-head Field_info Addr_grid">
        <span className="field_label">Code</span>{" "}
        <span className=" Field_value">{row.addressCode}</span>
      </Typography>
      <Typography variant="body1" className="Field_info">
        <span className="field_label">Comapany Name</span>
        <span className="Field_value">{row.companyName}</span>
      </Typography>
      <Typography variant="body1" className="Field_info">
        <span className="field_label">Address</span>{" "}
        <span className="Field_value">
          {`${row.address1?.trim()}${row.address2 ? `, ${row.address2}` : ""}`}
        </span>
      </Typography>
      <Typography variant="body1" className="Field_info">
        <span className="field_label">City</span>{" "}
        <span className="Field_value">{row.cityName}</span>
      </Typography>
      <Typography variant="body1" className="Field_info">
        <span className="field_label"> Country </span>
        <span className="Field_value">{row.countryID}</span>
      </Typography>
      <Typography variant="body1" className="Field_info">
        <span className="field_label">ATTN/SEE </span>
        <span className="Field_value">{row.attention}</span>
      </Typography>
      <Typography variant="body1" className="Field_info">
        <span className="field_label">Phone</span>
        <span className="Field_value">{row.phoneNumber}</span>
      </Typography>
      <Typography variant="body1" className="Field_info">
        <span className="field_label">A/P</span>
        <span className="Field_value">{row.airportID}</span>
      </Typography>
    </Paper>
  );
};

export default AddressBooks;
