import { Dispatch } from "redux"; // Import Dispatch type
import axiosInstance from "../../config/axios-interceptor";
import { API_BASE_URL } from "../../config/constants";
import { LongFormRequestDTO } from "../../models/long-form-request-item-dto";


// Action Types for Default Data
const BOOK_ORDER_WITHOUT_ROUTE_REQUEST  = "BOOK_ORDER_WITHOUT_ROUTE_REQUEST ";
const BOOK_ORDER_WITHOUT_ROUTE_SUCCESS = "BOOK_ORDER_WITHOUT_ROUTE_SUCCESS";
const BOOK_ORDER_WITHOUT_ROUTE_FAILURE = "BOOK_ORDER_WITHOUT_ROUTE_FAILURE";


// Action Creators for Default Data
export const bookOrderWithoutRouteRequest = () => ({
    type: BOOK_ORDER_WITHOUT_ROUTE_REQUEST ,
  });
  
  export const bookOrderWithoutRouteSuccess = () => ({
    type: BOOK_ORDER_WITHOUT_ROUTE_SUCCESS,
  });
  
  export const bookOrderWithoutRouteFailure = (error: string) => ({
    type: BOOK_ORDER_WITHOUT_ROUTE_FAILURE,
    payload: error,
  });


// Initial State for  Active Order
export interface BookOrderWithoutRouteState {
    isAuthenticated: boolean;
    BookOrderWithoutRouteLoading: boolean;
    BookOrderWithoutRouteError: string | null;
  }

  const initialAuthState: BookOrderWithoutRouteState = {
    isAuthenticated: false,
    BookOrderWithoutRouteLoading: false,
    BookOrderWithoutRouteError: null,
  };
  
  // Define the interface for the API response
interface BookOrderWithoutRouteResponse {
    success: boolean;
    message: string;
    data: any;
    statusCode: string;
  }
  
  // Reducer for Authentication
  const bookOrderWithoutRouteReducer = (state = initialAuthState, action: any): BookOrderWithoutRouteState => {
    switch (action.type) {
      case BOOK_ORDER_WITHOUT_ROUTE_REQUEST :
        return { ...state, BookOrderWithoutRouteLoading: true, BookOrderWithoutRouteError: null };
      case BOOK_ORDER_WITHOUT_ROUTE_SUCCESS:
        return { ...state, BookOrderWithoutRouteLoading: false, isAuthenticated: true };
      case BOOK_ORDER_WITHOUT_ROUTE_FAILURE:
        return { ...state, BookOrderWithoutRouteLoading: false, BookOrderWithoutRouteError: action.payload };
      default:
        return state;
    }
  };
  
  // Thunk for Active Order
  export const BookOrderWithoutRoute = (param: LongFormRequestDTO) => async (dispatch: Dispatch) => {
      dispatch(bookOrderWithoutRouteRequest());
      try {
        const response = await axiosInstance.post(
          `${API_BASE_URL}/api/Orders/BookOrderWithoutRoute`,
          param
      );
        // Assuming  Active Order was successful if no error occurred
        dispatch(bookOrderWithoutRouteSuccess());
        const data: BookOrderWithoutRouteResponse = response.data;
        // Store token in localStorage
         if (response.status === 200 && data.success) {
          // Store token in localStorage
          return { data };
        } else {
          // Handle different status codes if necessary
          dispatch(bookOrderWithoutRouteFailure(data.message));
        }
        return response.data;
      } catch (error: any) {
        if(error.response.data.message){
          dispatch(bookOrderWithoutRouteFailure(error.response.data.message));
        }else{
          dispatch(bookOrderWithoutRouteFailure(error.message));
        }

      }
    };


    export default bookOrderWithoutRouteReducer;  
  