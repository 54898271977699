import { Dispatch } from "redux"; // Import Dispatch type
import axiosInstance from "../../config/axios-interceptor";
import { API_BASE_URL } from "../../config/constants";
import { LongFormRequestDTO } from "../../models/long-form-request-item-dto";


// Action Types for Default Data
const BOOK_Re_ORDER_WITH_ROUTE_REQUEST  = "BOOK_RE_ORDER_WITH_ROUTE_REQUEST ";
const BOOK_Re_ORDER_WITH_ROUTE_SUCCESS = "BOOK_RE_ORDER_WITH_ROUTE_SUCCESS";
const BOOK_Re_ORDER_WITH_ROUTE_FAILURE = "BOOK_RE_ORDER_WITH_ROUTE_FAILURE";


// Action Creators for Default Data
export const bookReOrderWithRouteRequest = () => ({
    type: BOOK_Re_ORDER_WITH_ROUTE_REQUEST ,
  });
  
  export const bookReOrderWithRouteSuccess = () => ({
    type: BOOK_Re_ORDER_WITH_ROUTE_SUCCESS,
  });
  
  export const bookReOrderWithRouteFailure = (error: string) => ({
    type: BOOK_Re_ORDER_WITH_ROUTE_FAILURE,
    payload: error,
  });


// Initial State for  Active Order
export interface BookReOrderWithRouteState {
    isAuthenticated: boolean;
    BookReOrderWithRouteLoading: boolean;
    BookReOrderWithRouteError: string | null;
  }

  const initialAuthState: BookReOrderWithRouteState = {
    isAuthenticated: false,
    BookReOrderWithRouteLoading: false,
    BookReOrderWithRouteError: null,
  };
  
  // Define the interface for the API response
interface BookReOrderWithRouteResponse {
    success: boolean;
    message: string;
    data: any;
    statusCode: string;
  }
  
  // Reducer for Authentication
  const bookReOrderWithRouteReducer = (state = initialAuthState, action: any): BookReOrderWithRouteState => {
    switch (action.type) {
      case BOOK_Re_ORDER_WITH_ROUTE_REQUEST :
        return { ...state, BookReOrderWithRouteLoading: true, BookReOrderWithRouteError: null };
      case BOOK_Re_ORDER_WITH_ROUTE_SUCCESS:
        return { ...state, BookReOrderWithRouteLoading: false, isAuthenticated: true };
      case BOOK_Re_ORDER_WITH_ROUTE_FAILURE:
        return { ...state, BookReOrderWithRouteLoading: false, BookReOrderWithRouteError: action.payload };
      default:
        return state;
    }
  };
  
  // Thunk for Active Order
  export const BookReOrderWithRoute = (param: LongFormRequestDTO) => async (dispatch: Dispatch) => {
      dispatch(bookReOrderWithRouteRequest());
      try {
        const response = await axiosInstance.post(
          `${API_BASE_URL}/api/Orders/REBookOrderWithRoute`,
          param
      );
        // Assuming  Active Order was successful if no error occurred
        dispatch(bookReOrderWithRouteSuccess());
        const data: BookReOrderWithRouteResponse = response.data;
        // Store token in localStorage
         if (response.status === 200 && data.success) {
          // Store token in localStorage
          return { data };
        } else {
          // Handle different status codes if necessary
          dispatch(bookReOrderWithRouteFailure(data.message));
        }
        return response.data;
      } catch (error: any) {
        if(error.response.data.message){
          dispatch(bookReOrderWithRouteFailure(error.response.data.message));
        }else{
          dispatch(bookReOrderWithRouteFailure(error.message));
        }

      }
    };


    export default bookReOrderWithRouteReducer;  
  