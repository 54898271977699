import {
  Box,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import { RateEstimateChargeDTO } from "../../../models/rate-estimate-dto";

type Order = "desc" | "asc";
interface EnhancedTableProps {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof RateEstimateChargeDTO | any
  ) => void;
  order: Order;
  orderBy: string | any;
  rowCount?: number;
  headCells: any;
  itemDTO?: any;
  isAllowEmptyCell?: any;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort, headCells, isAllowEmptyCell } = props;
  const createSortHandler =
    (property: keyof RateEstimateChargeDTO | any) =>
    (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead className="addr_head">
      <TableRow sx={{ borderBottom: "1px solid rgba(224, 224, 224, 1)" }}>
        {isAllowEmptyCell && <TableCell key={"emptyCell"}></TableCell>}
        {headCells.map((headCell: any) => (
          <TableCell
            key={headCell.id}
            align={"left"}
            sx={{
              minWidth: `${headCell?.minwidth}px !important`,
            }}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            className={headCell?.className}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default EnhancedTableHead;
