import {
  Box,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { MySettingDto } from "../../../models/my-setting-dto";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import { red } from "@mui/material/colors";

interface ContactInfoProps {
  mySetting: MySettingDto;
  handleInputChange: (updatedData: any) => void;
  formErrors: any;
}

const ContactInfo: React.FC<ContactInfoProps> = ({
  mySetting,
  handleInputChange,
  formErrors,
}) => {
  return (
    <Grid item lg={4} md={6} sm={6} xs={12}>
      <Box className="p16 border rounded">
        <Typography className="sub_header padding0 mb-16">
          Contact Info
        </Typography>
        <Grid container spacing={2}>
          <Grid item lg={12} md={12} xs={12}>
            <TextField
              id="txtEmailAddress"
              name="emailAddress"
              label="Email"
              variant="outlined"
              className="w-100"
              size="small"
              inputProps={{ maxLength: 100 }}
              defaultValue={mySetting.emailAddress}
              onChange={handleInputChange}
              error={!!formErrors.emailAddress}
              InputProps={{
                endAdornment: !!formErrors.emailAddress && (
                  <InputAdornment position="end" disablePointerEvents={true}>
                    <InfoRoundedIcon
                      style={{ color: red[500] }}
                      fontSize="small"
                    ></InfoRoundedIcon>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item lg={12} md={12} xs={12}>
            <TextField
              id="txtOfficeNumber"
              name="officeNumber"
              label="Phone"
              variant="outlined"
              className="w-100"
              size="small"
              defaultValue={mySetting.officeNumber}
              inputProps={{ maxLength: 25 }}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item lg={12} md={12} xs={12}>
            <TextField
              id="txtFaxNumber"
              name="faxNumber"
              label="Fax"
              variant="outlined"
              className="w-100"
              size="small"
              defaultValue={mySetting.faxNumber}
              onChange={handleInputChange}
              inputProps={{
                maxLength: 25,
              }}
            />
          </Grid>
          <Grid item lg={12} md={12} xs={12}>
            <TextField
              id="txtUserCompanyName"
              name="userCompanyName"
              label="Company Name"
              variant="outlined"
              inputProps={{ maxLength: 50 }}
              className="w-100"
              size="small"
              defaultValue={mySetting.userCompanyName}
              onChange={handleInputChange}
              error={!!formErrors.userCompanyName}
              InputProps={{
                endAdornment: !!formErrors.userCompanyName && (
                  <InputAdornment position="end" disablePointerEvents={true}>
                    <InfoRoundedIcon
                      style={{ color: red[500] }}
                      fontSize="small"
                    ></InfoRoundedIcon>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
};

export default ContactInfo;
