import { Dispatch } from "redux"; // Import Dispatch type
import axiosInstance from "../../config/axios-interceptor";
import { API_BASE_URL } from "../../config/constants";
import { healthCareDto } from "../../models/health-care-dto";

// Action Types for Default Data
const HEALTH_REQUEST = "HEALTH_REQUEST";
const HEALTH_SUCCESS = "HEALTH_SUCCESS";
const HEALTH_FAILURE = "HEALTH_FAILURE";

// Action Creators for Default Data
export const HealthCarerRequest = () => ({
  type: HEALTH_REQUEST,
});

export const HealthCarerSuccess = () => ({
  type: HEALTH_SUCCESS,
});

export const HealthCarerFailure = (error: string) => ({
  type: HEALTH_FAILURE,
  payload: error,
});

// Initial State for  Active Order
export interface HealthCareState {
  isAuthenticated: boolean;
  HealthCarerLoading: boolean;
  HealthCarerError: string | null;
}

const initialHealthCareState: HealthCareState = {
  isAuthenticated: false,
  HealthCarerLoading: false,
  HealthCarerError: null,
};

// Define the interface for the API response
interface HealthCarerResponse {
  success: boolean;
  message: string;
  data: any;
  statusCode: string;
}

// Reducer for Authentication
const healthCareReducer = (
  state = initialHealthCareState,
  action: any
): HealthCareState => {
  switch (action.type) {
    case HEALTH_REQUEST:
      return { ...state, HealthCarerLoading: true, HealthCarerError: null };
    case HEALTH_SUCCESS:
      return { ...state, HealthCarerLoading: false, isAuthenticated: true };
    case HEALTH_FAILURE:
      return {
        ...state,
        HealthCarerLoading: false,
        HealthCarerError: action.payload,
      };
    default:
      return state;
  }
};

// Thunk for Active Order
export const HealthCare =
  (param: healthCareDto) => async (dispatch: Dispatch) => {
    dispatch(HealthCarerRequest());
    try {
      const response = await axiosInstance.post(
        `${API_BASE_URL}/api/Orders/HealthCareOrder`,
        param
      );
      // Assuming  Active Order was successful if no error occurred
      dispatch(HealthCarerSuccess());
      const data: HealthCarerResponse = response.data;
      // Store token in localStorage
      if (response.status === 200 && data.success) {
        // Store token in localStorage
        return { data };
      } else {
        // Handle different status codes if necessary
        dispatch(HealthCarerFailure(data.message));
      }
      return response.data;
    } catch (error: any) {
      if (error.response.data.message) {
        dispatch(HealthCarerFailure(error.response.data.message));
      } else {
        dispatch(HealthCarerFailure(error.message));
      }
    }
  };

export const getHealthAddress = (param: any) => async () => {
  try {
    const response = await axiosInstance.post(
      `${API_BASE_URL}/api/Orders/GetHealthAddress`,
      param
    );

    const data = response.data;
    // Store token in localStorage
    if (response.status === 200 && data.success) {
      // Store token in localStorage
      return { data };
    }
    return response.data;
  } catch (error: any) {}
};

export const getGiftDetails = () => async () => {
  try {
    const response = await axiosInstance.post(
      `${API_BASE_URL}/api/Orders/GetGiftDetails`
    );

    const data = response.data;

    if (response.status === 200 && data.success) {
      return { data };
    }
    return response.data;
  } catch (error: any) {}
};

export default healthCareReducer;
